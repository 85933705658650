import { TYPE_OF_BUSINESS_SELECT } from 'utils/signUp'

export const SIGN_UP_VI = {
  distributorInfo: 'Thông tin Nhà phân phối',
  businessInfo: 'Thông tin về Hoạt động kinh doanh',
  infrastructureInfo: 'Thông tin về Cơ sở hạ tầng',
  documents: 'Tài liệu',
  registerToSell: 'Đăng ký bán trên Vigo',
  businessName: 'Tên doanh nghiệp',
  typeOfBusiness: 'Loại hình kinh doanh',
  type: 'Loại hình',
  placeholderAddress: 'Vui lòng chọn Tỉnh/Thành phố, Quận/Huyện, Phường/Xã',
  placeholderAddressDetail: 'Vui lòng nhập Tên đường/Số nhà/Tên nhà',
  mandatory: 'Thông tin bắt buộc',
  distributionArea: 'Khu vực phân phối',
  businessModel: 'Mô hình kinh doanh',
  placeholderOneOrMore: 'Vui lòng chọn một hoặc nhiều lựa chọn',
  numberOfYears: 'Số năm làm phân phối',
  monthlySales: 'Doanh số hàng tháng',
  numberRequired: 'số',
  creditAllow: 'Cho phép công nợ',
  averageInventoryValue: 'Giá trị trung bình tồn kho',
  numberWarehouse: 'Số kho hàng',
  totalWarehouseArea: 'Tổng diện tích kho hàng ',
  numberOfSales: 'Số lượng nhân viên bán hàng',
  numberWarehouseStaff: 'Số lượng nhân viên kho hàng',
  numberAccountant: 'Số lượng kế toán',
  numberRider: 'Số lượng tài xế',
  numberTruck: 'Số lượng xe tải',
  numberMotorbike: 'Số lượng xe máy',
  confirmSubmit: 'Xác nhận đăng ký',
  back: 'Quay lại',
  category: 'Thuộc danh mục',
  thankYouSubmit: 'Cảm ơn đã đăng ký',
  contact: 'Vigo sẽ liên hệ với bạn trong vòng 24h tới',
  backToLogin: 'Quay lại trang đăng nhập',
  addressInRegion: 'Địa chỉ',
  rejectBusinessName: 'Từ chối đăng ký của doanh nghiệp:',
  rejectReason: 'Lý do từ chối:',
  ex: 'VD: Cần thêm thông tin',
  send: 'Gửi',
  'insufficient-information': 'Không đủ thông tin cần thiết',
  'invalid-information': 'Thông tin không hợp lệ',
  'unable-contact': 'Không liên lạc được',
  others: 'Khác',
  waringSaveChanges: 'Bạn có muốn lưu chỉnh sửa mới nhất không?',
  notSave: `Tôi không muốn lưu`,
  yesPlease: 'Xác nhận lưu',
  termAndCondition: 'Tôi đồng ý với <link>các điều khoản và điều kiện</link>',
  titleTermAndCondition: 'Điều khoản và điều kiện',
  certificate: 'Giấy phép',
  enterprise: 'Doanh nghiệp',
  ERC: 'Doanh nghiệp',
  household: 'Hộ kinh doanh',
  pleaseProvide: 'Vui lòng cung cấp giấy phép đăng ký {name} {type}',
  ...TYPE_OF_BUSINESS_SELECT,
}
