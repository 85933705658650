export const PURCHASE_REQUEST_ALL = 'all'
export const PURCHASE_REQUEST_CREATED = 'created'
export const PURCHASE_REQUEST_QUOTING = 'quoting'
export const PURCHASE_REQUEST_QUOTED = 'quoted'
export const PURCHASE_REQUEST_CLOSED = 'closed'
export const PURCHASE_REQUEST_CANCELLED = 'cancelled'
export const PURCHASE_REQUEST_RECOMMENDED = 'recommended'
export const PURCHASE_REQUEST_CONFIRMED = 'confirmed'

export const TASK_CODE_PURCHASE_REQUEST_UPLOAD = 'purchase-request-upload'

export const PROCESS_TYPE_CONSIGNMENT = 'consignment'
export const PROCESS_TYPE_RETAIL = 'retail'
export const PROCESS_TYPE_UNDEFINED = 'undefined'
export const PROCESS_TYPE_SUPPLIER_OF_DISTRIBUTOR = 'supplier_of_distributor'

export const CREATED_METHOD_NORMAL = 'normal'
export const CREATED_METHOD_BOT = 'bot'
export const IS_LAST_7_DAYS = 'isLast7Days'
export const IS_LAST_30_DAYS = 'isLast30Days'
export const IS_DATE_RANGE = 'isDateRange'

export const PROCESS_TYPE_IS_RETAIL_OR_CONSIGNMENT = [
  PROCESS_TYPE_RETAIL,
  PROCESS_TYPE_CONSIGNMENT,
]

export const PROCESS_TYPE_SELECT = [
  PROCESS_TYPE_RETAIL,
  PROCESS_TYPE_CONSIGNMENT,
  PROCESS_TYPE_SUPPLIER_OF_DISTRIBUTOR,
  PROCESS_TYPE_UNDEFINED,
]

export const STATUS_ALLOW_GET_DATA_ANALYTICS = [
  PURCHASE_REQUEST_RECOMMENDED,
  PURCHASE_REQUEST_CREATED,
]

export const PRE_ORDER_YES = 'pre_order_yes'
export const PRE_ORDER_NO = 'pre_order_no'

export const PURCHASE_REQUEST_FILTER_DATE = [IS_LAST_7_DAYS, IS_LAST_30_DAYS]

export const IS_PRE_ORDER_SELECT = {
  [PRE_ORDER_YES]: 'Yes',
  [PRE_ORDER_NO]: 'No',
}

export const DATE_SELECT = {
  [IS_LAST_7_DAYS]: 'in last 7 days',
  [IS_LAST_30_DAYS]: 'in last 30 days',
}

export const PURCHASE_REQUEST_TAB_MAPPING = {
  [PURCHASE_REQUEST_ALL]: 'ALL',
  [PURCHASE_REQUEST_RECOMMENDED]: 'RECOMMENDED',
  [PURCHASE_REQUEST_CREATED]: 'CREATED',
  [PURCHASE_REQUEST_QUOTING]: 'QUOTING',
  [PURCHASE_REQUEST_QUOTED]: 'QUOTED',
  [PURCHASE_REQUEST_CLOSED]: 'CLOSED',
  [PURCHASE_REQUEST_CANCELLED]: 'CANCELLED',
}

export const PURCHASE_REQUEST_STATUS_SELECT = {
  [PURCHASE_REQUEST_RECOMMENDED]: 'RECOMMENDED',
  [PURCHASE_REQUEST_CREATED]: 'CREATED',
  [PURCHASE_REQUEST_QUOTING]: 'QUOTING',
  [PURCHASE_REQUEST_QUOTED]: 'QUOTED',
  [PURCHASE_REQUEST_CLOSED]: 'CLOSED',
  [PURCHASE_REQUEST_CANCELLED]: 'CANCELLED',
  [PURCHASE_REQUEST_CONFIRMED]: 'CONFIRMED',
}

export const PURCHASE_REQUEST_STATUS_COLOR_MAPPING = {
  [PURCHASE_REQUEST_RECOMMENDED]: 'geekblue',
  [PURCHASE_REQUEST_CREATED]: 'blue',
  [PURCHASE_REQUEST_QUOTING]: 'warning',
  [PURCHASE_REQUEST_QUOTED]: 'green',
  [PURCHASE_REQUEST_CLOSED]: 'default',
  [PURCHASE_REQUEST_CANCELLED]: 'red',
  [PURCHASE_REQUEST_CONFIRMED]: 'green',
}

export const COLOR_PROCESS_TYPE_MAPPING = {
  [PURCHASE_REQUEST_RECOMMENDED]: '#1d39c2',
  [PURCHASE_REQUEST_CREATED]: '#0b6ed9',
  [PURCHASE_REQUEST_QUOTING]: '#faad14',
  [PURCHASE_REQUEST_QUOTED]: '#379e0e',
  [PURCHASE_REQUEST_CLOSED]: '#d9d9d9',
  [PURCHASE_REQUEST_CANCELLED]: '#cf1521',
}

export const ALLOW_EDIT_PURCHASE_REQUEST_STATUS = [
  PURCHASE_REQUEST_CREATED,
  PURCHASE_REQUEST_RECOMMENDED,
]

export const OBJECT_COLUMN_MAPPING_BY_PROCESS_TYPE = {
  retail: [
    'remove',
    'sku',
    'brandGroups',
    'brands',
    'minimumOrderQuantity',
    'quantity',
    'purchasePrice',
    'referralPrice',
    'vigoCommissions',
    'estimateProfit',
    'estimateSaleAgentCommission',
    'totalReferralAmount',
    'totalEstimateProfit',
    'note',
  ],
  consignment: [
    'remove',
    'sku',
    'brandGroups',
    'brands',
    'minimumOrderQuantity',
    'quantity',
    'purchasePrice',
    'referralPrice',
    'vigoCommissions',
    'estimateProfit',
    'estimateSaleAgentCommission',
    'totalReferralAmount',
    'totalEstimateProfit',
    'note',
  ],
  supplier_of_distributor: [
    'remove',
    'sku',
    'brandGroups',
    'brands',
    'quantity',
    'purchasePrice',
    'purchasePriceVAT',
    'estimateProfit',
    'totalEstimateProfit',
    'note',
  ],
  undefined: [
    'remove',
    'sku',
    'brandGroups',
    'brands',
    'quantity',
    'estimateProfit',
    'totalEstimateProfit',
    'note',
  ],
}

export const OBJECT_COLUMN_MAPPING_PURCHASING_VIEW = {
  retail: [
    'remove',
    'sku',
    'brandGroups',
    'brands',
    'minimumOrderQuantity',
    'quantity',
    'referralPrice',
    'estimateProfit',
    'estimateSaleAgentCommission',
    'totalReferralAmount',
    'totalEstimateProfit',
    'note',
  ],
  consignment: [
    'remove',
    'sku',
    'brandGroups',
    'brands',
    'minimumOrderQuantity',
    'quantity',
    'referralPrice',
    'estimateProfit',
    'estimateSaleAgentCommission',
    'totalReferralAmount',
    'totalEstimateProfit',
    'note',
  ],
  supplier_of_distributor: [
    'sku',
    'brandGroups',
    'brands',
    'quantity',
    'purchasePrice',
    'purchasePriceVAT',
    'estimateProfit',
    'totalEstimateProfit',
    'note',
  ],
  undefined: [
    'remove',
    'sku',
    'brandGroups',
    'brands',
    'quantity',
    'referralPrice',
    'estimateProfit',
    'totalReferralAmount',
    'totalEstimateProfit',
    'note',
  ],
}
