export const PURCHASING_VI = {
  pageName: 'Thu mua',
  purchaseOrderTrends: 'Xu hướng đơn mua hàng',
  shortcuts: 'Phím tắt của bạn',
  purchaseRequest: 'Yêu cầu mua hàng',
  vigoQuotation: 'Báo giá từ Vigo',
  purchaseOrder: 'Đơn mua hàng',
  paymentRequest: 'Yêu cầu thanh toán',
  purchasingWorkflow: 'Quy trình mua hàng',
}
