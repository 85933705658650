import {
  INVENTORY_ENTITY_TYPE_INBOUND_ITEM,
  INVENTORY_ENTITY_TYPE_ORDER_ITEM,
  INVENTORY_ENTITY_TYPE_OUTBOUND_ITEM,
  INVENTORY_ENTITY_TYPE_OVERRIDE,
  INVENTORY_ENTITY_TYPE_PRODUCT_LISTING,
  INVENTORY_ENTITY_TYPE_PRODUCT_TRADING_STOCK,
  INVENTORY_ENTITY_TYPE_RETURN_ITEM,
  INVENTORY_ENTITY_TYPE_TRANSFER_STOCK,
  INVENTORY_ENTITY_TYPE_UPLOAD_OVERRIDE,
  INVENTORY_EXPORT_BY_BATCH,
  INVENTORY_EXPORT_MOVEMENT,
  INVENTORY_EXPORT_SUMMARY,
  INVENTORY_STATUS_AWAITING_FOR_SALE,
  INVENTORY_STATUS_DAMAGED,
  INVENTORY_STATUS_IN_TRANSIT,
  INVENTORY_STATUS_RESERVED,
  INVENTORY_STATUS_SALABLE,
  INVENTORY_TYPE_INBOUND,
  INVENTORY_TYPE_OUTBOUND,
  INVENTORY_TYPE_UPLOAD_AWAITING_FOR_SALE,
  PROCESS_TYPE_CONSIGNMENT,
  PROCESS_TYPE_LISTING,
  PROCESS_TYPE_RETAIL,
  PROCESS_TYPE_TRADING,
  PROCESS_TYPE_TRADING_BY_LISTING,
} from 'utils/inventoryUtils'

const INVENTORY_EXPORT_TYPES = {
  [INVENTORY_EXPORT_SUMMARY]: 'Export Inventory Summary',
  [INVENTORY_EXPORT_MOVEMENT]: 'Export Inventory Movement',
  [INVENTORY_EXPORT_BY_BATCH]: 'Export Inventory By Batch',
}

const INVENTORY_ENTITY_TYPE = {
  [INVENTORY_ENTITY_TYPE_INBOUND_ITEM]: 'Inbound item',
  [INVENTORY_ENTITY_TYPE_PRODUCT_TRADING_STOCK]: 'Product Trading Stock',
  [INVENTORY_ENTITY_TYPE_OUTBOUND_ITEM]: 'Outbound item',
  [INVENTORY_ENTITY_TYPE_ORDER_ITEM]: 'Order item',
  [INVENTORY_ENTITY_TYPE_PRODUCT_LISTING]: 'Product listing',
  [INVENTORY_ENTITY_TYPE_OVERRIDE]: 'Override',
  [INVENTORY_ENTITY_TYPE_UPLOAD_OVERRIDE]: 'Upload override',
  [INVENTORY_TYPE_UPLOAD_AWAITING_FOR_SALE]:
    'Upload override awaiting for sale',
  [INVENTORY_ENTITY_TYPE_RETURN_ITEM]: 'Return item',
  [INVENTORY_ENTITY_TYPE_TRANSFER_STOCK]: 'Stock transfer',
}

const INVENTORY_TYPE = {
  [INVENTORY_TYPE_INBOUND]: 'Inbound',
  [INVENTORY_TYPE_OUTBOUND]: 'Outbound',
}

const INVENTORY_PROCESS_TYPE_SELECT = {
  [PROCESS_TYPE_TRADING]: 'Trading',
  [PROCESS_TYPE_CONSIGNMENT]: 'Consignment',
  [PROCESS_TYPE_RETAIL]: 'Retail',
  [PROCESS_TYPE_LISTING]: 'Listing',
  [PROCESS_TYPE_TRADING_BY_LISTING]: 'Trading By Listing',
}

const INVENTORY_STATUS = {
  [INVENTORY_STATUS_AWAITING_FOR_SALE]: 'AWAITING FOR SALE',
  [INVENTORY_STATUS_SALABLE]: 'SALABLE',
  [INVENTORY_STATUS_DAMAGED]: 'DAMAGED',
  [INVENTORY_STATUS_RESERVED]: 'RESERVED',
  [INVENTORY_STATUS_IN_TRANSIT]: 'IN TRANSIT',
}

export const INVENTORY_EN = {
  casePerUnit: ' Case / Unit',
  cogs: 'COGS',
  awaitingForSale: 'Awaiting For Sale',
  salableStock: 'Salable stock',
  reservedStock: 'Reserved stock',
  damagedStock: 'Damaged stock',
  totalStock: 'Total stock',
  invValuePreTax: 'Inv Value (pre tax)',
  invValuePostTax: 'Inv Value (post tax)',
  noOfCase: 'No of case',
  noOfPcs: 'No of Pcs',
  pack: 'Pack',
  maximumRetailPrice: 'MRP / Unit',
  totalMRP: 'Total MRP (with Tax)',
  qtyByBatches: 'Quantity by batches',
  inventoryMovementStates: 'Inventory Movement States',
  inCase: 'In Case',
  inPcs: 'In Pieces',
  notFound: 'Inventory not found!',
  inventoryMovementExport: 'Inventory Movement Export',
  totalQtyCasePcs: 'Total Qty (in cases/pieces)',
  creationTime: 'Creation Time',
  entityType: 'Entity Type',
  selectEntityType: 'Select type',
  type: 'Type',
  selectType: 'Select type',
  orderID: 'Order ID',
  statuses: 'Statuses',
  selectStatuses: 'Select statuses',
  description: 'Description',
  inventoryHistories: 'Inventory Histories',
  brand: 'Brand',
  market: 'Market',
  productSegment: 'Product Segment',
  product_listing_v2: 'Product Listing',
  ...INVENTORY_EXPORT_TYPES,
  ...INVENTORY_ENTITY_TYPE,
  ...INVENTORY_TYPE,
  ...INVENTORY_STATUS,
  ...INVENTORY_PROCESS_TYPE_SELECT,
}
