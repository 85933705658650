export const AGENT_EN = {
  title: 'Agent Management',
  agent: 'Agent',
  all: 'ALL',
  inactive: 'IN-ACTIVE',
  active: 'ACTIVE',
  agentType: 'Agent Type',
  inventoryValue: 'Inventory Value Information',
  collectionHistory: 'Collection History',
  saleSupervisor: 'Sale Supervisor',
  agentCode: 'Agent Code',
  saleSupervisorCode: 'Sale Supervisor Email',
  saleSupervisorName: 'Sale Supervisor Name',
  createNewAgent: 'Create New Agent',
  updateAgent: 'Update Agent Information',
  linkAgent: 'Link Agent Information',
  unlinkAgent: 'Unlink Agent Information',
  requiredFirstName: 'Please input first name of agent',
  requiredLastName: 'Please input last name of agent',
  requiredPhone: 'Please input phone number',
  requiredSaleSupervisor: 'Please choose one sale supervisor',
  updateAgentSuccess: 'Created agent, please wait for admin approve',
  createAgentSuccess: 'Create agent request created',
  linkAgentSuccess: 'Link agent successfully',
  agentPhone: 'Agent Phone',
  agentEmail: 'Agent Email',
  'in-active': 'IN-ACTIVE',
  collectionHistoryOf: 'Collection History of',
  outletDetail: 'Outlet details',
  agentDetail: 'Agent details',
  totalInvAllocated: 'Total Value Inventory Allocated',
  inventoryValueAvailable: 'Inventory Value Available',
  outstandingInvValue: 'Outstanding Inventory Value',
}
