import {
  ORDER_LIST,
  PURCHASE_ORDER_LIST,
  PURCHASING,
} from 'common/config/routes'

export const ENTITY_TYPE_TRADING = 'trading_by_listing'
export const ENTITY_TYPE_DPO = 'distributor_purchase_order'
export const ENTITY_TYPE_PO = 'purchase_order'
export const ENTITY_TYPE_ORDER = 'fulfillment_order'

export const BUDGET_STATUS_ACTIVE = 'active'
export const BUDGET_STATUS_INACTIVE = 'inactive'

export const BUDGET_TYPE_AUTO = 'auto'
export const BUDGET_TYPE_SPECIAL = 'special'

export const BUDGET_TYPES = [BUDGET_TYPE_AUTO, BUDGET_TYPE_SPECIAL]

export const BUDGET_STATUSES = [BUDGET_STATUS_ACTIVE, BUDGET_STATUS_INACTIVE]

export const SOURCE_MAPPING = [
  ENTITY_TYPE_TRADING,
  ENTITY_TYPE_DPO,
  ENTITY_TYPE_PO,
  ENTITY_TYPE_ORDER,
]

export const LINK_REDIRECT_MAPPING = {
  [ENTITY_TYPE_DPO]: `${PURCHASING}/distributor-purchase-order`,
  [ENTITY_TYPE_PO]: `${PURCHASE_ORDER_LIST}`,
  [ENTITY_TYPE_ORDER]: `${ORDER_LIST}`,
}

export const BUDGET_STATUS_COLOR_MAPPING = {
  [BUDGET_STATUS_ACTIVE]: 'blue',
  [BUDGET_STATUS_INACTIVE]: 'red',
}
