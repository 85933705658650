export const RIDER_VI = {
  firstName: 'Tên',
  lastName: 'Họ',
  createNewRider: 'Tạo mới tài xế',
  name: 'Họ và tên',
  phone: 'Số điện thoại',
  active: 'Hoạt động',
  totalPendingPackage: 'Tổng số lượng kiện hàng đang chờ',
  editRider: 'Chỉnh sửa tài xế',
  deleteRider: 'Xóa tài xế',
  rider: 'Tài xế',
  updateRider: 'Cập nhật tài xế',
}
