export const REMITTANCE_VI = {
  code: 'Mã thu tiền',
  cashierPlaceholder: 'Chọn hoặc nhập tên để tìm kiếm',
  cashier: 'Thu ngân',
  actualAmount: 'Giá trị thực nhận thu từ tài xế',
  actualPendingAmount: 'Giá trị thực tế chờ xử lý',
  differentAmount: 'Giá trị chênh lệch',
  totalAmount: 'Tổng cộng giá trị',
  totalPackage: 'Tổng cộng kiện hàng',
  createNewRemittance: 'Tạo mới yêu cầu thu tiền',
  remittanceDetail: 'Chi tiết yêu cầu thu tiền',
  riderID: 'Mã định danh tài xế',
  actualCollectedAmount: 'Giá trị thực nhận thu từ khách hàng',
  deliveredDate: 'Ngày giao hàng thành công',
  remittanceSubmitStops: 'Thu tiền theo điểm giao hàng',
  actualAmountValidMsg: 'Không thể lớn hơn 3% Giá trị thực tế chờ xử lý',
  riderUnremitted: 'Tài xế chưa nộp tiền',
  pendingDays: 'Số ngày chờ xử lý',
}
