const BIDDING_INVITATION_STATUS = {
  requested: 'Đã yêu cầu',
  submitted: 'Đã nộp',
}

export const BIDDING_INVITATION_VI = {
  code: 'Mã mời đấu thầu',
  biddingEndDate: 'Thời gian kết thúc đấu thầu',
  submitBidding: 'Nộp đấu thầu',
  supplierProvince: 'Nhà cung cấp theo tỉnh',
  searchBidding: 'Tìm kiếm mã đấu thầu',
  biInfo: 'Thông tin lời mời đấu thầu',
  biItems: 'Danh sách sản phẩm lời mời đấu thầu',
  requestQty: 'Số lượng yêu cầu',
  biddingQty: 'Số lượng đấu thầu',
  biddingPrice: 'Giá đấu thầu',
  submitBI: 'Nộp lời mời đấu thầu',
  deliveryInfo: 'Thông tin giao hàng',
  warehouseName: 'Tên kho hàng',
  warehouseProvince: 'Tỉnh kho hàng',
  addComment: 'Thêm phản hồi...',
  submit: 'Nộp',
  ...BIDDING_INVITATION_STATUS,
}
