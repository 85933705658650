import { BUDGET_TYPE_AUTO, BUDGET_TYPE_SPECIAL } from 'utils/budget'

const BUDGET_TYPES_EN = {
  [BUDGET_TYPE_AUTO]: 'Different Price',
  [BUDGET_TYPE_SPECIAL]: 'Special',
}

export const BUDGET_EN = {
  retail_process: 'Retail Process',
  automatic_subsidy: 'Automatic Subsidy',
  active: 'Active',
  inactive: 'DeActive',
  ...BUDGET_TYPES_EN,
}
