export const HOME_VI = {
  homePage: 'Công việc cần làm',
  labelRecommendedPurchaseOrder: 'Đề nghị mua hàng cần xác nhận',
  labelCreatedVigoQuotation: 'Báo giá mua hàng cần xác nhận',
  labelAwaitingPaymentDPO: 'Đơn mua hàng cần thanh toán',
  labelCreatedOrder: 'Đơn bán hàng cần xử lý',
  distributor: 'Nhà phân phối',
  placeholderDistributor: 'Chọn nhà phân phối',
  notification: 'Thông báo',
  emptyNotification: 'Bạn chưa có thông báo nào',
  more: 'Xem thêm',
  next: 'Kế tiếp',
  back: 'Trước đó',
  markAllRead: 'Đánh dấu tất cả đã đọc',
  seeMoreNotification: 'Xem thêm thông báo',
  all: 'Tất cả',
  unread: 'Chưa đọc',
  seen: 'Đã đọc',
  created: 'Chưa đọc',
}
