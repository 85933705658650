import {
  TRADE_ORDER_STATUS_CANCELLED,
  TRADE_ORDER_STATUS_COMPLETED,
  TRADE_ORDER_STATUS_COMPLETED_DELIVERED,
  TRADE_ORDER_STATUS_COMPLETED_PARTIAL,
  TRADE_ORDER_STATUS_CREATED,
  TRADE_ORDER_STATUS_FAILED,
  TRADE_ORDER_STATUS_IN_DELIVERY,
  TRADE_ORDER_STATUS_PENDING,
  TRADE_ORDER_STATUS_PROCESSING,
} from 'utils/tradeOrder'

const TRADE_ORDER_STATUS_EN = {
  [TRADE_ORDER_STATUS_CREATED]: 'CREATED',
  [TRADE_ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [TRADE_ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [TRADE_ORDER_STATUS_CANCELLED]: 'CANCELLED',
  [TRADE_ORDER_STATUS_PENDING]: 'PENDING',
  [TRADE_ORDER_STATUS_IN_DELIVERY]: 'IN DELIVERY',
  [TRADE_ORDER_STATUS_COMPLETED_DELIVERED]: 'COMPLETED',
  [TRADE_ORDER_STATUS_COMPLETED_PARTIAL]: 'COMPLETED',
  [TRADE_ORDER_STATUS_FAILED]: 'FAILED',
}

export const TRADE_ORDERS_EN = {
  allStatus: 'All Status',
  pre_order: 'Pre-order',
  available: 'Available',
  fulfillmentOrderButton: 'Fulfillment Order',
  confirmToFulfillment: 'Confirm To Fulfillment',
  messageProductNotEnough:
    'Currently {quantity} products are not enough for delivery:',
  messageRemoveItemFulfillment:
    'Insufficient items will be partial fulfillment when clicking on Fulfillment Order. Would you like to add more of insufficiently stocked products?',
  buttonNoConfirmFulfillment: 'No, continue fulfillment',
  buttonYesConfirmFulfillment: 'Yes, please wait for me to inbound',
  currentStock: 'current stock',
  warningPreOrders: 'Warning To Fulfillment',
  all: 'ALL',
  subtotalDes: 'Subtotal = Original Price x Qty Ordered',
  discountDes: 'Discount = Total SKU Promo Discount',
  grossSACommissionsDes: 'Total Gross SA Commission for all SKUs on the order',
  grandTotalDes:
    'The price after discount and including shipping fees for the entire order',
  totalPaidDes:
    'Total Paid= Grand Total + SA Commission for all SKUs on the order',
  totalRefunded: 'Total Refunded = Qty Refunded x Price',
  totalDueDes: 'Total Due = Total Paid - Total Refunded',
  category: 'Cate Promotion',
  sku: 'SKU Promotion',
  cart_total: 'Cart Promotion',
  cancelTradeOrder: 'Cancel trader Order',
  reasonCancel: 'Reasons to cancel order:',
  createNew: 'Cancel to create new one',
  duplicateOrder: 'Duplicate Order',
  outletTooFar: 'Outlet too far',
  salesAgentCancel: 'Sales Agent asked to cancel',
  insufficientInventory: 'Insufficient Inventory',
  product: 'Product Promotion',
  cart: 'Cart Promotion',
  flexible: 'Flexible Promotion',
  bundle: 'Bundle Promotion',
  brand: 'Brand Promotion',
  type: 'Type',
  price: 'Price Details',
  others: 'Others',
  ...TRADE_ORDER_STATUS_EN,
}
