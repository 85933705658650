const INBOUND_STATUS = {
  created: 'Mới',
  distributor_approved: 'Nhà phân phối duyệt',
  finance_approved: 'Kế toán duyệt',
  rejected: 'Từ chối',
  bgm_confirmed: 'BGM xác nhận',
  all: 'Tất cả',
}

const INBOUND_STATUS_TRANSITION_ACTION = {
  distributor_approved: 'Nhà phân phối duyệt',
  finance_approved: 'Kế toán duyệt',
  rejected: 'Từ chối',
  created: 'Chuyển sang Mới',
  bgm_confirmed: 'BGM xác nhận',
}

export const INBOUND_VI = {
  number: 'Mã phiếu nhập hàng',
  totalPrice: 'Tổng giá trị',
  totalQuantity: 'Tổng số lượng',
  note: 'Ghi chú',
  paymentTerm: 'Chính sách thanh toán',
  notFoundMessage: 'Không tìm thấy đơn nhập hàng!',
  statusHistory: 'Lịch sử trạng thái',
  statusAction: INBOUND_STATUS_TRANSITION_ACTION,
  additionalInfo: 'Thông tin thêm',
  export: 'Xuất sản phẩm',
  exportMessage: {
    success: 'Xuất thành công!',
    error: 'Xuất không thành công!',
  },
  addBatch: 'Thêm số lô',
  removeBatch: 'Xóa số lô',
  inboundItems: 'Danh sách sản phẩm',
  qtyOrdered: 'Số lượng đã mua',
  printInvoice: 'In hóa đơn',
  warningMessageMassUpdate:
    'Vui lòng kiểm tra lại SKUs: {skus}. Mã dược quốc gia, Giá thực tế, Giá sau thuế phải giống nhau ở tất cả các lô',
  validateMessage: {
    expirationDate: 'Ngày hết hạn phải sau ngày hiện tại!',
    invalidQty: 'Tổng số lượng phải bằng số lượng nhập hàng!',
    submitError: {
      skus: 'SKUs {errSKU}: Tổng số lượng thực nhận phải bằng số lượng nhập hàng!',
      batches:
        'Số lô {errBatch}: Tổng số lượng thực nhận và số lượng từ chối phải lớn hơn 0!',
    },
    shippingFee: 'Phí giao hàng phải >= 0!',
  },
  paid: 'Đã thanh toán',
  unpaid: 'Chưa thanh toán',
  not_yet: 'Chưa có hóa đơn',
  partial: 'Hóa đơn một phần',
  full: 'Đầy đủ hóa đơn',
  printInboundNote: 'Phiếu nhập hàng',
  ...INBOUND_STATUS,
}
