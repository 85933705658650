export const DISTRIBUTOR_CONTRACT_FEE_EN = {
  contractItem: 'Contract item',
  feeType: 'Fee Type',
  marketing: 'Marketing',
  warehousing: 'Warehousing',
  delivery: 'Delivery',
  serviceFee: 'Service Fee',
  fromQuantity: 'From quantity',
  toQuantity: 'To quantity',
  feeValue: 'Fee value',
  title: 'Distributor Contract Fee Management',
  service_fee: 'Service fee',
  per_case: 'Per case',
  per_unit: 'Per unit',
  percent: 'Percent',
  effectiveFrom: 'Effective From',
  effectiveTo: 'Effective To',
  effectiveFromTo: 'Effective From - To',
  confirmDelete: 'Delete contract fee - Distributor name: {name}',
  contentConfirmDelete:
    'All contract fee information of this distributor will be deleted',
  yes: 'Yes, please',
  no: 'No',
  distributorContractFee: 'Distributor Contract Fee',
  titleEditModal: 'Edit Contract Fee',
  historyUpdated: 'History updated',
  warningCancel: 'Are you sure cancel this update?',
  limitFeeAmount: 'Limit Fee Amount',
  updatedValue: 'Updated Value',
  previousValue: 'Previous Value',
  salePrice: 'Sale Price',
}
