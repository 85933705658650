import { BUDGET_TYPE_AUTO, BUDGET_TYPE_SPECIAL } from 'utils/budget'

const BUDGET_TYPES_VI = {
  [BUDGET_TYPE_AUTO]: 'Chênh giá',
  [BUDGET_TYPE_SPECIAL]: 'Đặc biệt',
}
export const BUDGET_VI = {
  retail_process: 'Quy trình 2 - Retail',
  automatic_subsidy: 'Trợ giá tự động',
  active: 'Đang chạy',
  inactive: 'Dừng',
  ...BUDGET_TYPES_VI,
}
