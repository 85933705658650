import {
  UPDATE_HISTORY_ENTITY_DELIVERY_LEAD_TIME,
  UPDATE_HISTORY_ENTITY_PACKAGE_TYPE,
  UPDATE_HISTORY_ENTITY_SA_COMMISSION,
  UPDATE_HISTORY_ENTITY_SA_COMMISSION_PACKAGE_TYPE,
  UPDATE_HISTORY_ENTITY_SA_COMMISSION_UNIT,
  UPDATE_HISTORY_ENTITY_SELLING_PRICE,
  UPDATE_HISTORY_ENTITY_STATUS,
  UPDATE_HISTORY_ENTITY_STOCK,
} from 'utils/productPreOrder'

const UPDATE_HISTORY_ENTITY_VN = {
  [UPDATE_HISTORY_ENTITY_SELLING_PRICE]: 'Giá bán sản phẩm đặt hàng trước',
  [UPDATE_HISTORY_ENTITY_STOCK]: 'Số lượng đặt trước',
  [UPDATE_HISTORY_ENTITY_DELIVERY_LEAD_TIME]: 'Thời gian giao hàng (Ngày)',
  [UPDATE_HISTORY_ENTITY_STATUS]: 'Trạng thái',
  [UPDATE_HISTORY_ENTITY_PACKAGE_TYPE]: 'Đơn vị bán hàng',
  [UPDATE_HISTORY_ENTITY_SA_COMMISSION]: 'Hoa hồng nhân viên bán hàng',
  [UPDATE_HISTORY_ENTITY_SA_COMMISSION_UNIT]: 'Số lượng Đơn vị hoa hồng',
  [UPDATE_HISTORY_ENTITY_SA_COMMISSION_PACKAGE_TYPE]: 'Đơn vị hoa hồng',
}
export const PRODUCT_PRE_ORDER_VI = {
  ...UPDATE_HISTORY_ENTITY_VN,
  productPreOrder: 'Quản lý sản phẩm đặt trước',
  messageErrorGetPreOrderFailed: 'Lấy dữ liệu sản phẩm đặt trước thất bại!',
  afterValueDays: 'Sau {day} ngày',
  preOrderSellingPrice: 'Giá bán sản phẩm đặt hàng trước',
  preOrderStock: 'Số lượng đặt trước',
  createProductPreOrder: 'Tạo sản phẩm đặt hàng trước',
  createProductPreOrderSuccess: 'Tạo sản phẩm đặt trước thành công!',
  preOrderAlreadyExist:
    'Sản phẩm đặt hàng trước đã tồn tại, vui lòng sử dụng thông tin đang có',
  createProductPreOrderFailed: 'Tạo sản phẩm đặt trước thất bại!',
  getProductPreOrderFailed: 'Lấy dữ liệu chi tiết sản phẩm đặt trước thất bại!',
  updateProductPreOrder: 'Cập nhật sản phẩm đặt hàng trước',
  updateProduct: 'Cập nhật sản phẩm',
  messageGetUpdateHistoryFailed: 'Lấy dữ liệu lịch sử thay đổi thất bại!',
  updatedInformation: 'Thông tin đã cập nhật',
  previousInformation: 'Thông tin trước đó',
  deliveryLeadTime: 'Thời gian giao hàng (Ngày)',
  messageSelectSku: 'Nhấn vào để thêm <link>một hoặc nhiều SKU</link>',
  ratioRefillStock: 'Tỷ lệ hồi tồn',
  purchasePrice: 'Giá mua',
  refreshCommission: 'Lấy giá trị mới nhất',
}
