export const PRODUCT_LISTINGS_VI = {
  productListingManagement: 'Sản phẩm niêm yết',
  createProductListing: 'Tạo mới sản phẩm niêm yết',
  refresh: 'Làm mới',
  sku: 'SKU',
  productName: 'Tên sản phẩm',
  warehouseCode: 'Mã kho hàng',
  searchWarehouse: 'Chọn hoặc nhập tên để tìm kiếm',
  status: 'Trạng thái',
  selectStatus: 'Chọn Trạng thái',
  requested: 'YÊU CẦU',
  accepted: 'CHẤP NHẬN',
  rejected: 'TỪ CHỐI',
  terminated: 'KẾT THÚC',
  currentStockUpdateRequest: 'Yêu cầu cập nhật tồn kho',
  warehouse: 'Kho hàng',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  price: 'Giá',
  stockUpdateRequest: 'Yêu cầu cập nhật tồn kho',
  currentSellingType: 'Dạng giá bán hiện tại',
  commission: 'Hoa hồng',
  saleAgentCommission: 'Hoa hồng cho nhân viên bán hàng',
  saCommissionUnit: 'Đơn vị hoa hồng',
  currentMagentoStock: 'Tồn hiện tại trên Magento',
  actions: 'Thao tác',
  updateProductListing: 'Cập nhật sản phẩm niêm yết',
  updateProductListingFail: 'Cập nhật sản phẩm niêm yết thất bại',
  createProductListingFail: 'Tạo sản phẩm niêm yết thất bại',
  'startDate must be in future': 'ngày bắt đầu phải lớn hơn ngày hiện tại',
  update: 'Cập nhật sản phẩm',
  cancel: 'Hủy',
  requestedDate: 'Ngày yêu cầu',
  requestedBy: 'Người yêu cầu',
  updateStockRequest: 'Yêu cầu cập nhật tồn kho',
  updateStockHistory: 'Cập nhật tồn sản phẩm niêm yết',
  no: 'Số thứ tự',
  approveDate: 'Ngày duyệt',
  requestBy: 'Người yêu cầu',
  approvalBy: 'Người duyệt',
  newStock: 'Tồn mới',
  state: 'Trạng thái',
  updateStock: 'Tạo mới yêu cầu cập nhật tồn kho',
  currentStocks: 'Tồn hiện tại',
  requestDate: 'Ngày yêu cầu',
  approvalDate: 'Ngày duyệt',
  reject: 'Từ chối',
  approved: 'ĐÃ DUYỆT',
  approve: 'Duyệt',
  newStocks: 'Tồn mới',
  create: 'Tạo',
  search: 'Tìm kiếm',
  reset: 'Cài lại',
  pending: 'CHỜ XỬ LÝ',
  createdDate: 'Ngày tạo',
  updatedDate: 'Ngày cập nhật',
  distributorCode: 'Mã Nhà phân phối',
  messageDateError:
    'Tạo sản phẩm niêm yết thất bại, ngày bắt đầu phải lớn hơn ngày hiện tại!',
  requireSKU: 'Vui lòng chọn SKU',
  requireWarehouse: 'Vui lòng chọn kho hàng',
  requireStartDate: 'Vui lòng chọn ngày bắt đầu',
  requirePrice: 'Vui lòng nhập giá',
  requireCommission: 'Vui lòng nhập hoa hồng',
  requireSaleAgentCommission: 'Vui lòng nhập hoa hồng cho nhân viên bán hàng',
  requireSaleAgentCommissionUnit: 'Vui lòng nhập đơn vị hoa hồng',
  productListUpdated: 'Sản phẩm niêm yết được cập nhật thành công',
  productListCreated: 'Sản phẩm niêm yết được tạo thành công',
  inventoryUpdateRequest: 'Đang có yêu cầu cập nhật tồn đang đợi xử lí',
  newUpdateStocksCreated: 'Yêu cầu cập nhật tồn kho đã được tạo',
  productListingDetail: 'Sản phẩm niêm yết chi tiết',
  productListingID: 'Sản phẩm niêm yết ID',
  created: 'MỚI',
  selectDate: 'Chọn ngày',
  newStockRequired: 'Vui lòng nhập tồn mới',
  approvedMessage: 'Đã duyệt',
  rejectedMessage: 'Đã từ chối',
  updateStatusSuccess: 'Cập nhật trạng thái thành công! ',
  alertDropMenuProductListing:
    'Quy trình Listing sẽ dừng hoạt động kể từ ngày 22-01-2024. Nhà phân phối vui lòng chọn Quy trình khác theo hướng dẫn sau: <link>Tài liệu hướng dẫn</link>',
  listingGroupNumber: 'Mã nhóm sản phẩm',
  all: 'TẤT CẢ',
  active: 'ĐANG HOẠT ĐỘNG',
  active_soon: 'SẮP HOẠT ĐỘNG',
  awaiting_approve: 'CHỜ DUYỆT',
  need_adjust: 'CẦN ĐIỀU CHỈNH',
  draft: 'NHÁP',
  distributorSellingPrice: 'Giá bán của NPP',
  productSku: 'Tên sản phẩm - SKU',
  titleCreate: 'Tạo sản phẩm niêm yết',
  warningTerminate:
    'Hệ thống sẽ tự động hạ tồn cho sản phẩm này và dừng bán trên App',
  productListing: 'Sản phẩm niêm yết',
  messageErrorAlreadyExistsActive:
    'Tạo thất bại! Bạn có một sản phẩm niêm yết đang hoạt động, <link>nhấn vào đây để xem chi tiết</link>',
  currentStock: 'Tồn hiện tại',
  totalUpdatedStock: 'Tổng tồn đã cập nhật',
}
