import ACL from 'common/config/acl'
import { HOME } from 'common/config/routes'
import get from 'lodash/get'
import { useSession } from 'next-auth/react'
import { useMemo } from 'react'

const getAccessibleRoutes = (userRoles: string[]): string[] => {
  if (userRoles.length === 0) return []

  return userRoles.reduce((prev, role) => {
    const arr = Array.isArray(ACL[role]) ? [...ACL[role]] : []
    return [HOME, ...prev, ...arr]
  }, [])
}

const useACL = () => {
  const { data: session } = useSession()

  const userRoles = useMemo(() => {
    return get(session, 'user.roles', []) as string[]
  }, [session])

  const aclRoutes = useMemo(() => getAccessibleRoutes(userRoles), [userRoles])

  return { aclRoutes, userRoles }
}

export default useACL
