// 'Hộ kinh doanh' → business_household

// 'Công ty TNHH' → company_limited

// 'Công ty cổ phần' → join_stock_company

// 'Doanh nghiệp tư nhân' → private_enterprise

//  'Công ty hợp doanh' → partnership_company
export const TYPE_OF_BUSINESS_HOUSEHOLD = 'business_household'
export const TYPE_OF_BUSINESS_LIMITED = 'company_limited'
export const TYPE_OF_JOIN_STOCK_COMPANY = 'join_stock_company'
export const TYPE_OF_BUSINESS_ENTERPRISE = 'private_enterprise'
export const TYPE_OF_BUSINESS_PARTNERSHIP = 'partnership_company'

export const TYPE_CERTIFICATE_HOUSEHOLD = 'household'
export const TYPE_CERTIFICATE_ERC = 'ERC'

export const TYPE_CERTIFICATE_SELECT = {
  [TYPE_CERTIFICATE_ERC]: 'ERC',
  [TYPE_CERTIFICATE_HOUSEHOLD]: 'Household',
}

export const TYPE_OF_BUSINESS_IS_CERTIFICATE_ERC = [
  TYPE_OF_BUSINESS_LIMITED,
  TYPE_OF_JOIN_STOCK_COMPANY,
  TYPE_OF_BUSINESS_ENTERPRISE,
  TYPE_OF_BUSINESS_PARTNERSHIP,
]

export const TYPE_OF_BUSINESS_SELECT = {
  [TYPE_OF_BUSINESS_HOUSEHOLD]: 'Hộ kinh doanh',
  [TYPE_OF_BUSINESS_LIMITED]: 'Công ty TNHH',
  [TYPE_OF_JOIN_STOCK_COMPANY]: 'Công ty cổ phần',
  [TYPE_OF_BUSINESS_ENTERPRISE]: 'Doanh nghiệp tư nhân',
  [TYPE_OF_BUSINESS_PARTNERSHIP]: 'Công ty hợp doanh',
}

export const TYPE_OF_BUSINESS_MODEL_SELECT = [
  'Tổng hợp',
  'Nhà phân phối nhãn chuyên trách',
]
