import {
  PURCHASE_ORDER_DISTRIBUTOR_HUB,
  PURCHASE_ORDER_SUPPLIER_HUB,
} from 'utils/purchaseOrder'

const PURCHASE_ORDER_STATUS = {
  created: 'CREATED',
  supplier_approved: 'SUPPLIER APPROVED',
  approved: 'APPROVED',
  inbound_created: 'INBOUND CREATED',
  inbounded: 'INBOUNDED',
  rejected: 'REJECTED',
  canceled: 'CANCELED',
  pending: 'PENDING',
  all: 'ALL',
  request_high_level_approved: 'NEED APPROVE LEVEL 2',
}

const PURCHASE_ORDER_STATUS_TRANSITION_ACTION = {
  supplier_approved: 'Supplier Confirm',
  approved: 'PO Manager Approve',
  inbound_created: '',
  inbounded: '',
  rejected: 'Reject',
  pending: 'Pending',
  created: 'Confirm',
}

const PURCHASE_ORDER_HUB_TAB = {
  [PURCHASE_ORDER_SUPPLIER_HUB]: 'SUPPLIER HUB',
  [PURCHASE_ORDER_DISTRIBUTOR_HUB]: 'DISTRIBUTOR HUB',
}

export const PO_EN = {
  purchaseOrderManagement: 'Purchase Order Management ',
  biddingCode: 'Bidding Code ',
  createPurchaseOrder: 'Create Purchase Order',
  exportToExcel: 'Export PO selected to MS Excel',
  printDeliveryMinutes: 'Print Goods Delivery Minutes',
  poDate: ' PO Date',
  poNote: 'PO Note',
  totalPrice: 'Total Price',
  addProductMsg: 'Please add supplier products!',
  createPO: 'Create Purchase Order',
  configSupplierProducts: 'Config Supplier Products',
  configSupplierProductWarning:
    'Please select supplier and add supplier products!',
  productPrice: 'Product Price',
  qtyValidateMsg: {
    less: 'Quantity must be less than {qty}',
    greater: 'Quantity must be greater than {qty}',
  },
  priceValidateMsg: 'Price must be between {min} and {max}!',
  priceOutOfRangeMsg: 'Price out of range between {min} and {max}',
  min: 'Min',
  max: 'Max',
  sold: 'Sold',
  inventory: 'Inventory',
  searchPlaceholder: 'Enter keyword to search',
  price: 'Price',
  selectSupplierProducts: 'Select Supplier Products',
  notFound: 'Purchase order not found',
  paymentTerm: 'Payment Term',
  paymentDate: 'Payment Date',
  logisticsCost: 'Logistics Cost',
  plInfo: '3PL Info',
  statusTransition: PURCHASE_ORDER_STATUS_TRANSITION_ACTION,
  logisticsCostValidateMsg: 'Logistics Cost must be > 0',
  poItems: 'PO Items',
  vatAmount: 'VAT Amount',
  actualAmount: 'Actual Amount',
  expectedDeliveryDate: 'Expected Delivery Date',
  massUpdate: 'Mass Update',
  startUpload: 'Start Upload',
  massiveCreatePO: 'Massive Create Purchase Order',
  printDeliveryNote: 'Print Delivery Note',
  ...PURCHASE_ORDER_STATUS,
  ...PURCHASE_ORDER_HUB_TAB,
}
