export const STOCK_TRANSFER_EN = {
  stockTransfer: 'Stock Transfer',
  createStockTransfer: 'Create Stock Transfer',
  sourceWarehouse: 'Source Warehouse',
  destinationWarehouse: 'Destination Warehouse',
  sourceBatchNumber: 'Source Batch Number',
  sourceSalableQuantity: 'Source Salable Quantity',
  destinationBatchNumber: 'Destination Batch Number',
  destinationSalableQuantity: 'Destination Salable Quantity',
  number: 'Number',
  awaiting_approve: 'AWAITING APPROVE',
  rejected: 'REJECTED',
  approved: 'APPROVED',
  requireSourceWarehouse: 'Please select source warehouse',
  requireDestinationWarehouse: 'Please select destination warehouse',
  distributorNotMatch:
    'Source warehouse and destination warehouse must be the same Distributor',
  errorNotMatchBatch:
    'Destination Batch Number must belong the list of Source Batch Number',
  adminApprove: 'Admin Approve',
  stockTransferItems: 'Stock Transfer Items',
  messageErrorStock: 'SKUs {errSKU}: does not have stock to transfer',
}
