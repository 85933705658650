export const REASON_NEED_ADJUST_EN = {
  reasonName: 'Reason Name',
  reasonNeedAdjust: 'Reason Need Adjust',
  addNew: 'Add New',
  createReasonNeedAdjust: 'Create Reason Need Adjust',
  search: 'Please input name to search',
  messageRequiredReasonName: 'Please input Reason Name',
  createReasonSuccessful: 'Create Reason successful',
  createReasonFail: 'Create Reason failed!',
  updateReasonSuccessful: 'Update Reason successful',
  updateReasonFail: 'Update Reason failed!',
}
