export const REPORT_RIDER_EN = {
  vehiclePlate: 'Vehicle Plate',
  packageCreated: 'Package Created',
  packageInDelivery: 'Package In Delivery',
  packageDelivered: 'Package Delivered',
  totalAmount: 'Total Amount',
  actualCollectedAmount: 'Actual Collected Amount',
  pendingAmount: 'Pending Amount',
  packageCreatedDate: 'Package Created Date',
  grandTotal: 'Grand Total',
}
