import {
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_APPROVED,
  PRODUCT_STATUS_AWAITING_APPROVAL,
  PRODUCT_STATUS_INACTIVE,
  PRODUCT_STATUS_REJECTED,
} from 'utils/productAvailable'

const PRODUCT_STATUS_EN = {
  [PRODUCT_STATUS_ACTIVE]: 'ACTIVE',
  [PRODUCT_STATUS_INACTIVE]: 'INACTIVE',
  [PRODUCT_STATUS_AWAITING_APPROVAL]: 'AWAITING APPROVAL',
  [PRODUCT_STATUS_APPROVED]: 'APPROVED',
  [PRODUCT_STATUS_REJECTED]: 'REJECTED',
}

export const PRODUCT_EN = { all: 'ALL', ...PRODUCT_STATUS_EN }
