import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import { Translate } from 'utils/localUtils'

export const MARK_AS_TYPE_FULL_DELIVERED = 'fullDelivered'
export const MARK_AS_TYPE_PARTIAL_DELIVERED = 'partialDelivered'
export const MARK_AS_TYPE_DELIVERY_FAILED = 'deliveryFailed'

export const DROPDOWN_OPTIONS = [
  MARK_AS_TYPE_FULL_DELIVERED,
  MARK_AS_TYPE_PARTIAL_DELIVERED,
  MARK_AS_TYPE_DELIVERY_FAILED,
]

export const AGENT_MAPPING = {
  [MARK_AS_TYPE_FULL_DELIVERED]: 'agent_full_delivered',
  [MARK_AS_TYPE_PARTIAL_DELIVERED]: 'agent_partial_delivered',
  [MARK_AS_TYPE_DELIVERY_FAILED]: 'agent_fail_delivered',
}

interface Props {
  className?: string
  disabled?: boolean
  onClick?: (key: string) => void
  translate: Translate
  isHelpDA: boolean
}

const MarkAsDropdown: React.FC<Props> = ({
  disabled,
  onClick,
  translate,
  isHelpDA = false,
}) => {
  return (
    <Dropdown
      disabled={disabled}
      overlay={
        <Menu
          onClick={(item) => {
            onClick && onClick(item.key)
          }}
        >
          {DROPDOWN_OPTIONS.map((value) => (
            <Menu.Item key={value} className="capitalize">
              {translate(`package.${isHelpDA ? AGENT_MAPPING[value] : value}`)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button>
        {translate('package.markAs')}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default React.memo(MarkAsDropdown)
