export const VIGO_MASTER_QUOTATION_EN = {
  title: 'Vigo Master Quotation',
  vigoMasterNumber: 'Vigo Master Quotation Number',
  all: 'ALL',
  request_high_level_approved: 'NEED APPROVE LEVEL 2',
  pending: 'PENDING',
  awaiting_logistics: 'AWAITING LOGISTICS',
  awaiting_approval: 'AWAITING APPROVAL',
  closed: 'CLOSED',
  number: 'Master Quotation Number',
  selectSuppliers: 'Select Suppliers',
  contentSelectSuppliers: 'Choose Suppliers and Order Quantity',
  arrangeLogistics: 'Arrange Logistics',
  contentArrangeLogistics: "For delivery Supplier doesn't support",
  approval: 'Approval',
  contentApproval: 'Discount and Approval',
  issueQuotation: 'Issue Quotation',
  contentIssueQuotation: "For Distributor's PRs",
  editByBatch: 'Edit By Batch Number',
  updateQuantityByBatch: 'Update Quantity By Batch Number',
  viewTermAndCondition: 'View Terms and Conditions',
  withShip: 'With Ship',
  withoutShip: 'Without Ship',
  competitorPrice: 'Competitor Price',
  maxQuantitySuggest: 'Max Quantity Suggest:',
  soldQuantity: 'Sold Quantity:',
  currentInventory: 'Current Inventory:',
  estimateStockPerDay: 'Estimate Stock Per Day:',
  payToSupplier: 'Pay to supplier',
  contentPayment: 'Pay the supplier before delivery?',
  supplierName: 'Supplier Name',
  pay_after_delivery: 'After Delivery',
  pay_before_delivery: 'Before Delivery',
  pendingRouter: 'Pending Routers',
  sort: 'Sort',
  fromSupplier: 'From-Supplier',
  toDistributor: 'To-Distributor',
  estimateWeight: 'Estimate Weight (kg)',
  deliveryPartner: 'Delivery Partner',
  deliveryGroup: 'Delivery Group',
  confirmArrange: 'Arrange Log & Next',
  distributor_pickup: 'Distributor Pickup',
  newVigoDelivery: 'New Vigo Delivery Summary',
  noOfRoute: 'No. of routes',
  estimateCost: 'Estimated Cost',
  pricePerTon: 'Price / Ton',
  deliveryDiscount: 'Delivery Discount',
  confirmDeadline: 'Confirm Deadline',
  suggestedDeliveryGroup: 'Suggested Delivery Group',
  estCost: 'Estimated Cost',
  totalDelivery: 'Total Delivery',
  purchasedPrice: 'Purchased Price',
}
