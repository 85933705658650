import {
  SUBSIDY_RULE_STATUS_CANCELLED,
  SUBSIDY_RULE_STATUS_COMPLETED,
  SUBSIDY_RULE_STATUS_IN_PROGRESS,
  SUBSIDY_RULE_STATUS_SCHEDULED,
} from 'utils/subsidyUtils'

const SUBSIDY_RULE_STATUS = {
  [SUBSIDY_RULE_STATUS_SCHEDULED]: 'SCHEDULED',
  [SUBSIDY_RULE_STATUS_IN_PROGRESS]: 'IN PROGRESS',
  [SUBSIDY_RULE_STATUS_CANCELLED]: 'CANCELLED',
  [SUBSIDY_RULE_STATUS_COMPLETED]: 'COMPLETED',
}

export const SUBSIDY_EN = {
  subsidyRuleManagement: 'Subsidy Rule Management',
  name: 'Rule Name',
  noOfSku: '# of sku',
  subsidyRuleInfo: 'Subsidy Rule Info',
  effectiveFrom: 'Effective From',
  effectiveTo: 'Effective To',
  subsidyRuleItems: 'Subsidy Rule Items',
  minOrderQuantity: 'Min Order Quantity',
  unitPriceSubsidy: 'Unit Price Subsidy',
  magentoStock: 'Magento Stock',
  createSubsidyRule: 'Create Subsidy Rule',
  generalInfo: 'General Info',
  supportedSkUs: 'Supported SKUs',
  addSkus: 'Add SKUs',
  selectSkus: 'Select Skus',
  incurredAmount: 'Incurred Amount',
  cancelSubsidy: 'Cancel Subsidy Rule',
  cancelSubsidyMsg:
    'Are you sure to cancel this Subsidy Rule? This action CANNOT be undone.',
  unitPrice: 'Unit Price',
  availableStock: 'Available Stock',
  unitPriceSubsidyValid: 'Unit Price Subsidy must > 0',
  poPrice: 'PO Price',
  salePrice: 'Sale Price',
  searchSkuPlaceholder: 'Enter at least 2 letters to search',
  maximumSubsidyAmount: 'Maximum Subsidy Amount',
  maximumOrderQuantity: 'Maximum Order Quantity',
  messErrorEmptySkuSubsidy: 'Please select only 1 SKU!',
  messageSelectSku: 'Click to add <link>one SKU</link> to the invoice.',
  subsidyType: 'Subsidy Type',
  manual: 'Manual',
  auto: 'Different Price',
  special: 'Special',
  ...SUBSIDY_RULE_STATUS,
}
