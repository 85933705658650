export const ORDER_STATUS_CREATED = 'created'
export const ORDER_STATUS_PROCESSING = 'processing'
export const ORDER_STATUS_COMPLETED = 'completed'
export const ORDER_STATUS_CANCELLED = 'cancelled'
export const ORDER_STATUS_PENDING = 'pending'
export const TypeOfCancellationByDistributor = 'by_distributor'

export const ORDER_ITEM_STATUS_CREATED = 'created'
export const ORDER_ITEM_STATUS_PARTIAL = 'partial_delivered'
export const ORDER_ITEM_STATUS_PICKLISTED = 'picklisted'
export const ORDER_ITEM_STATUS_PICKING = 'picking'
export const ORDER_ITEM_STATUS_CHECKING = 'checking'
export const ORDER_ITEM_STATUS_CHECKED_OUT = 'checked_out'
export const ORDER_ITEM_STATUS_CANCELLED = 'cancelled'
export const ORDER_ITEM_STATUS_RETURNED = 'returned'
export const ORDER_ITEM_STATUS_READY_TO_SHIP = 'ready_to_ship'
export const ORDER_ITEM_STATUS_DELIVERED = 'delivered'
export const ORDER_ITEM_STATUS_IN_TRANSIT = 'in_transit'
export const ORDER_ITEM_STATUS_DELIVERY_FAILED = 'delivery_failed'

export const ORDER_ITEM_STATUS_MAPPING = {
  [ORDER_ITEM_STATUS_CREATED]: 'Created',
  [ORDER_ITEM_STATUS_PICKLISTED]: 'Picklisted',
  [ORDER_ITEM_STATUS_PICKING]: 'Picking',
  [ORDER_ITEM_STATUS_CHECKING]: 'Checking',
  [ORDER_ITEM_STATUS_CHECKED_OUT]: 'Checked Out',
  [ORDER_ITEM_STATUS_CANCELLED]: 'Cancelled',
  [ORDER_ITEM_STATUS_RETURNED]: 'Returned',
  [ORDER_ITEM_STATUS_READY_TO_SHIP]: 'Ready To Ship',
  [ORDER_ITEM_STATUS_DELIVERED]: 'Delivered',
  [ORDER_ITEM_STATUS_IN_TRANSIT]: 'In Transit',
  [ORDER_ITEM_STATUS_DELIVERY_FAILED]: 'Delivery Failed',
  [ORDER_ITEM_STATUS_PARTIAL]: 'Partial Delivered',
}
export const ORDER_ITEM_STATUS_COLOR_MAPPING = {
  [ORDER_ITEM_STATUS_CREATED]: 'blue',
  [ORDER_ITEM_STATUS_PICKLISTED]: 'warning',
  [ORDER_ITEM_STATUS_PICKING]: 'warning',
  [ORDER_ITEM_STATUS_CHECKING]: 'warning',
  [ORDER_ITEM_STATUS_CHECKED_OUT]: 'green',
  [ORDER_ITEM_STATUS_CANCELLED]: 'red',
  [ORDER_ITEM_STATUS_RETURNED]: 'red',
  [ORDER_ITEM_STATUS_READY_TO_SHIP]: 'warning',
  [ORDER_ITEM_STATUS_DELIVERED]: 'green',
  [ORDER_ITEM_STATUS_IN_TRANSIT]: 'warning',
  [ORDER_ITEM_STATUS_DELIVERY_FAILED]: 'red',
}

export const ENTITY_TYPE_ORDER = 'order'

export const PAYMENT_METHOD_COD = 'cashondelivery'
export const PAYMENT_METHOD_CREDIT = 'credit'

export const ORDER_ITEM_STATUS_FAILED_OR_CANCELLED = [
  ORDER_ITEM_STATUS_CANCELLED,
  ORDER_ITEM_STATUS_DELIVERY_FAILED,
]

export const STATUSES_ORDER_PROCESSING = [
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_PENDING,
]

export const PAYMENT_METHOD_MAPPING = {
  [PAYMENT_METHOD_COD]: 'COD',
  [PAYMENT_METHOD_CREDIT]: 'Credit',
}

export const ORDER_STATUS_SELECT = {
  [ORDER_STATUS_CREATED]: 'CREATED',
  [ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [ORDER_STATUS_CANCELLED]: 'CANCELLED',
  [ORDER_STATUS_PENDING]: 'PENDING',
}

export const ORDER_STATUS_COLOR_MAPPING = {
  [ORDER_STATUS_CREATED]: 'blue',
  [ORDER_STATUS_PROCESSING]: 'warning',
  [ORDER_STATUS_COMPLETED]: 'green',
  [ORDER_STATUS_CANCELLED]: 'red',
  [ORDER_STATUS_PENDING]: 'warning',
}

export const ORDER_STATUS = [
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_PENDING,
]

export const ORDER_STATUS_INDIA = {
  [ORDER_STATUS_CREATED]: 'CREATED',
  [ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [ORDER_STATUS_CANCELLED]: 'CANCELLED',
  [ORDER_STATUS_PENDING]: 'PENDING',
}

export const REASON_CANCEL_ORDER_INDIA = [
  'Duplicate Order',
  'Outlet too far',
  'Sales Agent asked to cancel',
  'Insufficient Inventory',
  'Others',
]
