export const STOCK_TRANSFER_VI = {
  stockTransfer: 'Luân chuyển tồn kho',
  createStockTransfer: 'Tạo yêu cầu luân chuyển tồn kho',
  sourceWarehouse: 'Kho xuất hàng',
  destinationWarehouse: 'Kho nhập hàng',
  sourceBatchNumber: 'Số lô kho xuất hàng',
  sourceSalableQuantity: 'Tồn có thể bán kho xuất hàng',
  destinationBatchNumber: 'Số lô kho nhập hàng',
  destinationSalableQuantity: 'Tồn có thể bán kho nhập hàng',
  number: 'Mã luân chuyển tồn kho',
  awaiting_approve: 'ĐANG CHỜ DUYỆT',
  approved: 'ĐÃ DUYỆT',
  rejected: 'TỪ CHỐI',
  requireSourceWarehouse: 'Vui lòng chọn kho xuất hàng',
  requireDestinationWarehouse: 'Vui lòng chọn kho nhập hàng',
  distributorNotMatch: 'Kho nguồn và kho đích phải cùng Nhà Phân Phối',
  errorNotMatchBatch:
    'Số lô kho nhập hàng phải thuộc danh sách số lô kho xuất hàng',
  adminApprove: 'Quản trị viên duyệt',
  stockTransferItems: 'Danh sách sản phẩm luân chuyển',
  messageErrorStock: 'SKUs {errSKU}: không còn tồn kho để luân chuyển',
}
