export const PRODUCT_TRADING_BY_LISTING_VI = {
  productTradingByListing: 'Sản phẩm niêm yết trên giao dịch',
  create: 'Tạo mới',
  refresh: 'Làm mới',
  sku: 'SKU',
  productName: 'Tên sản phẩm',
  warehouse: 'Kho hàng',
  price: 'Giá bán NPP đề nghị',
  vatPrice: 'Giá bán (VAT) - Giá bán NPP đề nghị',
  status: 'Trạng thái',
  purchasePrice: 'Giá mua vào thực tế',
  purchasePriceVAT: 'Giá mua vào (VAT)',
  commission: 'Hoa hồng',
  saleAgentCommission: 'Hoa hồng nhân viên bán hàng',
  saCommissionUnit: 'Đơn vị tính hoa hồng',
  currentStock: 'Tồn hiện tại(Thùng/Lẻ)',
  action: 'Thao tác',
  titleUpdate: 'Cập nhật sản phẩm niêm yết trên giao dịch',
  titleCreate: 'Tạo sản phẩm niêm yết trên giao dịch',
  cancel: 'Hủy',
  update: 'Cập nhật',
  updateStock: 'Cập nhật tồn',
  searchWarehouse: 'Chọn hoặc nhập tên để tìm kiếm',
  all: 'TẤT CẢ',
  active: 'ĐANG HOẠT ĐỘNG',
  active_soon: 'SẮP HOẠT ĐỘNG',
  awaiting_finance_approve: 'CHỜ DUYỆT KẾ TOÁN',
  terminated: 'KẾT THÚC',
  draft: 'NHÁP',
  need_adjust: 'CẦN ĐIỀU CHỈNH',
  admin_rejected: 'CẦN ĐIỀU CHỈNH',
  finance_rejected: 'CẦN ĐIỀU CHỈNH',
  creationTime: 'Ngày tạo',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  productTradingByListingCreated: 'Tạo thành công!',
  productTradingByListingUpdated: 'Cập nhật thành công!',
  messageErrorAlreadyExist:
    'Yêu cầu thất bại ! Bạn đang có yêu cầu chưa được duyệt, <link>nhấn vào đây để xem chi tiết</link>',
  messageErrorAlreadyExistsActiveSoon:
    'Yêu cầu thất bại! Bạn đang có yêu cầu sắp hoạt động,  <link>nhấn vào đây để xem chi tiết</link>',
  messageErrorAlreadyExistGroups:
    'Yêu cầu thất bại ! Bạn đang có yêu cầu chưa được duyệt,  SKUs: {sku}',
  messageErrorAlreadyExistsActiveSoonGroups:
    'Yêu cầu thất bại! Bạn đang có yêu cầu sắp hoạt động, SKUs: {sku}',
  messageSelectSku: 'Nhấn vào để thêm <link>một hoặc nhiều SKU</link>',
  profit: 'Lợi nhuận Nhà Phân Phối',
  distributorServiceFee: 'Chênh lệch giá bán/mua',
  vigoFee: 'Chi phí Vigo',
  CM1: 'CM1 (%)',
  requireSKU: 'Vui lòng chọn SKU',
  requireWarehouse: 'Vui lòng chọn kho hàng',
  requirePrice: 'Vui lòng nhập giá',
  requirePurchasePrice: 'Vui lòng nhập giá mua vào',
  requirePurchasePriceVAT: 'Vui lòng nhập giá mua vào (VAT)',
  requireCommission: 'Vui lòng nhập hoa hồng',
  requireSaleAgentCommission: 'Vui lòng nhập hoa hồng cho nhân viên bán hàng',
  requireSaleAgentCommissionUnit: 'Vui lòng nhập đơn vị hoa hồng',
  requireSellingQuantityExpect: 'Vui lòng nhập số lượng bán tối đa',
  statusHistory: 'Lịch sử thay đổi trạng thái',
  updateTradingFail: 'Cập nhật thất bại!',
  createTradingFail: 'Tạo thất bại!',
  search: 'Tìm kiếm',
  reset: 'Cài lại',
  distributorCode: 'Mã nhà phân phối',
  distributor: 'Nhà phân phối',
  messageErrorGetTradingFailed: 'Tải thông tin thất bại!',
  exportTrading: 'Tải dữ liệu',
  approve: 'Duyệt',
  needAdjust: 'Cần điều chỉnh',
  approveSuccess: 'Duyệt thành công',
  approveFailed: 'Duyệt thất bại',
  reasonNeedAdjust: 'Lý do cần điều chỉnh',
  reason: 'Lý do',
  suggest: 'Gợi ý điều chỉnh',
  updatedDate: 'Ngày cập nhật',
  updatedBy: 'Người cập nhật',
  requireReason: 'Vui lòng chọn lý do!',
  tradingGroupNumber: 'Mã nhóm sản phẩm',
  requireSuggest: 'Vui lòng nhập gợi ý cần điều chỉnh!',
  requestAdjustSuccess: 'Yêu cầu điều chỉnh thành công!',
  requestAdjustFailed: 'Yêu cầu điều chỉnh thất bại!',
  viewReason: 'Xem lý do',
  no: 'Thứ tự',
  terminate: 'Kết thúc',
  terminateSuccess: 'Kết thúc thành công!',
  terminateFailed: 'Kết thúc thất bại!',
  user: 'Người thao tác',
  confirm: 'Xác nhận',
  apolloDistributionFee: 'Chi phí Apollo Distribution',
  invoiceInfo: 'Thông tin hóa đơn',
  invoiceDate: 'Ngày xuất hóa đơn',
  invoiceNumber: 'Mã hóa đơn',
  note: 'Ghi chú',
  paymentTerm: 'Chính sách thanh toán',
  file: 'Tập tin',
  upload: 'Tải lên',
  requiredUploadFile: 'Vui lòng tải lên tập tin!',
  requiredInvoiceNumber: 'Vui lòng nhập mã hóa đơn!',
  requiredInvoiceDate: 'Vui lòng nhập ngày xuất hóa đơn!',
  vigoCommission: 'Hoa hồng Vigo',
  apolloCommission: 'Hoa hồng Apollo Distribution',
  generalInformation: 'Thông tin chung',
  productSku: 'Sản phẩm - SKU',
  sellingQuantityExpect: 'Số lượng bán tối đa',
  selectSku: 'Chọn sản phẩm',
  addItem: 'Thêm mới',
  packageSize: 'Quy cách đóng gói',
  packageType: 'Đơn vị đóng gói',
  messageMassive:
    'Nhập thông tin hàng ngang để điền hàng loạt cho các dòng dưới',
  messErrorSku: 'Vui lòng nhập đầy đủ thông tin',
  messErrorEmptySku: 'Vui lòng chọn ít nhất 1 sản phẩm!',
  copyData: 'Sao chép thông tin',
  messageCopySuccessful: 'Sao chép thông tin thành công',
  messageCopyError: 'Sao chép thông tin thất bại!',
  messageErrorPurchasePrice:
    'Giá mua vào phải nhỏ hơn hoặc bằng Giá mua vào(VAT)',
  messageErrorPurchasePriceVAT:
    'Giá mua vào(VAT) phải lớn hơn hoặc bằng Giá mua vào',
  actionActive: 'Hoạt động',
  messageProcessAllOrders:
    'Vui lòng xử lý tất cả các đơn hàng còn tồn động để thông tin mới được hoạt động, <link>bấm vào đây để xem</link>',
  totalUpdatedStock: 'Tổng tồn đã cập nhật (Thùng/lẻ)',
  messageValidateStockUpdate:
    'Số lượng tồn kho cập nhật đã vượt quá số lượng muốn bán được Kế toán phê duyệt, vui lòng tạo mới yêu cầu',
  duplicateInvoice: 'Có {total} hóa đơn bị trùng',
  duplicateInvoiceTitleModal: 'Hóa đơn hệ thông bị trùng',
  buyFromBrand: 'Mua từ nhãn',
  buy_from_brand_yes: 'Có',
  buy_from_brand_no: 'Không',
  messageErrorCommission:
    'Sản phẩm chưa thỏa điều kiện vận hành, vui lòng liên hệ Admin để hỗ trợ!',
  refreshCommission: 'Lấy giá trị mới nhất',
  suggestedPriceVAT: 'Giá bán (VAT) - Vigo khuyến nghị',
  finalPriceVAT: 'Giá bán(VAT) cuối cùng trên App',
  finalSaleAgentCommission: 'Hoa hồng nhân viên bán hàng cuối cùng trên App',
  bulkCreate: 'Tạo Hàng Loạt',
  bulkCreateProductTradingByListing:
    'Tạo hàng loạt sản phẩm niêm yết trên giao dịch',
  requestApprove: 'Yêu cầu duyệt',
  referralPrice: 'Giá bán Qui Trình 2- Retail',
  messageErrorReferralPrice: 'Vui lòng chuyển sang Qui Trình 2 - Retail',
  messageErrorSaCommission:
    'Hoa hồng SA phải từ 0.5% Giá bán(VAT) cuối cùng trên App trở lên',
}
