import {
  PRE_ORDER_STATUS_CANCELLED,
  PRE_ORDER_STATUS_COMPLETED,
  PRE_ORDER_STATUS_CREATED,
  PRE_ORDER_STATUS_PENDING,
  PRE_ORDER_STATUS_PROCESSING,
} from 'utils/preOrders'

const PRE_ORDERS_STATUS_EN = {
  [PRE_ORDER_STATUS_CREATED]: 'CREATED',
  [PRE_ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [PRE_ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [PRE_ORDER_STATUS_CANCELLED]: 'CANCELLED',
  [PRE_ORDER_STATUS_PENDING]: 'PENDING',
}

export const PRE_ORDERS_EN = {
  allStatus: 'All Status',
  'pre-order': 'Pre-order',
  fulfillmentOrderButton: 'Fulfillment Order',
  confirmToFulfillment: 'Confirm To Fulfillment',
  messageProductNotEnough:
    'Currently {quantity} products are not enough for delivery:',
  messageRemoveItemFulfillment:
    'Insufficient items will be partial fulfillment when clicking on Fulfillment Order. Would you like to add more of insufficiently stocked products?',
  buttonNoConfirmFulfillment: 'No, continue fulfillment',
  buttonYesConfirmFulfillment: 'Yes, please wait for me to inbound',
  currentStock: 'current stock',
  warningPreOrders: 'Warning To Fulfillment',
  all: 'ALL',
  ...PRE_ORDERS_STATUS_EN,
}
