import { ERROR_DESCRIPTION, ERROR_TYPE, IS_SUCCESSFUL } from '..'

export const LOGIN_METHOD = 'login_method'

export const MAPPING_LOGIN_SUCCESS = {
  [LOGIN_METHOD]: 'login_method',
  [IS_SUCCESSFUL]: 'is_successful',
}

export const MAPPING_LOGIN_FAILED = {
  [LOGIN_METHOD]: 'login_method',
  [IS_SUCCESSFUL]: 'is_successful',
  [ERROR_TYPE]: 'error_type',
  [ERROR_DESCRIPTION]: 'error_description',
}
