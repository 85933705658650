import {
  INVOICE_BUY_IN_STATUS_CREATED,
  INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED,
  INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT,
  INVOICE_BUY_IN_STATUS_VIGO_APPROVED,
  INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED,
} from 'utils/invoiceUtils'

const BUY_IN_INVOICE_STATUS = {
  [INVOICE_BUY_IN_STATUS_CREATED]: 'Mới',
  [INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED]: 'Vigo xác nhận',
  [INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT]: 'Cần điều chỉnh',
  [INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED]: 'Nhà phân phối xác nhận',
  [INVOICE_BUY_IN_STATUS_VIGO_APPROVED]: 'Vigo duyệt',
}

export const BUY_IN_INVOICE_VI = {
  buyInNumber: 'Mã mua vào',
  buyInDate: 'Ngày mua vào',
  totalPurchaseQty: 'Tổng số lượng mua vào',
  totalPurchaseAmount: 'Tổng giá trị mua vào',
  totalSuccessFeeAmount: 'Tổng phí bán hàng thành công',
  invoiceDate: 'Ngày xuất hóa đơn',
  buyInInvoiceManagement: 'Quản lý hóa đơn mua vào',
  exportBuyInInvoice: 'Xuất dữ liệu mua vào',
  rejectComment:
    'đã từ chối thông tin Hóa đơn cho Giao dịch mua này. Lý giải cho vấn đề này là:',
  actionMsg: {
    vigo_confirmed:
      'Bạn có chắc chắn xác nhận rằng thông tin trên hóa đơn mua vào này là chính xác không?',
    vigo_approved:
      'Bạn có chắc chắn xác nhận rằng chi tiết hóa đơn trên hóa đơn mua hàng này là chính xác không?',
  },
  rejectReason: 'Lý do từ chối',
  helpRejectMsg:
    'Vui lòng nhập lý do từ chối thông tin hóa đơn này từ Nhà phân phối.',
  submitInvoice: 'Tải hóa đơn lên hệ thống',
  reject: 'Từ chối',
  approve: 'Xác nhận',
  instruction: 'Hướng dẫn',
  instructionMsg:
    'Vui lòng điền đầy đủ các thông tin được yêu cầu và đính kèm tệp PDF của hóa đơn.',
  currentState: 'Trạng thái hiện tại',
  deliveredDate: 'Ngày giao hàng thành công',
  vat: 'Phần trăm VAT',
  costUnitPrice: 'Giá đơn vị mua vào',
  purchaseQuantity: 'Số lượng mua vào',
  purchaseAmount: 'Giá trị mua vào',
  sellOutItems: 'Danh sách sản phẩm bán ra',
  sellOutNumber: 'Mã bán ra',
  postedDate: 'Ngày công bố',
  sellOutDate: 'Ngày bán ra',
  eInvoice: 'Hóa đơn điện tử',
  activity: 'Hoạt động',
  comments: 'Phản hồi',
  history: 'Lịch sử',
  buyInItems: 'Danh sách sản phẩm mua vào',
  editBuyInInvoice: 'Chỉnh sửa hóa đơn mua vào',
  costUnitPriceValid: 'Giá đơn vị mua vào phải > 0',
  orderCreationTime: 'Ngày tạo đơn hàng',
  unitPrice: 'Giá đơn vị bán ra',
  salesQuantity: 'Số lượng bán ra',
  successFeeAmount: 'Tổng phí bán hàng thành công',
  costAmount: 'Tổng chi phí mua vào',
  salesAmount: 'Tổng chi phí bán ra',
  addComment: 'Thêm phản hồi...',
  changed: 'Đã thay đổi',
  status: 'Trạng thái',
  invoiceNumber: 'Mã hóa đơn',
  ...BUY_IN_INVOICE_STATUS,
}
