export const RIDER_EN = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  createNewRider: 'Create New Rider',
  name: 'Name',
  phone: 'Phone',
  active: 'Active',
  totalPendingPackage: 'Total Pending Package',
  editRider: 'Edit Rider',
  deleteRider: 'Delete Rider',
  rider: 'Rider',
  updateRider: 'Update Rider',
}
