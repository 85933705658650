export const PRODUCT_LISTINGS_EN = {
  productListingManagement: 'Product Listing Management',
  createProductListing: 'Create Product Listing',
  refresh: 'Refresh',
  sku: 'SKU',
  productName: 'Product Name',
  warehouseCode: 'Warehouse Code',
  searchWarehouse: 'Select or enter name to search',
  status: 'Status',
  selectStatus: 'Select Status',
  requested: 'REQUESTED',
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
  terminated: 'TERMINATED',
  currentStockUpdateRequest: 'Current Stock Update Request',
  warehouse: ' Warehouse',
  startDate: 'Start Date',
  endDate: 'End Date',
  price: 'Price',
  stockUpdateRequest: 'Stock Update Request',
  currentSellingType: 'Current Selling Type',
  commission: 'Commission',
  saleAgentCommission: 'Sale Agent Commission',
  saCommissionUnit: ' SA Commission Unit',
  currentMagentoStock: 'Current Magento Stock',
  actions: 'Actions',
  updateProductListing: 'Update Product Listing',
  update: 'Update',
  cancel: 'Cancel',
  updateProductListingFail: 'Update Product Listings Failed!',
  createProductListingFail: 'Create Product Listings Failed!',
  'startDate must be in future': 'startDate must be in future',
  requestedDate: 'Requested Date',
  requestedBy: 'Requested By',
  updateStockRequest: 'Update Stock Request',
  updateStockHistory: 'Update Stock History',
  no: 'NO.',
  approveDate: 'Approval Date',
  requestBy: 'Request By',
  approvalBy: 'Approval By',
  newStock: 'New Stock',
  state: 'State',
  updateStock: 'Create new update stock request',
  currentStocks: 'Current Stocks',
  requestDate: 'Request Date',
  approvalDate: 'Approval Date',
  reject: 'Reject',
  approve: 'Approve',
  newStocks: 'New Stocks',
  create: 'Create',
  search: 'Search',
  reset: 'Reset',
  pending: 'PENDING',
  createdDate: 'Created Date',
  updatedDate: 'Updated Date',
  distributorCode: 'Distributor Code',
  //
  messageDateError:
    'Create product listing failed! startDate must be in future New product listing created!',
  requireSKU: 'Please input SKU',
  requireWarehouse: 'Please input warehouse',
  requireStartDate: 'Please input Start Date',
  requirePrice: ' Please input Price',
  requireCommission: 'Please input Commission',
  requireSaleAgentCommission: 'Please input Sale Agent Commission',
  requireSaleAgentCommissionUnit: 'Please input Sale Agent Commission Unit',
  productListUpdated: 'Product listing updated',
  productListCreated: 'New product listing created!',
  inventoryUpdateRequest: 'There is already an inventory updated request',
  newUpdateStocksCreated: 'New Update Stocks request created!',
  productListingDetail: 'Product Listing Detail',
  productListingID: 'Product Listing ID',
  approved: 'APPROVED',
  created: 'CREATED',
  selectDate: 'Select Date',
  newStockRequired: 'Please input New Stocks!',
  approvedMessage: 'Approved!',
  rejectedMessage: 'Rejected!',
  updateStatusSuccess: 'Update Status Success!',
  alertDropMenuProductListing:
    'The Listing process will stop working from January 22, 2024. Distributors please choose another process to the following instruction: <link>Instruction Documents</link>',
  listingGroupNumber: 'Listing Group Number',
  all: 'ALL',
  active: 'ACTIVE',
  active_soon: 'ACTIVE SOON',
  awaiting_approve: 'WAITING APPROVE',
  need_adjust: 'NEED ADJUST',
  draft: 'DRAFT',
  distributorSellingPrice: 'Distributor Selling Price',
  productSku: 'Product - SKU',
  titleCreate: 'Create Product Listing',
  warningTerminate:
    'The system will automatically reduce the stock for this product and stop selling it on the app.',
  productListing: 'Product Listing',
  messageErrorAlreadyExistsActive:
    'Create failed! You have an active product listing, <link>click here for details</link>',
  currentStock: 'Current Stock',
  totalUpdatedStock: 'Total Stock Updated',
}
