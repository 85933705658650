import { Modal } from 'antd'
import React from 'react'

interface IProps {
  visible: boolean
  onCancel: () => void
  translate: (e: string) => string
}

const TermAndConditionModal: React.FC<IProps> = ({
  visible,
  onCancel,
  translate,
}) => {
  const fileUrl =
    'https://vigo-oms-public.s3.ap-southeast-1.amazonaws.com/admin-ui/4.+Vigo_+Service+contract+template_VN_240607.pdf'

  return (
    <Modal
      title={translate(`signUp.titleTermAndCondition`)}
      open={visible}
      footer={null}
      width="60vw"
      maskClosable={false}
      destroyOnClose
      onCancel={() => onCancel()}
    >
      <div style={{ width: '100%', height: '70vh' }}>
        <embed
          src={fileUrl}
          type="application/pdf"
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </Modal>
  )
}

export default React.memo(TermAndConditionModal)
