export const PRODUCT_TRADING_BY_LISTING_EN = {
  productTradingByListing: 'Product Trading By Listing',
  create: 'Create',
  refresh: 'Refresh',
  sku: 'SKU',
  productName: 'Product Name',
  warehouse: 'Warehouse',
  price: 'Price',
  vatPrice: 'Price (VAT)',
  status: 'Status',
  purchasePrice: 'Purchase Price',
  purchasePriceVAT: 'Purchase Price(VAT)',
  commission: 'Commission',
  saleAgentCommission: 'Sale Agent Commission',
  saCommissionUnit: ' SA Commission Unit',
  currentStock: 'Current Stock(Case/Piece)',
  action: 'Actions',
  titleUpdate: 'Update Product Trading By Listing',
  titleCreate: 'Create Product Trading By Listing',
  statusHistory: 'Status History',
  update: 'Update',
  updateStock: 'Update Stock',
  searchWarehouse: 'Select or enter name to search',
  all: 'ALL',
  active: 'ACTIVE',
  active_soon: 'ACTIVE SOON',
  awaiting_finance_approve: 'WAITING FINANCE APPROVE',
  terminated: 'TERMINATED',
  admin_rejected: 'NEED ADJUST',
  finance_rejected: 'NEED ADJUST',
  need_adjust: 'NEED ADJUST',
  creationTime: 'Created Date',
  startDate: 'Start Date',
  endDate: 'End Date',
  productTradingByListingCreated: 'Create successful!',
  productTradingByListingUpdated: 'Update successful!',
  messageErrorAlreadyExist:
    'Request failed!You have an unapproved request, <link>click here for details</link>',
  messageErrorAlreadyExistsActiveSoon:
    'Request failed! You have an active soon request, <link>click here for details</link>',
  messageErrorAlreadyExistGroups:
    'Request failed!You have an unapproved request,  SKUs: {sku}',
  messageErrorAlreadyExistsActiveSoonGroups:
    'Request failed! You have an active soon request, SKUs: {sku}',
  messageSelectSku:
    'Click to add <link>one or more SKUs</link> to the invoice.',
  profit: 'Distributor Profit',
  distributorServiceFee: 'Distributor Margin',
  vigoFee: 'Vigo Fee',
  CM1: 'CM1 (%)',
  requireSKU: 'Please input SKU',
  requireWarehouse: 'Please input warehouse',
  requirePrice: ' Please input Price',
  requirePurchasePrice: ' Please input Purchase Price',
  requirePurchasePriceVAT: ' Please input Purchase Price (VAT)',
  requireCommission: 'Please input Commission',
  requireSaleAgentCommission: 'Please input Sale Agent Commission',
  requireSaleAgentCommissionUnit: 'Please input Sale Agent Commission Unit',
  cancel: 'Cancel',
  updateTradingFail: 'Update Product Trading By Listing Failed!',
  createTradingFail: 'Create Product Trading By Listing Failed!',
  'startDate must be in future': 'startDate must be in future',
  search: 'Search',
  reset: 'Reset',
  distributorCode: 'Distributor Code',
  distributor: 'Distributor',
  messageErrorGetTradingFailed: 'Get Product Trading By Listing failed!',
  exportTrading: 'Export data',
  approve: 'Approve',
  needAdjust: 'Need adjust',
  approveSuccess: 'Approve successful',
  approveFailed: 'Approve failed!',
  reasonNeedAdjust: 'Reason Need Adjust',
  reason: 'Reason',
  suggest: 'Suggest',
  updatedDate: 'Updated Date',
  updatedBy: 'Updated By',
  requireReason: 'Please select reason!',
  requireSuggest: 'Please input suggest!',
  tradingGroupNumber: 'Trading Group Number',
  requestAdjustSuccess: 'Request adjust successful',
  requestAdjustFailed: 'Request adjust failed',
  viewReason: 'View Reason',
  no: 'NO.',
  terminate: 'Terminate',
  terminateSuccess: 'Terminate successful!',
  terminateFailed: 'Terminate failed!',
  user: 'User',
  confirm: 'Confirm',
  apolloDistributionFee: 'Apollo Distribution Fee',
  invoiceInfo: 'Invoice Information',
  invoiceDate: 'Invoice Date',
  invoiceNumber: 'Invoice Number',
  note: 'Note',
  paymentTerm: 'Payment Term',
  file: 'File',
  upload: 'Upload',
  requiredUploadFile: 'Please upload file!',
  requiredInvoiceNumber: 'Please input invoice number!',
  requiredInvoiceDate: 'Please input invoice date!',
  vigoCommission: 'Vigo Commission',
  apolloCommission: 'Apollo Commission',
  generalInformation: 'General Information',
  productSku: 'Product - SKU',
  sellingQuantityExpect: 'Selling Quantity Expect',
  selectSku: 'Select SKUs',
  addItem: 'Add Item',
  packageSize: 'Package Size',
  packageType: 'Package Type',
  messageMassive: 'Input this row to apply Massive Update',
  requireSellingQuantityExpect: 'Please input selling quantity expect',
  messErrorSku: 'Please input complete information',
  messErrorEmptySku: 'Please select at least 1 SKU!',
  copyData: 'Copy Data',
  messageCopySuccessful: 'Copy data successful',
  messageCopyError: 'Copy data failed!',
  messageErrorPurchasePrice:
    'Purchase Price must be less than or equal Purchase Price(VAT)',
  messageErrorPurchasePriceVAT:
    'Purchase Price(VAT) must be greater than or equal Purchase Price',
  actionActive: 'Active',
  messageProcessAllOrders:
    'Please process all order not yet process to active new information, <link>click here to view</link>',
  totalUpdatedStock: 'Total Stock Updated (Case/Piece)',
  messageValidateStockUpdate:
    'Total stock updated over Selling Quantity Expect approved by Finance, please create new one request',
  duplicateInvoice: 'Have {total} the same invoice',
  duplicateInvoiceTitleModal: 'Duplicate System Invoice Entity',
  buyFromBrand: 'Buy from brand',
  buy_from_brand_yes: 'Yes',
  buy_from_brand_no: 'No',
  messageErrorCommission:
    'Product does not meet conditions to create request. Please contact Admin for support!',
  refreshCommission: 'Refresh',
  suggestedPriceVAT: 'Suggested Price(VAT)',
  finalPriceVAT: 'Final Price(VAT)',
  finalSaleAgentCommission: 'Final Sale Agent Commission',
  bulkCreate: 'Bulk Create',
  bulkCreateProductTradingByListing: 'Bulk Create Product Trading By Listing',
  draft: 'DRAFT',
  requestApprove: 'Request Approve',
  referralPrice: 'Retail Price',
  messageErrorReferralPrice: 'Please switch to process 2 - Retail',
  messageErrorSaCommission:
    'SA Commission must be from 0.5% of the final price (VAT) on the App or more',
}
