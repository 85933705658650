export const REASON_NEED_ADJUST_VI = {
  reasonName: 'Tên lý do',
  reasonNeedAdjust: 'Lý do cần điều chỉnh',
  addNew: 'Thêm mới',
  createReasonNeedAdjust: 'Tạo mới lý do cần điều chỉnh',
  search: 'Vui lòng nhập tên để tìm kiếm',
  messageRequiredReasonName: 'Vui lòng nhập tên lý do',
  createReasonSuccessful: 'Tạo lý do thành công',
  createReasonFail: 'Tạo lý do thất bại!',
  updateReasonSuccessful: 'Cập nhật lý do thành công',
  updateReasonFail: 'Cập nhật lý do thất bại!',
}
