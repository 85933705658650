import { TYPE_OF_BUSINESS_SELECT } from 'utils/signUp'

export const SIGN_UP_EN = {
  distributorInfo: 'Distributor Information',
  businessInfo: 'Information about Business Activities',
  infrastructureInfo: 'Information about Infrastructure',
  documents: 'Documents',
  registerToSell: 'Register to sell on Vigo',
  businessName: 'Business name',
  typeOfBusiness: 'Type of business',
  placeholderAddress: 'Please select Province/City, District, Ward/Commune',
  placeholderAddressDetail: 'Please input Street name/House number/House name',
  mandatory: 'Mandatory information',
  distributionArea: 'Distribution Area',
  businessModel: 'Business model',
  placeholderOneOrMore: 'Please select one or more options',
  numberOfYears: 'Number of years as distributor',
  monthlySales: 'Monthly sales',
  numberRequired: 'number',
  creditAllow: 'Credit allow',
  averageInventoryValue: 'Average inventory value',
  numberWarehouse: 'Number warehouse',
  totalWarehouseArea: 'Total warehouse area',
  numberOfSales: 'Number of sales',
  numberWarehouseStaff: 'Number warehouse staff',
  numberAccountant: 'Number of accountant',
  numberRider: 'Number of rider',
  numberTruck: 'Number of trucks',
  numberMotorbike: 'Number of motorbike',
  confirmSubmit: 'Confirm submit',
  back: 'Back',
  category: 'Category',
  thankYouSubmit: 'Thank you for submitting this form',
  contact: 'Vigo will contact you within the next 24 hours',
  backToLogin: 'Back to Login',
  addressInRegion: 'Address in Region',
  rejectBusinessName: 'Reject Business Name:',
  rejectReason: 'Reasons to reject distributor:',
  ex: 'Ex: need to edit information',
  send: 'Send',
  'insufficient-information': 'Insufficient information',
  'invalid-information': 'Invalid information',
  'unable-contact': 'Unable to contact',
  others: 'Others',
  waringSaveChanges: 'Do you want to save the latest changes?',
  notSave: `No, I don't save`,
  yesPlease: 'Yes, please',
  termAndCondition: 'I agree to the <link>Terms and Conditions</link>',
  titleTermAndCondition: 'Terms and Conditions',
  type: 'Type',
  enterprise: 'Enterprise',
  ERC: 'Enterprise',
  household: 'Household',
  pleaseProvide: 'Please provide {name} registration certificate {type}',
  ...TYPE_OF_BUSINESS_SELECT,
}
