import {
  PICKLIST_STATUS_CANCELLED,
  PICKLIST_STATUS_CHECKING,
  PICKLIST_STATUS_COMPLETED,
  PICKLIST_STATUS_CREATED,
  PICKLIST_STATUS_PICKING,
} from 'utils/picklist'

const PICKLIST_STATUS = {
  [PICKLIST_STATUS_CREATED]: 'Mới',
  [PICKLIST_STATUS_CHECKING]: 'Đang kiểm tra',
  [PICKLIST_STATUS_PICKING]: 'Đang lấy hàng',
  [PICKLIST_STATUS_COMPLETED]: 'Hoàn thành',
  [PICKLIST_STATUS_CANCELLED]: 'Đã hủy',
}

const PICKLIST_ITEM_STATUS = {
  created: 'Mới',
  checking: 'Đang kiểm tra',
  picking: ' Đang lấy hàng',
  approved: 'Đã duyệt',
  rejected: 'Từ chối',
}

export const PICKLIST_VI = {
  printErrorMessage: 'Vui lòng chọn tối đa 100 dòng!',
  createErrorMessage:
    'Không thể tạo mới giao hàng nếu danh sách chọn không ở trạng thái hoàn thành!',
  createNewDelivery: 'Tạo mới giao hàng',
  picklistNumber: 'Mã vận đơn',
  agentName: 'Chuyên viên kinh doanh',
  agentCode: 'Mã chuyên viên kinh doanh',
  agentPhone: 'SĐT chuyên viên kinh doanh',
  customerName: 'Khách hàng',
  receiverAddress: 'Địa chỉ người nhận',
  receiverPhone: 'Số điện thoại người nhận',
  totalSku: 'Tổng số lượng mặt hàng',
  totalItems: 'Tổng số lượng sản phẩm',
  totalCheckoutItems: 'Tổng số lượng sản phẩm được thanh toán',
  totalCheckoutSku: 'Tổng số lượng mặt hàng được thanh toán',
  picklistDetail: 'Chi tiết vận đơn',
  promiseMinTime: 'Thời gian giao sớm nhất',
  promiseMaxTime: 'Thời gian giao trể nhất',
  picklistItems: 'Danh sách mặt hàng',
  printPicklist: 'In phiếu lấy hàng',
  printReceipt: 'In phiếu xuất kho',
  printDraft: 'In hóa đơn nháp',
  ...PICKLIST_STATUS,
  ...PICKLIST_ITEM_STATUS,
}
