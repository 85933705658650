export const HOME_EN = {
  homePage: 'To do list',
  labelRecommendedPurchaseOrder: 'Recommend purchase request need confirmation',
  labelCreatedVigoQuotation: 'Quotation need confirmation',
  labelAwaitingPaymentDPO: 'Purchase order need payment',
  labelCreatedOrder: 'Sales order need to be processed',
  distributor: 'Distributor',
  placeholderDistributor: 'Select Distributor',
  notification: 'Notification',
  emptyNotification: `You don't have any notifications yet`,
  more: 'More',
  next: 'Next',
  back: 'Back',
  markAllRead: 'Mark all as read',
  seeMoreNotification: 'See more notification',
  all: 'All',
  unread: 'Unread',
  seen: 'Read',
  created: 'Unread',
}
