import { isIndia } from 'utils/commonUtils'
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_PROCESSING,
  PAYMENT_METHOD_COD,
  PAYMENT_METHOD_CREDIT,
} from 'utils/order'

const ORDER_STATUS = {
  [ORDER_STATUS_CREATED]: 'CREATED',
  [ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [ORDER_STATUS_CANCELLED]: 'CANCELLED',
  [ORDER_STATUS_PENDING]: 'PENDING',
}

const PAYMENT_METHOD = {
  [PAYMENT_METHOD_COD]: 'COD',
  [PAYMENT_METHOD_CREDIT]: 'Credit',
}

const ORDER_ITEM_STATUS = {
  created: 'CREATED',
  picklisted: 'PICKLISTED',
  picking: 'PICKING',
  checking: 'CHECKING',
  checked_out: 'CHECKED OUT',
  cancelled: 'CANCELLED',
  returned: 'RETURNED',
  ready_to_ship: 'READY TO SHIP',
  delivered: 'DELIVERED',
  in_transit: 'IN TRANSIT',
  delivery_failed: 'DELIVERY FAILED',
  partial_delivered: 'PARTIAL DELIVERED',
}

export const ORDERS_EN = {
  orderedQuantity: 'Ordered Quantity',
  confirmedQuantity: 'Confirmed Quantity',
  orderManagement: 'Order Management',
  sender: 'Sender',
  receiver: 'Receiver',
  tradeOrderCreatedDate: 'Trade Order Created Date',
  skuOrder: 'SKU Order',
  itemOrders: 'Item Orders',
  grantTotal: 'Grant Total',
  paymentMethod: 'Payment Method',
  cancelOrder: 'Cancel order',
  orderDetail: 'Order Detail',
  remark: 'Remark',
  tags: 'Tags',
  paidPrice: 'Paid Price',
  taxAmount: 'Tax Amount',
  unitPrice: 'Unit Price',
  promotionDiscount: 'Promotion Discount',
  distributorSubsidy: 'Distributor Subsidy',
  saleAgentSubsidy: 'Sale Agent Subsidy',
  adjustQuantity: 'Adjust Quantity',
  orderItems: 'Order Items',
  allStatus: 'All Status',
  cod: 'COD',
  fulfillmentOrderManagement: isIndia
    ? 'Order Management'
    : 'Fulfillment Order Management',
  shippingType: {
    text: 'Shipping Type',
    warehouse: 'Warehouse',
    dropshipping: 'Dropshipping',
  },
  'pre-order': 'Pre Order',
  ...ORDER_STATUS,
  ...PAYMENT_METHOD,
  ...ORDER_ITEM_STATUS,
}
