export const VEHICLE_EN = {
  plate: 'Plate',
  type: 'Type',
  createNewVehicle: 'Create new vehicle',
  vehicleName: 'Vehicle Name',
  vehiclePlate: 'Vehicle Plate',
  vehicleType: 'Vehicle Type',
  vehicleWeight: 'Vehicle Weight',
  vehicleBuyTime: 'Vehicle Buy Time',
  editVehicle: 'Edit Vehicle',
  deleteVehicle: 'Delete Vehicle',
  updateVehicle: 'Update Vehicle',
  vehiclePlaceholder: 'Search vehicle plate',
}
