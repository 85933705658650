import {
  MARK_AS_TYPE_DELIVERY_FAILED,
  MARK_AS_TYPE_FULL_DELIVERED,
  MARK_AS_TYPE_PARTIAL_DELIVERED,
} from 'containers/Rider/Packages/ConfirmDeliveryDrawer/MarkAsDropdown'
import {
  PACKAGE_STATUS_AGENT_DELIVERY_FAILED,
  PACKAGE_STATUS_AGENT_FULL_DELIVERED,
  PACKAGE_STATUS_AGENT_PARTIAL_DELIVERED,
  PACKAGE_STATUS_ASSIGNED,
  PACKAGE_STATUS_ATTEMPT_FAILED,
  PACKAGE_STATUS_CANCELLED,
  PACKAGE_STATUS_DELIVERED,
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_RETURNED,
  PACKAGE_STATUS_RIDER_DELIVERED,
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
  PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED,
  PACKAGE_STATUS_RIDER_RETURNED,
  PACKAGE_STATUS_UNASSIGNED,
} from 'utils/package'

const PACKAGE_STATUS = {
  [PACKAGE_STATUS_ASSIGNED]: 'ASSIGNED',
  [PACKAGE_STATUS_ATTEMPT_FAILED]: 'ATTEMPT FAILED',
  [PACKAGE_STATUS_CANCELLED]: 'CANCELLED',
  [PACKAGE_STATUS_DELIVERED]: 'DELIVERED',
  [PACKAGE_STATUS_DELIVERY_FAILED]: 'DELIVERY FAILED',
  [PACKAGE_STATUS_PARTIAL_DELIVERED]: 'PARTIAL DELIVERED',
  [PACKAGE_STATUS_RETURNED]: 'RETURNED',
  [PACKAGE_STATUS_RIDER_DELIVERED]: 'RIDER DELIVERED',
  [PACKAGE_STATUS_RIDER_DELIVERY_FAILED]: 'RIDER FAILED DELIVERY',
  [PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED]: 'RIDER PARTIAL DELIVERED',
  [PACKAGE_STATUS_RIDER_RETURNED]: 'RIDER RETURNED',
  [PACKAGE_STATUS_UNASSIGNED]: 'UNASSIGNED',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERED]: 'DISTRIBUTOR DELIVERED',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]: 'DISTRIBUTOR FAILED DELIVERY',
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]:
    'DISTRIBUTOR PARTIAL DELIVERED',
  [PACKAGE_STATUS_AGENT_DELIVERY_FAILED]: 'AGENT DELIVERY FAILED',
  [PACKAGE_STATUS_AGENT_FULL_DELIVERED]: 'AGENT FULL DELIVERED',
  [PACKAGE_STATUS_AGENT_PARTIAL_DELIVERED]: 'AGENT PARTIAL DELIVERED',
}

const REASON_OPTIONS = {
  dontWantAnymore: "Don't Want Anymore",
  lateDelivery: 'Late Delivery',
  highPrice: 'High Price',
  willSoonExpire: 'Will Soon Expire',
  otherReason: 'Other Reason',
}

const MARK_AS_DROPDOWN = {
  [MARK_AS_TYPE_FULL_DELIVERED]: 'Full Delivered',
  [MARK_AS_TYPE_PARTIAL_DELIVERED]: 'Partial Delivered',
  [MARK_AS_TYPE_DELIVERY_FAILED]: 'Delivery Failed',
}

export const PACKAGE_EN = {
  packageID: 'Package ID',
  orderCreatedDate: 'Order Created Date',
  outletPhone: 'Outlet Phone',
  outletAddress: 'Outlet Address',
  senderName: 'Sender Name',
  senderPhone: 'Sender Phone',
  senderAddress: 'Sender Address',
  confirmDelivery: 'Confirm Delivery',
  helpDAConfirmDelivery: 'Help DA Confirm Delivery',
  packageDetail: 'Package Detail',
  invoiceValue: 'Invoice Value',
  deliveredQuantity: 'Delivered Quantity',
  packageItems: 'Package Items',
  exportPackage: 'Export Package',
  packageCreatedDate: 'Package Creation Date',
  updatePackageMsg:
    'the Total Quantity delivered cannot be equal to 0 or the total Quantity ordered when the Delivery type is PARTIAL DELIVERY!',
  updatedByRiders: 'Updated by riders',
  selectAll: 'Select All',
  packagesSelected: 'Packages Selected',
  unknownContact: 'Unknown Contact',
  clearAll: 'Clear All',
  confirmAs: 'Confirm As',
  markAs: 'Mark As',
  trackingNumbersSeparatedByComma: 'Tracking Numbers (Separated by comma “,”)',
  searchByTrackingNumber: 'Search by tracking number',
  orderNumbersSeparatedByComma: 'Order Numbers (Separated by comma “,”)',
  searchByOrderNumbers: 'Search by order numbers',
  ...PACKAGE_STATUS,
  ...REASON_OPTIONS,
  ...MARK_AS_DROPDOWN,
}
