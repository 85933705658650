export const REPORT_RIDER_VI = {
  vehiclePlate: 'Biển số phương tiện',
  packageCreated: 'Số kiện hàng đợi vận chuyển',
  packageInDelivery: 'Số kiện hàng đang vận chuyển',
  packageDelivered: 'Số kiện hàng đã giao',
  totalAmount: 'Tổng giá trị',
  actualCollectedAmount: 'Giá trị thực nhận từ khách hàng',
  pendingAmount: 'Giá trị chờ xử lí',
  packageCreatedDate: 'Ngày tạo kiện hàng',
  grandTotal: 'Tổng cộng',
}
