const DELIVERY_STATUS = {
  created: 'CREATED',
  in_delivery: 'IN DELIVERY',
  completed: 'COMPLETED',
  cancelled: 'CANCELLED',
  rider_completed: 'RIDER COMPLETED',
  returned: 'RETURNED',
  arrived_at_consignee_area: 'ARRIVED AT CONSIGNEE AREA',
  delivered: 'DELIVERED',
  delivery_failed: 'DELIVERY FAILED',
  done: 'DONE',
}

export const DELIVERY_EN = {
  code: 'Code',
  trackingNumber: 'Tracking Number',
  riderPlaceholder: 'Select or enter the first name to search',
  totalStop: 'Total Stops',
  departureTime: 'Departure Time',
  editDelivery: 'Edit Delivery',
  updateDelivery: 'Update Delivery',
  createNewDelivery: 'Create New Delivery',
  vehicle: 'Vehicle',
  selectPackages: 'Select Packages',
  selectPackageMsg: 'Please select package',
  removePackage: 'Remove package',
  cancelDelivery: 'Cancel delivery',
  removePackageConfirm:
    'Are you sure you want to Remove this Package from this Delivery?<div>This action CANNOT BE UNDONE.</div>',
  contactName: 'Contact Name',
  contactPhone: 'Contact Phone',
  contactAddress: 'Contact Address',
  area: 'Area',
  tradeOrderCreationTime: 'Trade Order Creation Time',
  exportDelivery: 'Export Delivery',
  deliveryID: 'Delivery ID',
  overall: 'Overall',
  cancelDeliveryConfirm:
    'Are you sure to cancel this Delivery?<div> This action CANNOT BE UNDONE</div>',
  deliveryInfo: 'Delivery Information',
  destinationStopsDetail: 'Destination & Stops Detail',
  stopID: 'Stop ID',
  totalBag: 'Total Bag',
  contact: 'Contact',
  senderContact: 'Sender Contact',
  tags: 'Tags',
  redelivery: 'Redelivery',
  return: 'Return',
  actionMsg: {
    success: 'Package {trackingNumber} has been {action} successfully!',
    error: '{action} failed!',
  },
  enterTrackingNumber: 'Enter Package Tracking Number',
  orderNumber: 'Order Number',
  undefined: 'undefined',
  ...DELIVERY_STATUS,
}
