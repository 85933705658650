import { track } from '@amplitude/analytics-browser'
import { PAYMENT_REQUEST, PURCHASING, SUBSIDY_RULE } from 'common/config/routes'

import { EVENT_VIEW_PAYMENT_REQUEST_LIST } from './pages/payment-request'
import { EVENT_VIEW_PURCHASING_CHART } from './pages/purchasing'

export const LEFT_NAVIGATION = 'left_navigation'
export const FRONT_END = 'frontend'
export const BACK_END = 'backend'

export const IS_SUCCESSFUL = 'is_successful'
export const ERROR_TYPE = 'error_type'
export const ERROR_DESCRIPTION = 'error_description'
export const SOURCE_SCREEN = 'source_screen'
export const STATUS = 'status'
export const STATUSES = 'statuses'
export const WAREHOUSE_ID = 'warehouseID'
export const DISTRIBUTOR_ID = 'distributorID'
export const PRODUCT_NAME = 'productName'
export const NAME = 'name'
export const DISTRIBUTOR_NAME = 'distributorName'
export const SKU = 'sku'
export const WAREHOUSE = 'warehouse'

//Logout Login
export const EVENT_LOGOUT = 'logout'
export const EVENT_LOGIN = 'login'
export const EVENT_VIEW_LOGIN_FORM = 'view_login_form'
//Pass
export const EVENT_VIEW_CHANGE_PASS = 'view_change_password_popup'
export const EVENT_CHANGED_PASS = 'changed_password'
//Event Subsidy Rule
export const EVENT_CREATE_SUBSIDY = 'create_subsidy_rule'
export const EVENT_VIEW_LIST_SUBSIDY = 'view_subsidy_rule_list'
export const EVENT_CANCEL_SUBSIDY = 'cancel_subsidy_rule'
export const EVENT_VIEW_DETAIL_SUBSIDY = 'view_subsidy_rule_detail'
export const EVENT_VIEW_FORM_CREATE_SUBSIDY = 'view_form_create_subsidy_rule'

export const TO_DO_LIST = 'to_do_list'

//Mapping view list
export const VIEW_LIST = {
  [SOURCE_SCREEN]: 'source_screen',
}

//Mapping success
export const SUCCESSFUL = {
  [IS_SUCCESSFUL]: 'is_successful',
}

export const ROUTE_TRACKING_MAPPING = {
  [SUBSIDY_RULE]: EVENT_VIEW_LIST_SUBSIDY,
  [PURCHASING]: EVENT_VIEW_PURCHASING_CHART,
  [PAYMENT_REQUEST]: EVENT_VIEW_PAYMENT_REQUEST_LIST,
}

//Mapping error
export const ERROR = {
  [IS_SUCCESSFUL]: 'is_successful',
  [ERROR_TYPE]: 'error_type',
  [ERROR_DESCRIPTION]: 'error_description',
}

export const tracking = (
  eventName: string,
  dataProperties?: any,
  propertiesMapping?: any
) => {
  if (eventName && dataProperties && propertiesMapping) {
    const properties = Object.entries(dataProperties).map((item) => {
      return { [propertiesMapping[item[0]]]: item[1] }
    })
    const nextProperties = Object.assign({}, ...properties)
    return track(eventName, nextProperties)
  }

  if (eventName && !dataProperties && !propertiesMapping) {
    return track(eventName)
  }
}
