import { Button, Drawer, Form, Input, Space } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import GooglePlacesScript from 'components/GoogleMap'
import LocationSelect from 'components/LocationSelect'
import React, { useEffect, useMemo, useState } from 'react'
import { Translate } from 'utils/localUtils'

interface IProps {
  visible: boolean
  onClose: () => void
  translate: Translate
  isStatusApproved?: boolean
  form: FormInstance<any>
  setAddressDistributor: (value: any) => void
  setCoordinateProps?: (value: any) => void
}

const AddressDrawer: React.FC<IProps> = ({
  visible,
  onClose,
  form: formProps,
  translate,
  isStatusApproved,
  setAddressDistributor,
  setCoordinateProps,
}) => {
  const [address, setAddress] = useState(undefined)
  const [addressDetail, setAddressDetail] = useState()
  const [coordinate, setCoordinate] = useState(undefined)
  const [form] = Form.useForm()

  useEffect(() => {
    const checkCoordinate = () => {
      const address = JSON.parse(localStorage.getItem('address'))
      if (address) {
        const {
          formatted_address,
          geometry: {
            location: { lat, lng },
          },
        } = address

        setAddressDetail(formatted_address)
        form.setFieldValue('detail', formatted_address)
        setCoordinate({ lat, long: lng })
      }
    }
    window.addEventListener('storage', checkCoordinate)

    return () => {
      window.removeEventListener('storage', checkCoordinate)
      localStorage.removeItem('address')
    }
  }, [])

  const handleClose = () => {
    onClose()
    setAddress(undefined)
    setCoordinate(undefined)
    setAddressDetail(undefined)
    localStorage.removeItem('address')
    form.resetFields()
  }
  const handleOk = () => {
    const { lat, long } = coordinate
    setAddressDistributor(address)
    setCoordinateProps({ lat, long })
    const coordinateProps = `Latitude: ${lat} - Longitude: ${long}`
    const valueAddress = [
      address.provinceID,
      address.districtID,
      address.wardID,
    ]
    formProps.setFieldsValue({
      address: valueAddress,
      coordinate: coordinateProps,
      detail: addressDetail,
    })
    handleClose()
  }
  const coordinateAddress = useMemo(() => {
    if (coordinate) {
      const { lat, long } = coordinate
      return `${translate('common.latitude')}: ${lat} - ${translate(
        'common.longitude'
      )}: ${long}`
    }
    return `${translate('common.latitude')}: 0 - ${translate(
      'common.longitude'
    )}: 0`
  }, [coordinate])

  const disableButton = useMemo(() => {
    return !coordinate || !address || !addressDetail
  }, [address, coordinate, addressDetail])

  return (
    <>
      <Drawer
        title={translate('common.address')}
        destroyOnClose
        width="60vw"
        open={visible}
        onClose={() => handleClose()}
        footer={
          <Space>
            <Button onClick={() => handleClose()}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                handleOk()
              }}
              disabled={disableButton}
            >
              OK
            </Button>
          </Space>
        }
      >
        <Form form={form} name="addressForm">
          <Form.Item
            name="address"
            label={translate('common.address')}
            rules={[
              {
                required: true,
                message: translate('signUp.mandatory'),
              },
            ]}
            getValueFromEvent={(value: any, selectedOptions) => {
              if (!value?.[0] || !value?.[1] || !value?.[2]) return null
              const payload = {
                provinceID: selectedOptions[0]?.id,
                province: selectedOptions[0]?.label,
                districtID: selectedOptions[1]?.id,
                district: selectedOptions[1]?.label,
                wardID: selectedOptions[2]?.id,
                ward: selectedOptions[2]?.label,
              }
              selectedOptions?.length === 3
                ? setAddress(payload)
                : setAddress(undefined)
              return value
            }}
          >
            <LocationSelect
              placeholder={translate('signUp.placeholderAddress')}
              disabled={isStatusApproved}
            />
          </Form.Item>
          <Form.Item
            label={translate('common.addressDetail')}
            name="detail"
            valuePropName="value"
            rules={[
              {
                required: true,
                message: translate('signUp.mandatory'),
              },
            ]}
          >
            <Input
              placeholder={translate('signUp.placeholderAddressDetail')}
              className="w-full"
              disabled={isStatusApproved}
            />
          </Form.Item>
          <Form.Item className="mb-6" label={translate('common.coordinate')}>
            <Input disabled className="text-center" value={coordinateAddress} />
          </Form.Item>
        </Form>
        <GooglePlacesScript disabled />
      </Drawer>
    </>
  )
}

export default React.memo(AddressDrawer)
