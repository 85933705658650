export const SUPPLIER_TYPE_NORMAL = 'normal'
export const SUPPLIER_TYPE_MASTER_DISTRIBUTOR_HUB = 'master_distributor_hub'

// Prepayment(Thanh toán trước)

// Pay after receive(Thanh toán sau khi nhận hàng)

// Pay on duration(Thanh toán theo thời hạn)
export const PAYMENT_TYPE_PREPAYMENT = 'prepayment'
export const PAYMENT_TYPE_PAY_AFTER_RECEIVE = 'pay_after_receive'
export const PAYMENT_TYPE_PAY_ON_DURATION = 'pay_on_duration'

export const PROCESS_TYPE_RETAIL = 'retail'
export const PROCESS_TYPE_CONSIGNMENT = 'consignment'

export const PROCESS_TYPE_SELECT = {
  [PROCESS_TYPE_RETAIL]: 'Retail',
  [PROCESS_TYPE_CONSIGNMENT]: 'Consignment',
}

export const PAYMENT_TYPE_SELECT = {
  [PAYMENT_TYPE_PREPAYMENT]: 'Prepayment',
  [PAYMENT_TYPE_PAY_AFTER_RECEIVE]: 'Pay after receive',
  [PAYMENT_TYPE_PAY_ON_DURATION]: 'Pay on duration',
}

export const WORKING_DAY_MONDAY = 'monday'
export const WORKING_DAY_TUESDAY = 'tuesday'
export const WORKING_DAY_WEDNESDAY = 'wednesday'
export const WORKING_DAY_THURSDAY = 'thursday'
export const WORKING_DAY_FRIDAY = 'friday'
export const WORKING_DAY_SATURDAY = 'saturday'
export const WORKING_DAY_SUNDAY = 'sunday'

export const WORKING_DAY_SELECT = {
  [WORKING_DAY_MONDAY]: 'Monday',
  [WORKING_DAY_TUESDAY]: 'Tuesday',
  [WORKING_DAY_WEDNESDAY]: 'Wednesday',
  [WORKING_DAY_THURSDAY]: 'Thursday',
  [WORKING_DAY_FRIDAY]: 'Friday',
  [WORKING_DAY_SATURDAY]: 'Saturday',
  [WORKING_DAY_SUNDAY]: 'Sunday',
}
