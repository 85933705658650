export const VIGO_MASTER_QUOTATION_VI = {
  title: 'Giá tổng của Vigo',
  vigoMasterNumber: 'Mã báo giá tổng của Vigo',
  all: 'TẤT CẢ',
  request_high_level_approved: 'DUYỆT CẤP ĐỘ 2',
  pending: 'CHỜ XỬ LÝ',
  awaiting_logistics: 'CHỜ VẬN CHUYỂN',
  awaiting_approval: 'CHỜ DUYỆT',
  closed: 'ĐÃ ĐÓNG',
  number: 'Mã báo giá tổng',
  selectSuppliers: 'Chọn Nhà cung cấp',
  contentSelectSuppliers: 'Chọn nhà cung cấp và số lượng đặt hàng',
  arrangeLogistics: 'Sắp xếp vận chuyển',
  contentArrangeLogistics: 'Dành cho Nhà cung cấp không hỗ trợ giao',
  approval: 'Duyệt',
  contentApproval: 'Chiết khấu và Phê duyệt',
  issueQuotation: 'Phát hành báo giá',
  contentIssueQuotation: 'Dành cho đơn mua hàng Nhà phân phối',
  editByBatch: 'Chỉnh sửa theo số lô',
  updateQuantityByBatch: 'Cập nhật số lượng theo số lô',
  viewTermAndCondition: 'Xem Điều khoản và Điều kiện',
  withShip: 'With Ship',
  withoutShip: 'Without Ship',
  competitorPrice: 'Giá cạnh tranh',
  maxQuantitySuggest: 'Số lượng gợi ý tối đa:',
  soldQuantity: 'Đã bán:',
  currentInventory: 'Tồn kho hiện tại:',
  estimateStockPerDay: 'Dự đoán tồn mỗi ngày:',
  payToSupplier: 'Thanh toán cho Nhà cung cấp',
  contentPayment: 'Thanh toán cho Nhà cung cấp trước khi nhận hàng?',
  supplierName: 'Nhà cung cấp',
  pay_after_delivery: 'Sau khi giao hàng',
  pay_before_delivery: 'Trước khi giao hàng',
  pendingRouter: 'Tuyến giao đang chờ xử lý',
  sort: 'Xếp',
  fromSupplier: 'Từ Nhà cung cấp',
  toDistributor: 'Đến Nhà phân phối',
  estimateWeight: 'Khối lượng ước tính (kg)',
  deliveryPartner: 'Đối tác giao hàng',
  deliveryGroup: 'Nhóm giao hàng',
  confirmArrange: 'Xác nhận & Tiếp tục',
  distributor_pickup: 'Nhà phân phối giao',
  newVigoDelivery: 'Tóm tắt giao hàng mới của Vigo',
  noOfRoute: 'Số tuyến đường',
  estimateCost: 'Chi phí ước tính',
  pricePerTon: 'Giá/Tấn',
  deliveryDiscount: 'Chiết khấu giao hàng',
  confirmDeadline: 'Xác nhận thời gian',
  suggestedDeliveryGroup: 'Nhóm giao hàng được đề xuất ',
  estCost: 'Chi phí ước tính',
  totalDelivery: 'Tổng giao',
  purchasedPrice: 'Giá đã mua',
}
