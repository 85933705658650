import { Select } from 'antd'
import { DEFAULT_PAGE } from 'hooks/usePaging'
import { Category } from 'interfaces/product'
import stringify from 'qs/lib/stringify'
import React, { useEffect, useState } from 'react'
import axios from 'utils/axiosInstance'
import { defaultStringifyOption, getResponseData } from 'utils/commonUtils'

interface IProps {
  disabled?: boolean
  value?: string
  onChange?: (id: string) => void
  multiple?: boolean
  placeholder?: string
  keyValue?: string
}

const PlatformCategorySelect: React.FC<IProps> = ({
  disabled,
  value,
  placeholder = 'Select category',
  onChange,
  multiple,
  keyValue = 'id',
}) => {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    const getCategories = async () => {
      try {
        setLoading(true)
        const params = stringify(
          {
            page: DEFAULT_PAGE,
            perPage: 10000,
            orderBy: 'updatedAt desc',
            ignorePerPage: true,
          },
          defaultStringifyOption
        )
        const response = await axios.get(`/v1/categories?${params}`)
        setCategories(getResponseData<Category[]>(response))
      } catch (error) {
        setCategories([])
      } finally {
        setLoading(false)
      }
    }

    getCategories()
  }, [])

  return (
    <Select
      allowClear
      mode={multiple ? 'multiple' : undefined}
      value={value}
      disabled={disabled}
      loading={loading}
      showArrow={false}
      className="w-full"
      placeholder={placeholder}
      onChange={onChange}
      maxTagCount={1}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {categories.map((p) => (
        <Select.Option key={p.id} value={p[keyValue]}>
          {p?.level3 || p?.level2 || p?.level1}
        </Select.Option>
      ))}
    </Select>
  )
}

export default React.memo(PlatformCategorySelect)
