import {
  UPDATE_HISTORY_ENTITY_DELIVERY_LEAD_TIME,
  UPDATE_HISTORY_ENTITY_PACKAGE_TYPE,
  UPDATE_HISTORY_ENTITY_SA_COMMISSION,
  UPDATE_HISTORY_ENTITY_SA_COMMISSION_PACKAGE_TYPE,
  UPDATE_HISTORY_ENTITY_SA_COMMISSION_UNIT,
  UPDATE_HISTORY_ENTITY_SELLING_PRICE,
  UPDATE_HISTORY_ENTITY_STATUS,
  UPDATE_HISTORY_ENTITY_STOCK,
} from 'utils/productPreOrder'

const UPDATE_HISTORY_ENTITY_IN = {
  [UPDATE_HISTORY_ENTITY_SELLING_PRICE]: 'Pre-Order Selling Price',
  [UPDATE_HISTORY_ENTITY_STOCK]: 'Pre-Order Stock',
  [UPDATE_HISTORY_ENTITY_DELIVERY_LEAD_TIME]: 'Delivery Leadtime (Days)',
  [UPDATE_HISTORY_ENTITY_STATUS]: 'Status',
  [UPDATE_HISTORY_ENTITY_PACKAGE_TYPE]: 'Package Type',
  [UPDATE_HISTORY_ENTITY_SA_COMMISSION]: 'SA Commission',
  [UPDATE_HISTORY_ENTITY_SA_COMMISSION_UNIT]: 'SA Commission Unit',
  [UPDATE_HISTORY_ENTITY_SA_COMMISSION_PACKAGE_TYPE]:
    'SA Commission Package Type',
}

export const PRODUCT_PRE_ORDER_EN = {
  ...UPDATE_HISTORY_ENTITY_IN,
  productPreOrder: 'Product Pre-Order',
  messageErrorGetPreOrderFailed: 'Get Product Pre-Orders failed!',
  preOrderSellingPrice: 'Pre-Order Selling Price',
  preOrderStock: 'Pre-Order Stock',
  deliveryLeadTime: 'Delivery Leadtime (Days)',
  afterValueDays: 'After {day} days',
  createProductPreOrder: 'Create Product Pre-Order',
  createProductPreOrderSuccess: 'Create Product Pre-Order successfully!',
  createProductPreOrderFailed: 'Create Product Pre-Order failed!',
  preOrderAlreadyExist:
    'Pre-Order Product already exists, please reuse existing information',
  getProductPreOrderFailed: 'Get Product Pre-Order failed!',
  updateProductPreOrder: 'Update Product Pre-Order',
  updateProduct: 'Update Product Pre-Order',
  messageGetUpdateHistoryFailed: 'Get Update History failed!',
  updatedInformation: 'Updated Information',
  previousInformation: 'Previous Information',
  messageSelectSku: 'Click to add <link>one or more SKUs</link>',
  ratioRefillStock: 'Ratio Refill Stock',
  purchasePrice: 'Purchase Price',
  refreshCommission: 'Refresh',
}
