import getConfig from 'next/config'
import Head from 'next/head'
import React from 'react'
import { isIndia } from 'utils/commonUtils'

declare let google: any

const {
  publicRuntimeConfig: { googleMapKey },
} = getConfig()

interface IProps {
  disabled?: boolean
}

const latLngVN = { lat: 10.762622, lng: 106.660172 }
const latLngIN = { lat: 13.067439, lng: 80.237617 }
const url = `https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&callback=initMap&libraries=places&v=weekly`
const markers = []

const setMapAtAll = (map) => {
  markers.forEach((marker) => {
    marker.setMap(map)
  })
}
const addMarker = (marker, index) => {
  index ? (markers[index] = marker) : markers.push(marker)
}

const initMap = () => {
  const map = new google.maps.Map(
    document.getElementById('map') as HTMLElement,
    {
      center: isIndia ? latLngIN : latLngVN,
      zoom: 10,
    }
  )
  const geocoder = new google.maps.Geocoder()

  const input = document.getElementById('pac-input') as HTMLInputElement

  // Specify just the place data fields that you need.
  const autocomplete = new google.maps.places.Autocomplete(input, {
    fields: ['place_id', 'geometry', 'formatted_address', 'name'],
  })
  autocomplete.bindTo('bounds', map)

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)

  const infoWindow = new google.maps.InfoWindow()
  const infoWindowContent = document.getElementById(
    'infoWindow-content'
  ) as HTMLElement

  infoWindow.setContent(infoWindowContent)
  //placeMarkerAndPanTo(e.latLng, map)
  const marker = new google.maps.Marker({
    map: map,
  })

  const coordinate = document.getElementById('coordinate') as HTMLElement

  map.addListener('click', (e) => {
    // Close the current InfoWindow.
    const latLng = { lat: e.latLng.lat(), long: e.latLng.lng() }
    localStorage.removeItem('latLng')
    localStorage.setItem('latLng', JSON.stringify(latLng ? latLng : {}))
    //placeMarkerAndPanTo(e.latLng, map)
    const marker = new google.maps.Marker({
      position: e.latLng,
      map: map,
    })

    geocoder.geocode(
      {
        latLng: e.latLng,
      },
      function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const address = results[0]
            ;(
              coordinate?.children.namedItem('lat-Lng') as HTMLElement
            ).textContent = `Latitude: ${e.latLng.lat()} - Longitude: ${e.latLng.lng()}`

            localStorage.removeItem('address')
            localStorage.setItem(
              'address',
              JSON.stringify(address ? address : {})
            )
            window.dispatchEvent(new Event('storage'))
            marker.addListener('click', function () {
              ;(
                infoWindowContent?.children.namedItem(
                  'place-address'
                ) as HTMLElement
              ).textContent = address?.formatted_address as string
              ;(
                infoWindowContent?.children.namedItem(
                  'place-latLng'
                ) as HTMLElement
              ).textContent = `Latitude: ${latLng.lat} - Longitude: ${latLng.long}`
              infoWindow.open(map, marker)
            })
          }
        }
      }
    )
    setMapAtAll(null)
    addMarker(marker, 0)
  })

  autocomplete.addListener('place_changed', () => {
    infoWindow.close()

    const place = autocomplete.getPlace()

    if (!place.geometry || !place.geometry.location) {
      return
    }

    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport)
    } else {
      map.setCenter(place.geometry.location)
      map.setZoom(17)
    }
    marker.setPlace({
      placeId: place.place_id,
      location: place.geometry.location,
    })

    const latitude = place.geometry.location.lat()
    const longitude = place.geometry.location.lng()

    const latLng = { lat: latitude, long: longitude }

    localStorage.removeItem('latLng')
    localStorage.setItem('latLng', JSON.stringify(latLng ? latLng : {}))

    localStorage.removeItem('address')
    localStorage.setItem('address', JSON.stringify(place ? place : {}))
    window.dispatchEvent(new Event('storage'))

    marker.setVisible(true)
    ;(
      infoWindowContent?.children.namedItem('place-name') as HTMLElement
    ).textContent = place?.name
    ;(
      infoWindowContent?.children.namedItem('place-address') as HTMLElement
    ).textContent = place?.formatted_address as string
    ;(
      infoWindowContent?.children.namedItem('place-latLng') as HTMLElement
    ).textContent = `Lat: ${latitude} - Lng: ${longitude}`
    ;(
      coordinate?.children.namedItem('lat-Lng') as HTMLElement
    ).textContent = `Latitude: ${latitude} - Longitude: ${longitude}`
    infoWindow.open(map, marker)
  })
}
declare global {
  interface Window {
    initMap: () => void
  }
}

if (typeof window !== 'undefined') {
  window.initMap = initMap
}

const GooglePlacesScript: React.FC<IProps> = ({ disabled }) => {
  return (
    <>
      <Head>
        <script src={url} defer />
      </Head>
      <div id="coordinate" className={`mb-4 ${disabled ? 'hidden' : ''}`}>
        <span id="lat-Lng" />
      </div>
      <div className="h-5/6">
        <div className="hidden">
          <input
            id="pac-input"
            className="mt-2 border-2 border-[#b3b3b3] rounded text-sm p-2 w-7/12"
            type="text"
            placeholder="Enter a location"
          />
        </div>
        <div id="map" className="h-full"></div>
        <div id="infoWindow-content">
          <span id="place-name"></span>
          <br />
          <span id="place-address"></span>
          <br />
          <span id="place-latLng"></span>
        </div>
      </div>
    </>
  )
}
export default React.memo(GooglePlacesScript)
