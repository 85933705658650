import { PURCHASE_REQUEST } from './purchase-request'

export const PURCHASING_CHART = 'purchasing_chart'
export const VIGO_QUOTATION = 'vigo-quotation'
export const PURCHASE_ORDER = 'distributor-purchase-order'
export const PAYMENT_REQUEST = 'payment-request'
const STATUS = 'status'
const SOURCE_SCREEN = 'source_screen'

//Event view in pages purchasing
export const EVENT_VIEW_PURCHASING_CHART = 'view_purchasing_chart'
export const EVENT_VIEW_PURCHASING_WORKFLOW = 'view_purchasing_workflow'
export const EVENT_VIEW_PURCHASE_REQUEST_LIST = 'view_purchase_request_list'
export const EVENT_VIEW_VIGO_QUOTATION_LIST = 'view_vigo_quotation_list'
export const EVENT_VIEW_DISTRIBUTOR_PURCHASE_ORDER_LIST =
  'view_distributor_purchase_order_list'
export const EVENT_VIEW_PAYMENT_REQUEST_LIST = 'view_payment_request_list'

//Mapping shortcuts purchasing chart

export const SHORTCUTS_VIEW_TRACKING_MAPPING = {
  [PURCHASE_REQUEST]: EVENT_VIEW_PURCHASE_REQUEST_LIST,
  [VIGO_QUOTATION]: EVENT_VIEW_VIGO_QUOTATION_LIST,
  [PURCHASE_ORDER]: EVENT_VIEW_DISTRIBUTOR_PURCHASE_ORDER_LIST,
  [PAYMENT_REQUEST]: EVENT_VIEW_PAYMENT_REQUEST_LIST,
}
export const PROPERTIES_SHORTCUTS_PURCHASING_MAPPING = {
  [SOURCE_SCREEN]: 'source_screen',
  [STATUS]: 'filter_by_tab_status_selected',
}
