import {
  ADMIN_ROLE,
  EXTERNAL_DISTRIBUTOR,
  FINANCE,
  MASTER_DISTRIBUTOR_HUB,
} from 'common/config/acl'
import { Distributor } from 'interfaces/distributor'
import get from 'lodash/get'
import { useSession } from 'next-auth/react'
import { stringify } from 'querystring'
import { useEffect, useMemo, useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/commonUtils'

import useACL from './useACL'

const useGetDistributorID = (isExactEmail?: boolean) => {
  const [shouldRefetch, setRefetch] = useState({})
  const [distributorID, setDistributorID] = useState<string>('')
  const [enableEWBill, setEnableEWBill] = useState(false)
  const [distributorName, setDistributorName] = useState<string>('')
  const [distributor, setDistributor] = useState<Distributor>(null)
  const { userRoles } = useACL()
  const { data: session } = useSession()
  const [finish, setFinish] = useState<boolean>(false)

  const isExDistributorOrDistributorHub = useMemo(() => {
    const isRoleAdminOrFinance = userRoles.some(
      (r) => r === ADMIN_ROLE || r === FINANCE
    )
    const isRoleDistributorOrDistributorHub = userRoles.some(
      (r) => r === EXTERNAL_DISTRIBUTOR || r === MASTER_DISTRIBUTOR_HUB
    )
    return !isRoleAdminOrFinance && isRoleDistributorOrDistributorHub
  }, [userRoles])

  const currentUserEmail = useMemo(() => {
    return get(session, 'user.email') as string
  }, [session?.user])

  useEffect(() => {
    const getDistributor = async () => {
      try {
        const params = stringify(
          isExactEmail
            ? {
                exactOwnerEmail: currentUserEmail,
              }
            : {
                ownerEmail: currentUserEmail,
              }
        )
        const res = await axiosInstance.get(`/v1/distributors?${params}`)
        const distributors = getResponseData<Distributor>(res)
        const distributorID = (get(distributors, ['0', 'id']) as string) || ''
        const enableEWayBill =
          (get(distributors, [
            '0',
            'eWBConfig',
            'enableEInvoice',
          ]) as boolean) || false
        const name = get(distributors, ['0', 'name'])
        setDistributor(distributors?.[0])
        setDistributorID(distributorID)
        setEnableEWBill(enableEWayBill)
        setDistributorName(name)
      } catch (error) {
        setDistributorID('')
      } finally {
        setFinish(true)
      }
    }

    if (isExDistributorOrDistributorHub && currentUserEmail) {
      getDistributor()
    } else {
      setDistributorID('')
      setFinish(true)
    }
  }, [isExDistributorOrDistributorHub, currentUserEmail, shouldRefetch])

  return {
    distributorID,
    enableEWBill,
    distributorName,
    distributor,
    setRefetch,
    finish,
  }
}

export default useGetDistributorID
