const BIDDING_REQUEST_STATUS = {
  created: 'CREATED',
  pending: 'PENDING',
  requested: 'REQUESTED',
  closed: 'CLOSED',
  cancelled: 'CANCELLED',
}

export const BIDDING_REQUEST_EN = {
  createBiddingRequest: 'Create Bidding Request',
  updateBiddingRequest: 'Update Bidding Request',
  warehouseProvince: 'Warehouse Province',
  selectProvince: 'Select province',
  biddingCode: 'Bidding Code',
  requestEndDate: 'Request End Date',
  biddingEndDate: 'Bidding End Date',
  updateRequest: 'Update Request',
  generalInfo: 'General Info',
  requestPeriod: 'Request Period',
  biddingPeriod: 'Bidding Period',
  addSkus: 'Add SKUs',
  selectSkus: 'Select SKUs',
  searchPlaceholder: 'Enter at least 2 letters to search',
  salePrice: 'Sale Price',
  requestedBy: 'Requested By',
  sold: 'Sold',
  inventory: 'Inventory',
  averagePriceLastMonth: 'Average Price Last Month',
  competitorPrice: 'Competitor Price',
  min: 'Min',
  max: 'Max',
  select: 'Select',
  unselect: 'Unselect',
  review: 'Review',
  goTo: 'Go to V2',
  backTo: 'Back to V1',
  submittedQty: 'Submitted Qty',
  unitPrice: 'Unit Price',
  deliverDate: 'Deliver Date',
  internalShippingFee: 'Internal Shipping Fee',
  adjustQty: 'Adjust Qty',
  adjustPrice: 'Adjust Price',
  priceShippingFee: 'Price & Shipping Fee',
  submit: 'Submitted',
  unsubmit: 'Unsubmitted',
  price: 'Price',
  pleaseSelectSkuToReview: 'Please select Sku to Review!',
  cancelWarning:
    'Warning! Cancel this Bidding Request cannot be reverted, do you want to continue?',
  subtotal: 'Subtotal',
  biddingReview: 'Bidding Approval Review',
  createPoMsg: {
    status: "Can't create PO when status is {status}",
    period: "Can't create PO before Bidding End Date!",
  },
  unitShippingFee: 'Unit Shipping Fee',
  updatedBy: 'Updated By',
  ...BIDDING_REQUEST_STATUS,
}
