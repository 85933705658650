import {
  VIGO_QUOTATION_ALL,
  VIGO_QUOTATION_CONFIRMED,
  VIGO_QUOTATION_CREATED,
  VIGO_QUOTATION_EXPIRED,
  VIGO_QUOTATION_REJECTED,
} from 'utils/vigoQuotation'

const VIGO_QUOTATION_STATUES_EN = {
  [VIGO_QUOTATION_ALL]: 'ALL',
  [VIGO_QUOTATION_CREATED]: 'CREATED',
  [VIGO_QUOTATION_CONFIRMED]: 'CONFIRMED',
  [VIGO_QUOTATION_EXPIRED]: 'EXPIRED',
  [VIGO_QUOTATION_REJECTED]: 'REJECTED',
}
export const VIGO_QUOTATION_EN = {
  vigoQuotationNumber: 'Vigo Quotation Number',
  detail: 'Detail',
  statusHistory: 'Status History',
  no: 'No.',
  createdDate: 'Created Date',
  updatedDate: 'Updated Date',
  updateBy: 'Update By',
  relatedDocuments: 'Related Documents',
  deliveryTo: 'Delivery To',
  quotationDetails: 'Quotation Details',
  shipment: 'Shipment',
  deliveredBy: 'Delivered By',
  vigoQuotation: 'Vigo Quotation',
  noOfItem: 'No. of item',
  totalAmount: 'Total Amount',
  image: 'Image',
  name: 'Name',
  selectWarehouse: 'Select Warehouse',
  actualOfferPrice: 'Actual Offer Price',
  vigoOfferPrice: 'Vigo Offer Price',
  requestedQuantity: 'Requested Quantity',
  actualTotalAmount: 'Actual Total Amount',
  totalPriceDifferent: 'Total Price Different',
  packType: 'Pack Type',
  estimatedWeight: 'Estimated Weight',
  confirm: 'confirm',
  warehouse: 'Warehouse',
  distributor: 'Distributor',
  creationTime: 'Creation Time',
  startDate: 'Start Date',
  endDate: 'End Date',
  search: 'Search',
  reset: 'Reset',
  refresh: 'Refresh',
  currentStatus: 'Current Status',
  requestDate: 'Request Date',
  confirmDeadline: 'Confirm Deadline',
  totalValue: 'Total Value',
  status: 'Status',
  pre: 'Pre',
  next: 'Next',
  nationalDrugCode: 'National Drug Code',
  batchNumber: 'Batch Number',
  shelfLifeMonth: 'Shelf Life (Month)',
  manufacturingDate: 'Manufacturing Date',
  expirationDate: 'Expiration Date',
  quantity: 'Quantity',
  productSku: 'Product - SKU',
  discountPercent: 'Discount Percent',
  beforeDiscount: 'Before Discount',
  actualPrice: 'Actual Price',
  vatPrice: 'VAT Price',
  distributor_pickup: 'Distributor Pickup',
  ...VIGO_QUOTATION_STATUES_EN,
}
