export const PRE_ORDER_STATUS_CREATED = 'created'
export const PRE_ORDER_STATUS_PROCESSING = 'processing'
export const PRE_ORDER_STATUS_COMPLETED = 'completed'
export const PRE_ORDER_STATUS_CANCELLED = 'cancelled'
export const PRE_ORDER_STATUS_PENDING = 'pending'

export const ENTITY_TYPE_PRE_ORDER = 'pre_order'

export const PRE_ORDER_STATUS_SELECT = {
  [PRE_ORDER_STATUS_CREATED]: 'CREATED',
  [PRE_ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [PRE_ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [PRE_ORDER_STATUS_CANCELLED]: 'CANCELLED',
  [PRE_ORDER_STATUS_PENDING]: 'PENDING',
}

export const PRE_ORDER_STATUS_COLOR_MAPPING = {
  [PRE_ORDER_STATUS_CREATED]: 'blue',
  [PRE_ORDER_STATUS_PROCESSING]: 'warning',
  [PRE_ORDER_STATUS_COMPLETED]: 'green',
  [PRE_ORDER_STATUS_CANCELLED]: 'red',
  [PRE_ORDER_STATUS_PENDING]: 'warning',
}

export const PRE_ORDER_STATUS_VN = [
  PRE_ORDER_STATUS_COMPLETED,
  PRE_ORDER_STATUS_CANCELLED,
  PRE_ORDER_STATUS_PENDING,
]
export const PRE_ORDER_STATUS_IN = [
  PRE_ORDER_STATUS_CREATED,
  PRE_ORDER_STATUS_PROCESSING,
  PRE_ORDER_STATUS_COMPLETED,
  PRE_ORDER_STATUS_CANCELLED,
  PRE_ORDER_STATUS_PENDING,
]
