import {
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_APPROVED,
  PRODUCT_STATUS_AWAITING_APPROVAL,
  PRODUCT_STATUS_INACTIVE,
  PRODUCT_STATUS_REJECTED,
} from 'utils/productAvailable'

const PRODUCT_STATUS_VI = {
  [PRODUCT_STATUS_ACTIVE]: 'CÓ SẴN',
  [PRODUCT_STATUS_INACTIVE]: 'TẠM HẾT',
  [PRODUCT_STATUS_AWAITING_APPROVAL]: 'CHỜ DUYỆT',
  [PRODUCT_STATUS_APPROVED]: 'ĐÃ DUYỆT',
  [PRODUCT_STATUS_REJECTED]: 'TỪ CHỐI',
}

export const PRODUCT_VI = { all: 'TẤT CẢ', ...PRODUCT_STATUS_VI }
