const INBOUND_STATUS = {
  created: 'CREATED',
  distributor_approved: 'DISTRIBUTOR APPROVED',
  finance_approved: 'FINANCE APPROVED',
  rejected: 'REJECTED',
  bgm_confirmed: 'BGM CONFIRMED',
  all: 'ALL',
}

const INBOUND_STATUS_TRANSITION_ACTION = {
  distributor_approved: 'Distributor Approve',
  finance_approved: 'Finance Approve',
  rejected: 'Reject',
  created: 'Move to Created',
  bgm_confirmed: 'BGM Confirm',
}

export const INBOUND_EN = {
  number: 'Number',
  totalPrice: 'Total Price',
  totalQuantity: 'Total Quantity',
  note: 'Note',
  paymentTerm: 'Payment Term',
  notFoundMessage: 'Inbound not found!',
  statusHistory: 'Status History',
  export: 'Export',
  exportMessage: {
    success: 'Export successfully!',
    error: 'Export failed!',
  },
  additionalInfo: 'Additional Info',
  statusAction: INBOUND_STATUS_TRANSITION_ACTION,
  addBatch: 'Add Batch',
  removeBatch: 'Remove Batch',
  inboundItems: 'Inbound items',
  qtyOrdered: 'Quantity Ordered',
  printInvoice: 'Print Invoice',
  warningMessageMassUpdate:
    'Please double check SKUs: {skus}. National drug code, Actual price, VAT price must be the same in all batches',
  validateMessage: {
    expirationDate: 'Expiration Date must be >= current date!',
    invalidQty: 'Invalid Quantity! Total quantity must be equal to Quantity',
    submitError: {
      skus: 'SKUs {errSKU}: the Total Quantity of Batches must be equal to the Quantity!',
      batches:
        'Batches {errBatch}: the total Accepted Quantity and Rejected Quantity of the Batch must be greater than 0!',
    },
    shippingFee: 'Shipping Fee must be >= 0!',
  },
  paid: 'PAID',
  unpaid: 'UNPAID',
  not_yet: 'Not Yet Invoice',
  partial: 'Partial Invoice',
  full: 'Full Invoice',
  printInboundNote: 'Print Inbound Note',
  ...INBOUND_STATUS,
}
