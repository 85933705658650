import {
  PURCHASE_ORDER_DISTRIBUTOR_HUB,
  PURCHASE_ORDER_SUPPLIER_HUB,
} from 'utils/purchaseOrder'

const PURCHASE_ORDER_STATUS = {
  created: 'Mới',
  supplier_approved: 'Nhà cung cấp đã duyệt',
  approved: 'Duyệt',
  inbound_created: 'Đã tạo lệnh nhập kho',
  inbounded: 'Đã nhập kho',
  rejected: 'Từ chối',
  canceled: 'Hủy',
  pending: 'Chờ xử lý',
  all: 'Tất cả',
  request_high_level_approved: 'Cần duyệt cấp 2',
}

const PURCHASE_ORDER_STATUS_TRANSITION_ACTION = {
  supplier_approved: 'Nhà cung cấp duyệt',
  approved: 'PO Manager Duyệt',
  inbound_created: '',
  inbounded: '',
  rejected: 'Từ chối',
  pending: 'Chờ xử lý',
  created: 'Xác nhận',
}

const PURCHASE_ORDER_HUB_TAB = {
  [PURCHASE_ORDER_SUPPLIER_HUB]: 'Trung tâm nhà cung cấp',
  [PURCHASE_ORDER_DISTRIBUTOR_HUB]: 'Trung tâm nhà phân phối',
}

export const PO_VI = {
  purchaseOrderManagement: 'Quản lý đơn đặt hàng',
  biddingCode: 'Mã đấu thầu',
  createPurchaseOrder: 'Tạo đơn đặt hàng',
  exportToExcel: 'Xuất đơn đặt hàng đã chọn ra định dạng Excel',
  printDeliveryMinutes: 'In biên bản giao nhận hàng hóa',
  poDate: 'Ngày hiệu lực lên đơn đặt hàng',
  poNote: 'Ghi chú cho đơn đặt hàng',
  totalPrice: 'Tổng giá trị',
  addProductMsg: 'Vui lòng thêm sản phẩm của nhà cung cấp!',
  createPO: 'Tạo đơn đặt hàng',
  configSupplierProducts: 'Thiết lập sản phẩm nhà cung cấp',
  configSupplierProductWarning:
    'Vui lòng chọn nhà cung cấp và thêm sản phẩm nhà cung cấp!',
  productPrice: 'Giá sản phẩm',
  qtyValidateMsg: {
    less: 'Số lượng phải nhỏ hơn {qty}',
    greater: 'Số lượng phải lớn hơn {qty}',
  },
  priceValidateMsg: 'Giá phải từ {min} đến {max}!',
  priceOutOfRangeMsg: 'Giá nằm ngoài phạm vi giữa {min} và {max}',
  min: 'Thấp nhất',
  max: 'Cao nhất',
  sold: 'Đã bán',
  inventory: 'Tổn kho hiện tại',
  searchPlaceholder: 'Nhập từ khóa để tìm kiếm',
  price: 'Giá',
  selectSupplierProducts: 'Chọn sản phẩm nhà cung cấp',
  notFound: 'Không tìm thấy đơn đặt hàng',
  paymentTerm: 'Chính sách thanh toán',
  paymentDate: 'Ngày thanh toán',
  logisticsCost: 'Phí vận chuyển',
  plInfo: 'Thông tin đối tác vận chuyển',
  logisticsCostValidateMsg: 'Phí vận chuyển phải > 0',
  poItems: 'Danh sách sản phẩm',
  vatAmount: 'Tổng giá sau thuế',
  actualAmount: 'Tổng giá thực tế',
  expectedDeliveryDate: 'Ngày vận chuyển mong muốn',
  massUpdate: 'Cập nhật hàng loạt',
  startUpload: 'Bắt đầu tải lên',
  massiveCreatePO: 'Tạo hàng loạt đơn đặt hàng',
  statusTransition: PURCHASE_ORDER_STATUS_TRANSITION_ACTION,
  printDeliveryNote: 'Phiếu giao hàng',
  ...PURCHASE_ORDER_STATUS,
  ...PURCHASE_ORDER_HUB_TAB,
}
