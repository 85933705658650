export const VIGO_PURCHASE_ORDER_VI = {
  all: 'TẤT CẢ',
  created: 'MỚI',
  awaiting_payment: 'CHỜ THANH TOÁN',
  paid: 'ĐÃ THANH TOÁN',
  cancelled: 'ĐÃ HỦY',
  title: 'Đơn đặt hàng của Vigo',
  debt: 'GHI NỢ',
  messageCancel:
    'Bạn có chắc chắn TỪ CHỐI Đơn đặt hàng này từ Vigo không? Hành động này KHÔNG THỂ hoàn tác.',
  messageConfirm:
    'Bạn có chắc chắn XÁC NHẬN rằng bạn có thể hoàn tất Lệnh mua hàng này từ Vigo không?',
}
