export const PAYMENT_REQUEST_LIST = 'payment_request_list'
export const MARK_PAYMENT_REQUEST_AS_PAID = 'mark_payment_request_as_paid'

export const EVENT_VIEW_PAYMENT_REQUEST_LIST = 'view_payment_request_list'
export const EVENT_VIEW_PAYMENT_REQUEST_DETAIL = 'view_payment_request_detail'
//Properties filter payment request
const PAYMENT_REQUEST_NUMBER = 'paymentRequestNumber'
const PURCHASE_ORDER_NUMBER = 'purchaseOrderNumber'
const VIGO_PURCHASE_ORDER_NUMBER = 'vigoPurchaseOrderNumber'
const DATE_TO = 'dateTo'
const DATE_FROM = 'dateFrom'
const STATUS = 'status'
//Properties payment request detail mapping
const SOURCE_SCREEN = 'source_screen'
const PURCHASE_ORDER_TYPE = 'purchaseOrderType'
const PAYMENT_FROM = 'paymentFrom'
const PAYMENT_TO = 'paymentTo'
const IS_SUCCESSFUL = 'is_successful'
const ERROR_TYPE = 'error_type'
const ERROR_DESCRIPTION = 'error_description'

//Properties filter payment request mapping
export const PROPERTIES_FILTER_PAYMENT_REQUEST_MAPPING = {
  [VIGO_PURCHASE_ORDER_NUMBER]: 'filter_by_vigo_purchase_order_number_searched',
  [PURCHASE_ORDER_NUMBER]:
    'filter_by_distributor_purchase_order_number_searched',
  [DATE_FROM]: 'filter_by_create_start_date_selected',
  [DATE_TO]: 'filter_by_create_end_date_selected',
  [PAYMENT_REQUEST_NUMBER]: 'filter_by_payment_request_number_searched',
  [STATUS]: 'filter_by_tab_status_selected',
}
export const PROPERTIES_DETAIL_PAYMENT_REQUEST_MAPPING = {
  [SOURCE_SCREEN]: 'source_screen',
  [PAYMENT_REQUEST_NUMBER]: 'payment_request_number',
  [VIGO_PURCHASE_ORDER_NUMBER]: 'vigo_purchase_order_number',
  [PURCHASE_ORDER_NUMBER]: 'distributor_purchase_order_number',
  [PURCHASE_ORDER_TYPE]: 'purchase_order_type',
  [PAYMENT_FROM]: 'payment_from',
  [PAYMENT_TO]: 'payment_to',
  [IS_SUCCESSFUL]: 'is_successful',
  [ERROR_TYPE]: 'error_type',
  [ERROR_DESCRIPTION]: 'error_description',
}
