import { INotification } from 'interfaces/Notification'

export const initialState: IContextState = {
  notifications: undefined,
  refreshKey: 0,
  locations: [],
}

export interface IContextState {
  notifications: INotification
  refreshKey: number
  locations: any[]
}

export interface IContextAction {
  type: ContextActionType
  payload: any
}

export enum ContextActionType {
  REFRESH_NOTIFICATION = 'REFRESH_NOTIFICATION',
  LOCATIONS = 'LOCATIONS',
}

export const contextHomePage = (
  state: IContextState,
  action: IContextAction
) => {
  switch (action.type) {
    case ContextActionType.REFRESH_NOTIFICATION:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
