import {
  PICKLIST_STATUS_CANCELLED,
  PICKLIST_STATUS_CHECKING,
  PICKLIST_STATUS_COMPLETED,
  PICKLIST_STATUS_CREATED,
  PICKLIST_STATUS_PICKING,
} from 'utils/picklist'

const PICKLIST_STATUS = {
  [PICKLIST_STATUS_CREATED]: 'CREATED',
  [PICKLIST_STATUS_CHECKING]: 'CHECKING',
  [PICKLIST_STATUS_PICKING]: 'PICKING',
  [PICKLIST_STATUS_COMPLETED]: 'COMPLETED',
  [PICKLIST_STATUS_CANCELLED]: 'CANCELLED',
}

const PICKLIST_ITEM_STATUS = {
  created: 'CREATED',
  checking: 'CHECKING',
  picking: 'PICKING',
  approved: 'APPROVED',
  rejected: 'REJECTED',
}

export const PICKLIST_EN = {
  printErrorMessage: 'Please select up to 100 records!',
  createErrorMessage:
    'Cannot create new delivery if selected picklist is not in completed state',
  createNewDelivery: 'Create new delivery',
  picklistNumber: 'Picklist Number',
  agentName: 'Agent Name',
  agentCode: 'Agent Code',
  agentPhone: 'Agent Phone',
  customerName: 'Customer Name',
  receiverAddress: 'Receiver Address',
  receiverPhone: 'Receiver Phone',
  totalSku: 'Total SKU',
  totalItems: 'Total Items',
  totalCheckoutItems: 'Total Checkout Items',
  totalCheckoutSku: 'Total Checkout SKU',
  picklistDetail: 'Picklist Detail',
  promiseMinTime: 'Promise Min time',
  promiseMaxTime: 'Promise Max Time',
  picklistItems: 'Picklist Items',
  printPicklist: 'Print Picklists',
  printReceipt: 'Print Receipt',
  printDraft: 'Print Draft Invoice',
  ...PICKLIST_STATUS,
  ...PICKLIST_ITEM_STATUS,
}
