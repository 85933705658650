export const DISTRIBUTOR_CONTRACT_FEE_VI = {
  contractItem: 'Loại hợp đồng',
  feeType: 'Loại phí',
  marketing: 'Marketing',
  warehousing: 'Kho bãi',
  delivery: 'Giao hàng',
  serviceFee: 'Dịch vụ',
  fromQuantity: 'Từ số lượng',
  toQuantity: 'Đến số lượng',
  feeValue: 'Mức phí',
  title: 'Quản Lý Phí Hợp Đồng Nhà Phân Phối',
  service_fee: 'Dịch vụ',
  per_case: 'Theo thùng',
  per_unit: 'Theo đơn vị',
  percent: 'Phần trăm',
  effectiveFrom: 'Có hiệu lực từ',
  effectiveTo: 'Có hiệu lực đến',
  effectiveFromTo: 'Có hiệu lực từ - đến',
  confirmDelete: 'Xoá phí hợp đồng - Nhà phân phối: {name}',
  contentConfirmDelete:
    'Tất cả thông tin về phí hợp đồng của nhà phân phối này sẽ bị xóa',
  yes: 'Xác nhận xóa',
  no: 'Không',
  distributorContractFee: 'Phí hợp đồng nhà phân phối',
  titleEditModal: 'Chỉnh sửa phí hợp đồng',
  historyUpdated: 'Lịch sử cập nhật',
  warningCancel: 'Bạn có chắc chắn muốn Huỷ việc cập nhật?',
  limitFeeAmount: 'Mức phí tối đa',
  salePrice: 'Doanh Số',
}
