export const PURCHASE_REQUEST = 'purchase-request'
export const PURCHASE_REQUEST_LIST = 'purchase_request_list'
export const PURCHASE_REQUEST_NUMBER = 'purchase_request_number'

//Event view create purchase request
export const EVENT_VIEW_CREATE_PURCHASE_REQUEST_FORM =
  'view_create_purchase_request_form'

export const EVENT_VIEW_PURCHASE_REQUEST_DETAIL = 'view_purchase_request_detail'

//Action in purchase request
export const CREATE_PURCHASE_REQUEST = 'create_purchase_request'
export const UPDATE_PURCHASE_REQUEST = 'update_purchase_request'
export const CANCEL_PURCHASE_REQUEST = 'cancel_purchase_request'
export const CONFIRM_PURCHASE_REQUEST = 'confirm_purchase_request'
export const COPY_PURCHASE_REQUEST = 'copy_purchase_request'

//
const SOURCE_SCREEN = 'source_screen'
//Properties filter in purchase-request
const PR_NUMBER = 'PRNumber'
const DATE_FROM = 'dateFrom'
const DATE_TO = 'dateTo'
const WAREHOUSE_NAMES = 'warehouseNames'
const DISTRIBUTOR_NAME = 'distributorName'
const BRAND_GROUP_NAMES = 'brandGroupNames'
const STATUS = 'status'
//Properties create ,edit ,copy and cancel in purchase-request
const WAREHOUSE_NAME = 'warehouseName'
const BRAND_GROUP_NAME = 'brandGroupName'
const BRAND_NAME = 'brandName'
const NOTE = 'note'
const SKUS = 'skus'
const IS_UPDATE_QUANTITY = 'isUpdateQuantity'
const IS_SUCCESSFUL = 'is_successful'
const COPY_TYPE = 'copyType'
const ERROR_TYPE = 'error_type'
const ERROR_DESCRIPTION = 'error_description'

export const PROPERTIES_FILTER_PURCHASE_REQUEST_MAPPING = {
  [PR_NUMBER]: 'filter_by_pr_number_searched',
  [DATE_FROM]: 'filter_by_request_start_date_selected',
  [DATE_TO]: 'filter_by_request_end_date_selected',
  [WAREHOUSE_NAMES]: 'filter_by_warehouse_selected',
  [DISTRIBUTOR_NAME]: 'filter_by_distributor_selected',
  [BRAND_GROUP_NAMES]: 'filter_by_brand_group_selected',
  [STATUS]: 'filter_by_tab_status_selected',
}

export const PROPERTIES_CREATE_PURCHASE_REQUEST_MAPPING = {
  [PR_NUMBER]: 'purchase_request_number',
  [WAREHOUSE_NAME]: 'warehouse',
  [DISTRIBUTOR_NAME]: 'distributor',
  [BRAND_NAME]: 'brand',
  [BRAND_GROUP_NAME]: 'brand_group',
  [NOTE]: 'note',
  [SKUS]: 'SKU',
  [COPY_TYPE]: 'copy_type',
  [IS_UPDATE_QUANTITY]: 'is_updated_quantity',
  [IS_SUCCESSFUL]: 'is_successful',
  [ERROR_TYPE]: 'error_type',
  [ERROR_DESCRIPTION]: 'error_description',
  [STATUS]: 'status',
}
export const PROPERTIES_DETAIL_PURCHASE_REQUEST_MAPPING = {
  [SOURCE_SCREEN]: 'source_screen',
  [PURCHASE_REQUEST_NUMBER]: 'purchase_request_number',
  [STATUS]: 'status',
  [WAREHOUSE_NAME]: 'warehouse',
  [DISTRIBUTOR_NAME]: 'distributor',
}
