import { PURCHASE_REQUEST_TAB_MAPPING } from 'utils/purchaseRequest'

export const PURCHASE_REQUEST_EN = {
  pageName: 'Purchase Request',
  createNewPurchaseRequest: 'Create New Purchase Request',
  addPurchaseRequest: 'Add Purchase Request',
  refresh: 'Refresh',
  PRNumber: 'PR Number',
  warehouse: 'Warehouse',
  distributor: 'Distributor',
  brandGroup: 'Brand Group',
  requestDate: 'Request Date',
  currentStatus: 'Current Status',
  startDate: 'Start Date',
  endDate: 'End Date',
  search: 'Search',
  reset: 'Reset',
  all: 'ALL',
  recommended: 'RECOMMENDED',
  created: 'CREATED',
  quoting: 'QUOTING',
  quoted: 'QUOTED',
  closed: 'CLOSED',
  cancelled: 'CANCELLED',
  messageCreatePR: 'Each PR will be created for each brand groups ?',
  messageCancelPR:
    'Are you sure to CANCEL this Purchase Request? This cannot be done.',
  messageSelectWarehouse: 'Please select warehouse!',
  copyPurchaseRequest: 'Copy Purchase Request',
  copyExistingPR: 'Copy From Existing Purchase Request',
  createdDate: 'Created Date',
  selectWarehouse: 'Select Warehouse',
  searchBrandGroup: 'Search Brand Group',
  createdBy: 'Created By',
  requestBy: 'Request By',
  leadTime: 'Lead Time',
  dayAgo: 'day ago',
  monthAgo: 'month ago',
  yearAgo: 'year ago',
  pre: 'Pre',
  next: 'Next',
  records: 'records',
  page: 'page',
  status: 'Status',
  items: 'Items',
  addItem: 'Add Item',
  selectSKUs: 'Select SKUs',
  selectCategory: 'Select Category',
  productName: 'Product Name',
  poPrice: 'PO Price',
  salePrice: 'Sale Price',
  image: 'Image',
  ok: 'OK',
  quantity: 'Quantity',
  quantityRequired: 'Quantity is Required!',
  packType: 'Pack Type',
  action: 'Action',
  cancel: 'Cancel',
  create: 'Create',
  name: 'Name',
  brandGroupRequired: 'Please select brand group!',
  brand: 'Brand',
  update: 'Update',
  statusHistory: 'Status History',
  copyPRSuccess: 'Copy Purchase Request Success!',
  cancelPRSuccess: 'Cancel Purchase Request Successfully',
  confirmPRSuccess: 'Confirm Purchase Request Successfully',
  prUpdated: 'Purchase Request Updated!',
  prCreated: 'Purchase Request Created!',
  confirmDeleteSupplierProduct: 'Are you sure you want to delete this?',
  deleteSupplierProduct: 'Delete Supplier Product',
  confirmPurchaseRequest: 'Confirm',
  referralPrice: 'Referral Price',
  totalReferralAmount: 'Total Referral Amount',
  totalReferralPrice: 'Total Referral Price',
  estimateProfit: 'Estimate Profit',
  totalEstimateProfit: 'Total Estimate Profit',
  sellOutDay: 'Sell Out Day',
  recommendedSellOutDay: 'Sell Out Day',
  reasonCancel: 'Reason Cancel',
  industry: 'Industry',
  createRetail: 'Retail Purchase Request',
  createConsignment: 'Consignment Purchase Request',
  retail: 'Retail',
  consignment: 'Consignment',
  supplier_of_distributor: 'Supplier of Distributor',
  undefined: 'Undefined Purchase Request',
  ...PURCHASE_REQUEST_TAB_MAPPING,
}
