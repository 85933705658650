export const PURCHASING_EN = {
  pageName: 'Purchasing',
  purchaseOrderTrends: 'Purchase Order Trends',
  shortcuts: 'Your shortcuts',
  purchaseRequest: 'Purchase Request',
  vigoQuotation: 'Vigo Quotation',
  purchaseOrder: 'Purchase Order',
  paymentRequest: 'Payment Request',
  purchasingWorkflow: 'Purchasing Workflow',
}
