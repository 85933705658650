import { Select } from 'antd'
import { Brand } from 'interfaces/product'
import stringify from 'qs/lib/stringify'
import React, { useEffect, useState } from 'react'
import axios from 'utils/axiosInstance'
import { defaultStringifyOption, getResponseData } from 'utils/commonUtils'

interface IProps {
  mode?: 'multiple'
  disabled?: boolean
  value?: string | string[]
  onChange?: (id: string | string[]) => void
  placeholder?: string
  selectedBrandGroupID?: string
  includeSelectAll?: boolean
  optionValueKey?: string
  maxTagCount?: number | 'responsive'
  setBrandsName?: (data) => void
}

interface BrandFilter {
  perPage: number
  ignorePerPage: boolean
  orderBy: string
  name?: string
  brandGroupID?: string
}

const BrandsSelect: React.FC<IProps> = ({
  disabled,
  value,
  mode,
  onChange,
  placeholder = 'Search platform brand',
  selectedBrandGroupID,
  includeSelectAll,
  optionValueKey = 'id',
  maxTagCount,
  setBrandsName,
}) => {
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState<Brand[]>([])

  useEffect(() => {
    const getBrands = async (name?: string) => {
      try {
        setLoading(true)

        const payload: BrandFilter = {
          perPage: 1000,
          ignorePerPage: true,
          orderBy: 'updatedAt desc',
          name: name && encodeURIComponent(name),
        }
        if (selectedBrandGroupID) {
          payload.brandGroupID = selectedBrandGroupID
        }

        const params = stringify(payload, defaultStringifyOption)

        const response = await axios.get(`/v1/brands?${params}`)
        const data = getResponseData<Brand[]>(response)
        setBrands(data)

        const brandsName = data?.map((brand) => brand.name)
        setBrandsName && setBrandsName(brandsName)
      } catch (error) {
        setBrands([])
      } finally {
        setLoading(false)
      }
    }

    getBrands()
  }, [selectedBrandGroupID])

  return (
    <Select
      allowClear
      showSearch
      value={value}
      mode={mode}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      loading={loading}
      className="w-full"
      placeholder={placeholder}
      onChange={onChange}
      maxTagCount={maxTagCount}
      dropdownMatchSelectWidth={false}
    >
      {includeSelectAll && (
        <Select.Option key="all" value="all">
          All
        </Select.Option>
      )}

      {brands.map((p) => (
        <Select.Option key={p.id} value={p[optionValueKey]}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default React.memo(BrandsSelect)
