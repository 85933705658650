import { Alert } from 'antd'
import React, { memo } from 'react'

interface IProps {
  error: string
  onClose: () => void
}

const ErrorAlert: React.FC<IProps> = ({ error, onClose }) => {
  return <Alert type="error" message={error} closable onClose={onClose} />
}

export default memo(ErrorAlert)
