import {
  INVOICE_BUY_IN_STATUS_CREATED,
  INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED,
  INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT,
  INVOICE_BUY_IN_STATUS_VIGO_APPROVED,
  INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED,
} from 'utils/invoiceUtils'

const BUY_IN_INVOICE_STATUS = {
  [INVOICE_BUY_IN_STATUS_CREATED]: 'CREATED',
  [INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED]: 'VIGO CONFIRMED',
  [INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT]: 'NEED ADJUSTMENT',
  [INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED]: 'DISTRIBUTOR CONFIRMED',
  [INVOICE_BUY_IN_STATUS_VIGO_APPROVED]: 'VIGO APPROVED',
}

export const BUY_IN_INVOICE_EN = {
  buyInNumber: 'Buy-In Number',
  buyInDate: 'Buy-In Date',
  totalPurchaseQty: 'Total Purchase Qty',
  totalPurchaseAmount: 'Total Purchase Amount',
  totalSuccessFeeAmount: 'Total Success Fee Amount',
  invoiceDate: 'Invoice Date',
  buyInInvoiceManagement: 'Buy-In Invoice Management',
  exportBuyInInvoice: 'Export Buy-in Invoice',
  rejectComment:
    'had rejected the Invoice information this Buy-in. The reason for this is:',
  actionMsg: {
    vigo_confirmed:
      'Are you sure to confirm that the information on this Buy-in invoice is CORRECT?',
    vigo_approved:
      'Are you sure to approve that Invoice details on this Buy-in invoice is CORRECT?',
  },
  rejectReason: 'Reject Reason',
  helpRejectMsg:
    'Please enter the reason for rejecting this Invoice information from the Distributor.',
  submitInvoice: 'Submit invoice',
  reject: 'Reject',
  approve: 'Approve',
  instruction: 'Instruction',
  instructionMsg:
    'Please kindly fill in all required information and attach a PDF file of the invoice.',
  currentState: 'Current State',
  deliveredDate: 'Delivered Date',
  vat: 'VAT',
  costUnitPrice: 'Cost Unit Price',
  purchaseQuantity: 'Purchase Quantity',
  purchaseAmount: 'Purchase Amount',
  sellOutItems: 'Sell-out Items',
  sellOutNumber: 'Sell-out Number',
  postedDate: 'Posted Date',
  sellOutDate: 'Sell-out Date',
  eInvoice: 'E-Invoice',
  activity: 'Activity',
  comments: 'Comments',
  history: 'History',
  buyInItems: 'Buy-in Items',
  editBuyInInvoice: 'Edit Buy-in Invoice',
  costUnitPriceValid: 'Cost Unit Price must be > 0',
  orderCreationTime: 'Order Creation Time',
  unitPrice: 'Unit Price',
  salesQuantity: 'Sales Quantity',
  successFeeAmount: 'Success Fee Amount',
  costAmount: 'Cost Amount',
  salesAmount: 'Sales Amount',
  addComment: 'Add a comment...',
  changed: 'Changed',
  status: 'Status',
  invoiceNumber: 'Invoice Number',
  ...BUY_IN_INVOICE_STATUS,
}
