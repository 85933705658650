import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_PROCESSING,
  PAYMENT_METHOD_COD,
  PAYMENT_METHOD_CREDIT,
} from 'utils/order'

const ORDER_STATUS = {
  [ORDER_STATUS_CREATED]: 'Mới',
  [ORDER_STATUS_PROCESSING]: 'Đang xử lý',
  [ORDER_STATUS_COMPLETED]: 'Hoàn thành',
  [ORDER_STATUS_CANCELLED]: 'Đã hủy',
  [ORDER_STATUS_PENDING]: 'Đang chờ',
}

const PAYMENT_METHOD = {
  [PAYMENT_METHOD_COD]: 'Thanh toán tiền mặt',
  [PAYMENT_METHOD_CREDIT]: 'Thanh toán trả trước',
}

const ORDER_ITEM_STATUS = {
  created: 'Mới',
  picklisted: 'Đã lấy hàng',
  picking: 'Đang lấy hàng',
  checking: 'Đang kiểm tra',
  checked_out: 'Đã kiểm tra',
  cancelled: 'Đã hủy',
  returned: 'Đã trả',
  ready_to_ship: 'Sẵn sàng giao hàng',
  delivered: 'Đã giao hàng',
  in_transit: 'Đang vận chuyển',
  delivery_failed: 'Giao hàng thất bại',
  partial_delivered: 'Giao một phần',
}

export const ORDERS_VI = {
  orderedQuantity: 'Số lượng đã đặt hàng',
  confirmedQuantity: 'Số lượng đã xác nhận',
  orderManagement: 'Quản lý đơn hàng bán ra',
  sender: 'Người gửi',
  receiver: 'Người nhận',
  tradeOrderCreatedDate: 'Ngày tạo đơn hàng',
  skuOrder: 'Số lượng mặt hàng',
  itemOrders: 'Số lượng sản phẩm',
  grantTotal: 'Tổng giá trị',
  paymentMethod: 'Phương thức thanh toán',
  cancelOrder: 'Hủy đơn hàng',
  orderDetail: 'Chi tiết đơn hàng',
  remark: 'Ghi chú',
  tags: 'Thẻ',
  paidPrice: 'Giá thanh toán',
  taxAmount: 'Tổng giá trị sau thuế',
  unitPrice: 'Đơn giá',
  promotionDiscount: 'Chương trình giảm giá',
  distributorSubsidy: 'Tài trợ giá từ Nhà Phân Phối',
  saleAgentSubsidy: 'Tài trợ giá từ chuyên viên kinh doanh',
  adjustQuantity: 'Số lượng đã giao',
  orderItems: 'Đơn bán hàng',
  allStatus: 'Tất cả trạng thái',
  fulfillmentOrderManagement: 'Quản lý đơn giao hàng',
  cod: 'Thanh toán tiền mặt',
  shippingType: {
    text: 'Phương thức giao hàng',
    warehouse: 'Kho hàng',
    dropshipping: 'Không thông qua kho hàng',
  },
  'pre-order': 'Hàng đặt trước',
  ...ORDER_STATUS,
  ...PAYMENT_METHOD,
  ...ORDER_ITEM_STATUS,
}
