export const AGENT_COLLECTION_VI = {
  title: 'Xác nhận thu tiền nhân viên bán hàng',
  agent: 'Nhân viên bán hàng ',
  all: 'TẤT CẢ',
  waiting_collect: 'CHỜ THU TIỀN',
  partial_collected: 'THU TIỀN MỘT PHẦN',
  collected: 'ĐÃ THU',
  rejected: 'ĐÃ TỪ CHỐI',
  outstandingAndCollected: 'Số tiền còn nợ/ Số tiền đã thu',
  buttonCollected: 'Đã thu',
  agentType: 'Hình thức',
  inventoryValue: 'Thông tin giá trị tồn kho',
  collectionHistory: 'Lịch sử thu tiền',
  saleSupervisor: 'Giám sát bán hàng',
  agentCode: 'Mã NVBH',
  saleSupervisorCode: 'Email giám sát bán hàng',
  saleSupervisorName: 'Tên giám sát bán hàng',
  createNewAgent: 'Tạo nhân viên bán hàng mới',
  updateAgent: 'Cập nhật thông tin nhân viên bán hàng',
  linkAgent: 'Liên kết thông tin nhân viên bán hàng',
  unlinkAgent: 'Hủy liên kết thông tin nhân viên bán hàng',
  requiredFirstName: 'Vui lòng nhập tên của NVBH',
  requiredLastName: 'Vui lòng nhập họ của NVBH',
  requiredPhone: 'Vui lòng nhập số điện thoại',
  requiredSaleSupervisor: 'Vui lòng chọn một giám sát bán hàng',
  updateAgentSuccess:
    'Yêu cầu cập nhật NVBH đã được tạo, vui lòng chờ bộ phận Admin kiểm duyệt',
  createAgentSuccess:
    'Yêu cầu tạo NVBH đã được tạo, vui lòng chờ bộ phận Admin kiểm duyệt',
  linkAgentSuccess: 'Liên kết NVBH thành công',
  agentPhone: 'Số điện thoại NVBH',
  agentEmail: 'Email NVBH',
  massiveCollect: 'Thu tiền hàng loạt',
  confirmCollect:
    'Bạn có chắc xác nhận rằng đã thu đủ thanh toán cho tất cả các đơn hàng đã chọn không?',
  confirmReject: 'Bạn có chắc muốn từ chối?',
  titleConfirmCollectModal: 'Xác nhận thu tiền của cửa hàng',
  collectionInfo: 'Thông tin thu tiền',
  agentNameCode: 'Tên/Mã NVBH',
  outletNameCode: 'Tên/Mã Cửa hàng',
  dateTimeCollection: 'Thời gian thu tiền',
  outstandingAmt: 'Số tiền cần thu',
  collectionAmt: 'Số tiền thu',
  collectionMethod: 'Phương thức thu tiền',
  cash: 'Tiền mặt',
  bank_transfer: 'Chuyển khoản ngân hàng',
  additionalDetail: 'Chi tiết bổ sung',
  updateFrom: 'Cập nhật từ',
}
