import { ADMIN_ROLE, EXTERNAL_DISTRIBUTOR } from 'common/config/acl'
import dayjs from 'dayjs'

export const SUBSIDY_RULE_UPLOAD = 'subsidy-rule-upload'

export const SUBSIDY_RULE_STATUS_SCHEDULED = 'scheduled'
export const SUBSIDY_RULE_STATUS_IN_PROGRESS = 'in_progress'
export const SUBSIDY_RULE_STATUS_CANCELLED = 'cancelled'
export const SUBSIDY_RULE_STATUS_COMPLETED = 'completed'
export const SYSTEM_USER_ID = 'ff52b427-3cb2-46df-9c37-f0a29a446942'

export const TYPE_BY_MANUAL = 'manual'
export const TYPE_BY_AUTO = 'auto'
export const TYPE_BY_SPECIAL = 'special'

export const GENERATED_BY_MANUAL = 'manual'
export const GENERATED_BY_SYSTEM = 'system'

export const SUBSIDY_RULE_STATUS = [
  SUBSIDY_RULE_STATUS_SCHEDULED,
  SUBSIDY_RULE_STATUS_IN_PROGRESS,
  SUBSIDY_RULE_STATUS_CANCELLED,
  SUBSIDY_RULE_STATUS_COMPLETED,
]
export const SUBSIDY_RULE_TYPE = [TYPE_BY_MANUAL, TYPE_BY_AUTO, TYPE_BY_SPECIAL]

export const SUBSIDY_RULE_STATUS_COLOR_MAPPING = {
  [SUBSIDY_RULE_STATUS_SCHEDULED]: 'default',
  [SUBSIDY_RULE_STATUS_IN_PROGRESS]: 'blue',
  [SUBSIDY_RULE_STATUS_CANCELLED]: 'red',
  [SUBSIDY_RULE_STATUS_COMPLETED]: 'green',
}

export const SUBSIDY_RULE_STATUS_ALLOW_CANCEL = [
  SUBSIDY_RULE_STATUS_SCHEDULED,
  SUBSIDY_RULE_STATUS_IN_PROGRESS,
]

export const ACCEPTABLE_ROLE_CANCEL_SUBSIDY = {
  [GENERATED_BY_MANUAL]: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR],
  [GENERATED_BY_SYSTEM]: [ADMIN_ROLE],
}

export const mappingStatusSubsidyRule = (record) => {
  const now = dayjs()

  if (record.isCancelled) {
    return SUBSIDY_RULE_STATUS_CANCELLED
  }

  if (record?.effectiveFrom && record?.effectiveTo) {
    const isBeforeEffectiveTime = now.isBefore(record.effectiveFrom)
    const isAfterEffectiveTime = now.isAfter(record.effectiveTo)
    const onEffectiveTime = now.isBetween(
      record.effectiveFrom,
      record.effectiveTo,
      null,
      '[]'
    )
    if (isBeforeEffectiveTime) return SUBSIDY_RULE_STATUS_SCHEDULED
    if (isAfterEffectiveTime) return SUBSIDY_RULE_STATUS_COMPLETED
    if (onEffectiveTime) return SUBSIDY_RULE_STATUS_IN_PROGRESS
  }
}
