const BIDDING_INVITATION_STATUS = {
  requested: 'REQUESTED',
  submitted: 'SUBMITTED',
}

export const BIDDING_INVITATION_EN = {
  code: 'Code',
  biddingEndDate: 'Bidding End Date',
  submitBidding: 'Submit Bidding',
  supplierProvince: 'Supplier Province',
  searchBidding: 'Search Bidding',
  biInfo: 'Bidding Invitation Info',
  biItems: 'Bidding Invitation Items',
  requestQty: 'Request Quantity',
  biddingQty: 'Bidding Quantity',
  biddingPrice: 'Bidding Price',
  submitBI: 'Submit Bidding Invitation',
  deliveryInfo: 'Delivery Information',
  warehouseName: 'Warehouse Name',
  warehouseProvince: 'Warehouse Province',
  addComment: 'Add a comment...',
  submit: 'Submit',
  ...BIDDING_INVITATION_STATUS,
}
