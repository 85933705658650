export const VIGO_QUOTATION_ALL = 'all'
export const VIGO_QUOTATION_CREATED = 'created'
export const VIGO_QUOTATION_CONFIRMED = 'confirmed'
export const VIGO_QUOTATION_EXPIRED = 'expired'
export const VIGO_QUOTATION_REJECTED = 'rejected'

export const VIGO_QUOTATION_STATUSES = [
  VIGO_QUOTATION_CREATED,
  VIGO_QUOTATION_CONFIRMED,
  VIGO_QUOTATION_EXPIRED,
]

export const VIGO_QUOTATION_TAB_MAPPING = {
  [VIGO_QUOTATION_ALL]: 'ALL',
  [VIGO_QUOTATION_CREATED]: 'CREATED',
  [VIGO_QUOTATION_CONFIRMED]: 'CONFIRMED',
  [VIGO_QUOTATION_EXPIRED]: 'EXPIRED',
}

export const VIGO_QUOTATION_STATUS_SELECT = {
  [VIGO_QUOTATION_CREATED]: 'CREATED',
  [VIGO_QUOTATION_CONFIRMED]: 'CONFIRMED',
  [VIGO_QUOTATION_EXPIRED]: 'EXPIRED',
  [VIGO_QUOTATION_REJECTED]: 'REJECTED',
}

export const VIGO_QUOTATION_STATUS_COLOR_MAPPING = {
  [VIGO_QUOTATION_CREATED]: 'blue',
  [VIGO_QUOTATION_CONFIRMED]: 'green',
  [VIGO_QUOTATION_EXPIRED]: 'red',
  [VIGO_QUOTATION_REJECTED]: 'red',
}
