export const SELL_OUT_INVOICE_EN = {
  sellOutInvoiceManagement: 'Sell-out Invoice Management',
  sellOutItems: 'Sell-out Items',
  sellOutNumber: 'Sell-out Number',
  postedDate: 'Posted Date',
  sellOutDate: 'Sell-out Date',
  eInvoice: 'E-Invoice Link',
  created: 'CREATED',
  totalSalesAmount: 'Total Sales Amount',
  withinTotalSuccessFeeAmount: 'Within Total Success Fee Amount',
  exportSellOutInvoice: 'Export Sell-out Invoice',
  sellOutDetail: 'Sell-out Detail',
  totalCostAmount: 'Total Cost Amount',
}
