import { InvoiceBuyinStatus } from 'containers/Finance/BuyInInvoice/InvoiceStatusTag'

export const INVOICE_BUY_IN_STATUS_CREATED = 'created'
export const INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED = 'vigo_confirmed'
export const INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT = 'need_adjustment'
export const INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED =
  'distributor_confirmed'
export const INVOICE_BUY_IN_STATUS_VIGO_APPROVED = 'vigo_approved'
export const TYPE_OF_PROCESS_TRADING = 'trading'
export const TYPE_OF_PROCESS_RETAIL = 'retail'
export const TYPE_OF_PROCESS_TRADING_BY_LISTING = 'trading_by_listing'
export const TYPE_OF_PROCESS_CONSIGNMENT = 'consignment'

export const INVOICE_BUY_IN_STATUS_LABEL_MAPPING = [
  INVOICE_BUY_IN_STATUS_CREATED,
  INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED,
  INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT,
  INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED,
  INVOICE_BUY_IN_STATUS_VIGO_APPROVED,
]

export const INVOICE_BUY_IN_STATUS_COLOR_MAPPING = {
  [INVOICE_BUY_IN_STATUS_CREATED]: 'blue',
  [INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED]: 'lime',
  [INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT]: 'warning',
  [INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED]: 'lime',
  [INVOICE_BUY_IN_STATUS_VIGO_APPROVED]: 'green',
}

export const TYPE_OF_PROCESS_SELECT = [
  TYPE_OF_PROCESS_TRADING,
  TYPE_OF_PROCESS_RETAIL,
  TYPE_OF_PROCESS_TRADING_BY_LISTING,
  TYPE_OF_PROCESS_CONSIGNMENT,
]

export const INVOICE_BUY_IN_STATUS_TRANSITION: Record<string, string[]> = {
  [INVOICE_BUY_IN_STATUS_CREATED]: [INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED],
  [INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED]: [
    INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT,
    INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED,
  ],
  [INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT]: [
    INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED,
  ],
  [INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED]: [
    INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT,
    INVOICE_BUY_IN_STATUS_VIGO_APPROVED,
  ],
}

type ActionButtonType = { text: string; status: InvoiceBuyinStatus }

export const INVOICE_BUY_IN_BUTTON_ACTION_MAPPING: Record<
  string,
  ActionButtonType
> = {
  [INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED]: {
    text: 'vigo_confirmed',
    status: INVOICE_BUY_IN_STATUS_VIGO_CONFIRMED,
  },
  [INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT]: {
    text: 'reject',
    status: INVOICE_BUY_IN_STATUS_NEED_ADJUSTMENT,
  },
  [INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED]: {
    text: 'submitInvoice',
    status: INVOICE_BUY_IN_STATUS_DISTRIBUTOR_CONFIRMED,
  },
  [INVOICE_BUY_IN_STATUS_VIGO_APPROVED]: {
    text: 'approve',
    status: INVOICE_BUY_IN_STATUS_VIGO_APPROVED,
  },
}
