const DELIVERY_STATUS = {
  created: 'Mới',
  in_delivery: 'Đang vận chuyển',
  completed: 'Hoàn thành',
  cancelled: 'Hủy',
  rider_completed: 'Tài xế hoàn thành',
  returned: 'Trả hàng',
  arrived_at_consignee_area: 'Đã đến khu vực nhận hàng',
  delivered: 'Đã giao hàng',
  delivery_failed: 'Giao hàng thất bại',
  done: 'Hoàn thành',
}

export const DELIVERY_VI = {
  code: 'Mã tuyến giao hàng',
  trackingNumber: 'Mã vận đơn',
  riderPlaceholder: 'Chọn hoặc nhập tên để tìm kiếm',
  totalStop: 'Số điểm giao hàng',
  departureTime: 'Thời gian khởi hành',
  editDelivery: 'Chỉnh sửa giao hàng',
  updateDelivery: 'Cập nhật giao hàng',
  createNewDelivery: 'Tạo mới tuyến giao hàng',
  vehicle: 'Phương tiện',
  selectPackages: 'Chọn kiện hàng',
  selectPackageMsg: 'Vui lòng chọn kiện hàng',
  removePackage: 'Xóa kiện hàng',
  cancelDelivery: 'Hủy tuyến giao hàng',
  removePackageConfirm:
    'Bạn có chắc chắn muốn xóa kiện hàng này ra khỏi tuyến giao hàng?<div>Hành động này không thể hoàn tác!</div>',
  contactName: 'Tên liên hệ',
  contactPhone: 'Số điện thoại liên hệ',
  contactAddress: 'Địa chỉ liên hệ',
  area: 'Khu vực',
  tradeOrderCreationTime: 'Thời gian tạo đơn hàng',
  exportDelivery: 'Xuất dữ liệu tuyến giao hàng',
  deliveryID: 'Mã định danh vận chuyển',
  overall: 'Tổng quan',
  cancelDeliveryConfirm:
    'Bạn có chắc chắn muốn hủy tuyến giao hàng?<div>Hành động này không thể hoàn tác!</div>',
  deliveryInfo: 'Thông tin tuyến giao hàng',
  destinationStopsDetail: 'Lộ trình giao hàng chi tiết',
  stopID: 'Mã điểm giao hàng',
  totalBag: 'Số lượng kiện hàng',
  contact: 'Liên hệ',
  senderContact: 'Thông tin người gửi',
  tags: 'Thẻ',
  redelivery: 'Phân lại tuyến giao hàng',
  return: 'Trả hàng',
  actionMsg: {
    success: 'Kiện hàng {trackingNumber} đã được {action} thành công!',
    error: '{action} không thành công!',
  },
  enterTrackingNumber: 'Nhập mã vận đơn',
  orderNumber: 'Mã đơn hàng',
  undefined: 'undefined',
  ...DELIVERY_STATUS,
}
