export const SELL_OUT_INVOICE_VI = {
  sellOutInvoiceManagement: 'Quản lý hóa đơn bán ra',
  sellOutItems: 'Danh sách sản phẩm bán ra',
  sellOutNumber: 'Mã bán ra',
  postedDate: 'Ngày công bố',
  sellOutDate: 'Ngày bán ra',
  eInvoice: 'Hóa đơn điện tử',
  created: 'Mới',
  totalSalesAmount: 'Tổng giá trị bán ra',
  withinTotalSuccessFeeAmount: 'Bao gồm tổng phí bán hàng thành công',
  exportSellOutInvoice: 'Xuất dữ liệu bán ra',
  sellOutDetail: 'Chi tiết bán ra',
  totalCostAmount: 'Tổng giá trị mua vào',
}
