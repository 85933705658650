import {
  PRE_ORDER_STATUS_CANCELLED,
  PRE_ORDER_STATUS_COMPLETED,
  PRE_ORDER_STATUS_CREATED,
  PRE_ORDER_STATUS_PENDING,
  PRE_ORDER_STATUS_PROCESSING,
} from 'utils/preOrders'

const PRE_ORDERS_STATUS_VI = {
  [PRE_ORDER_STATUS_CREATED]: 'MỚI',
  [PRE_ORDER_STATUS_PROCESSING]: 'ĐANG XỬ LÝ',
  [PRE_ORDER_STATUS_COMPLETED]: 'ĐÃ PHỐI HÀNG',
  [PRE_ORDER_STATUS_CANCELLED]: 'ĐÃ HỦY',
  [PRE_ORDER_STATUS_PENDING]: 'CHỜ XỬ LÝ',
}
export const PRE_ORDERS_VI = {
  allStatus: 'Tất cả trạng thái',
  'pre-order': 'Hàng đặt trước',
  fulfillmentOrderButton: 'Xác nhận giao hàng',
  confirmToFulfillment: 'Xác nhận giao hàng',
  messageProductNotEnough:
    'Hiện có {quantity} sản phẩm không đủ số lượng để giao:',
  messageRemoveItemFulfillment:
    'Những sản phẩm không đủ số lượng sẽ được giao một phần khi bấm giao hàng. Bạn có muốn nhập thêm sản phẩm chưa đủ số lượng?',
  buttonNoConfirmFulfillment: 'Không, tiếp tục giao',
  buttonYesConfirmFulfillment: 'Có, vui lòng đợi cho tôi nhập hàng',
  currentStock: 'tồn hiện tại',
  warningPreOrders: 'Cảnh báo giao hàng',
  all: 'TẤT CẢ',
  ...PRE_ORDERS_STATUS_VI,
}
