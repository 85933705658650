import 'styles/global.css'

import * as amplitude from '@amplitude/analytics-browser'
import { message, Spin } from 'antd'
import axios from 'axios'
import { isDistributor } from 'common/config/acl'
import { HOME } from 'common/config/routes'
import {
  IContextAction,
  IContextState,
  initialState,
} from 'components/Layout/reducer'
import * as locales from 'contents/locale'
import { StateProvider } from 'contexts/AppContext'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import useACL from 'hooks/useACL'
import useGetDistributorID from 'hooks/useGetDistributorID'
import get from 'lodash/get'
import getConfig from 'next/config'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import dynamic from 'next/dynamic'
import Router, { useRouter } from 'next/router'
import { SessionProvider, useSession } from 'next-auth/react'
import { NextIntlProvider } from 'next-intl'
import NProgress from 'nprogress'
import { LoginComponent as Login } from 'pages/login'
import React, { useEffect, useMemo, useState } from 'react'
import { isIndia } from 'utils/commonUtils'
import { EN } from 'utils/localUtils'
import { ACCESS_DENIED_MIS_SCREEN, ACCESS_DENIED_SCREEN } from 'utils/user'

import SignUpPage from './sign-up'

const { publicRuntimeConfig } = getConfig()
const authRequired = publicRuntimeConfig.authRequired === 'true'

const AppLayout = dynamic(() => import('components/Layout'), {
  ssr: false,
})

axios.defaults.baseURL = publicRuntimeConfig.apiUrl

amplitude.init(publicRuntimeConfig.amplitudeApiKey, amplitude.getUserId())

dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

Router.events.on('routeChangeStart', (url) => {
  console.log(`%c[NProgress] Loading: ${url}`, 'color:green')
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())
export const Context = React.createContext<{
  state: IContextState
  dispatch: React.Dispatch<IContextAction>
}>({
  state: initialState,
  dispatch: () => null,
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [showChild, setShowChild] = useState(false)
  const { locale } = useRouter()
  const router = useRouter()
  let language = isIndia ? EN : locale
  let messages = locales[language]

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    // Maybe show some kind of placeholder UI here
    return null
  }
  if (showChild && localStorage.getItem('language')) {
    const lang = localStorage.getItem('language')

    language = isIndia ? EN : lang ? lang : locale
    messages = locales[language]
  }

  if (authRequired) {
    const acceptedRoles = get(Component, 'auth.acceptedRoles')
    const redirectPath = get(Component, 'auth.redirectPath') || HOME

    return (
      <SessionProvider refetchInterval={5 * 60} session={pageProps?.session}>
        {Component.displayName === 'authErrorPage' ? (
          <Component {...pageProps} />
        ) : (
          <StateProvider>
            <NextIntlProvider locale={language} messages={messages}>
              {Array.isArray(acceptedRoles) && acceptedRoles.length > 0 ? (
                <ACLWrapper
                  acceptedRoles={acceptedRoles}
                  redirectPath={redirectPath}
                >
                  <AppLayout>
                    <Component {...pageProps} />
                  </AppLayout>
                </ACLWrapper>
              ) : !isIndia && router.pathname === '/sign-up' ? (
                <SignUpPage />
              ) : (
                <AppLayout>
                  <Component {...pageProps} />
                </AppLayout>
              )}
            </NextIntlProvider>
          </StateProvider>
        )}
      </SessionProvider>
    )
  }

  return (
    <AppLayout>
      <Component {...pageProps} />
    </AppLayout>
  )
}

const Spinner = () => (
  <div className="w-full h-screen grid place-content-center">
    <Spin spinning />
  </div>
)

function ACLWrapper({ acceptedRoles, redirectPath, children }) {
  const router = useRouter()
  const { data: session, status } = useSession()
  const loading = status === 'loading'
  const { userRoles } = useACL()

  const { user } = session || {}
  const isExternalDistributor = isDistributor(userRoles)
  const { distributorID, finish } = useGetDistributorID(true)
  const distributorRoles = get(session, 'user.distributorRoles', []) as string[]

  const distributorAccessDenied = useMemo(() => {
    if (finish && isExternalDistributor && !distributorID && router.pathname) {
      return distributorRoles?.length > 0
        ? ACCESS_DENIED_SCREEN.find((i) => router.pathname.includes(i)) &&
            distributorRoles?.filter((i) =>
              router.pathname.includes(ACCESS_DENIED_MIS_SCREEN[i])
            )?.length === 0
        : ACCESS_DENIED_SCREEN.find((i) => router.pathname.includes(i))
    }
  }, [finish, distributorID, isExternalDistributor, router, distributorRoles])

  const accessDenied = useMemo(() => {
    return (
      Array.isArray(acceptedRoles) &&
      !acceptedRoles.includes('*') &&
      userRoles.every((role) => !acceptedRoles.includes(role))
    )
  }, [userRoles, acceptedRoles])

  useEffect(() => {
    if (loading) return // Do nothing while loading

    if (accessDenied) {
      if (!user && router.pathname !== '/') {
        localStorage.setItem('temp-redirect', router.pathname)
      } else {
        message.error('Access denied!')
        router.replace(redirectPath)
      }
    }
    if (distributorAccessDenied && isIndia) {
      message.error('Access denied!')
      router.replace(redirectPath)
    }
  }, [loading, accessDenied, router, user, distributorAccessDenied, isIndia])

  if (loading) {
    return <Spinner />
  }

  if (!user) {
    return <Login />
  }

  if (!accessDenied) {
    return children
  }

  return <Spinner />
}

export default MyApp
