export const CONSOLIDATED_PR_VI = {
  created: 'MỚI',
  closed: 'ĐÃ ĐÓNG',
  all: 'TẤT CẢ',
  title: 'Tổng hợp yêu cầu mua hàng',
  consolidatedPRNumber: 'Mã tổng hợp yêu cầu mua hàng',
  leadTime: 'Thời gian thực hiện',
  createPR: 'Tạo mới tổng hợp yêu cầu',
  titleConfirm: 'Xác nhận và gửi yêu cầu tới Nhà cung cấp',
  selectPRs: 'Chọn yêu cầu mua hàng',
  contentSelectPRs: 'Bấm chọn yêu cầu mua hàng để tổng hợp',
  contentSelectSuppliers: 'Chọn nhà cung cấp để yêu cầu báo giá',
  contentConfirm: 'Xác nhận báo giá, gửi yêu cầu Nhà cung cấp',
  addPRs: 'Chọn yêu cầu mua hàng',
  removeSelected: 'Gỡ đã chọn',
  next: 'Tiếp tục',
  contentDrawer:
    'Chỉ những Yêu cầu mua hàng có trạng thái Đã tạo và không được bao gồm trong bất kỳ PR hợp nhất nào mới được hiển thị ở đây',
  suggestMe: 'Gợi ý một danh sách cho tôi',
  requestQuotationDeadline: 'Hạn chót yêu cầu báo giá',
  contentPreview:
    'Vui lòng xem lại Yêu cầu báo giá sẽ được gửi đến Nhà cung cấp',
  purchase_requests: 'Yêu cầu mua hàng',
  supplier_quotations: 'Báo giá nhà cung cấp',
  editQuotation: 'Chỉnh sửa báo giá',
  submitQuotation: 'Gửi báo giá',
  dear: 'Kính gửi đội ngũ bán hàng,',
  text1: 'Tôi đang viết thư cho bạn thay mặt cho',
  text2:
    'và tôi muốn hỏi liệu bạn có thể gửi cho tôi báo giá cho các chi tiết sau không.',
  text3:
    'Nó sẽ rất tốt nếu có thể gửi cho chúng tôi lời đề nghị sớm nhất có thể.',
  text4: 'Trân trọng!',
  deliveryInfo: 'Thông tin giao hàng',
  contentDelivery: 'Chúng tôi hỗ trợ giao hàng đến địa chỉ đó:',
  quotationSubmitted: 'Báo giá đã được gửi',
  submitSuccess: 'Đã gửi thành công!',
  supportDelivery: 'Giao hàng được hỗ trợ bởi Nhà cung cấp:',
  notSupportDelivery: 'Giao hàng KHÔNG được Nhà cung cấp hỗ trợ',
  viewTC: 'xem điều khoản và điều kiện',
  warningClose:
    'Bạn có chắc chắn muốn đóng mục này không? Hành động này không thể hoàn tác.',
  warningCloseNotSubmit:
    'Bạn có chắc chắn muốn đóng cái này không? CHƯA GỬI BÁO GIÁ',
  closeSuccess: 'Đóng thành công',
  validateTotalQuantity: 'Tổng số lượng báo giá <= Số lượng yêu cầu',
}
