export const PACKAGE_STATUS_ASSIGNED = 'assigned'
export const PACKAGE_STATUS_ATTEMPT_FAILED = 'attempt_failed'
export const PACKAGE_STATUS_CANCELLED = 'cancelled'
export const PACKAGE_STATUS_DELIVERED = 'delivered'
export const PACKAGE_STATUS_DELIVERY_FAILED = 'delivery_failed'
export const PACKAGE_STATUS_PARTIAL_DELIVERED = 'partial_delivered'
export const PACKAGE_STATUS_RETURNED = 'returned'
export const PACKAGE_STATUS_RIDER_DELIVERED = 'rider_delivered'

export const PACKAGE_STATUS_RIDER_DELIVERY_FAILED = 'rider_delivery_failed'
export const PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED = 'rider_partial_delivered'
export const PACKAGE_STATUS_RIDER_RETURNED = 'rider_returned'

export const PACKAGE_STATUS_AGENT_DELIVERY_FAILED = 'agent_fail_delivered'
export const PACKAGE_STATUS_AGENT_FULL_DELIVERED = 'agent_full_delivered'
export const PACKAGE_STATUS_AGENT_PARTIAL_DELIVERED = 'agent_partial_delivered'

export const PACKAGE_STATUS_DISTRIBUTOR_DELIVERED = 'distributor_delivered'
export const PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED =
  'distributor_failed_delivered'
export const PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED =
  'distributor_partial_delivered'
export const PACKAGE_STATUS_UNASSIGNED = 'unassigned'
export const PACKAGE_CREATED_DATE = 'packageCreatedDate'
export const ORDER_CREATED_DATE = 'orderCreatedDate'
const DONT_WANT_ANYMORE = 'dontWantAnymore'
const LATE_DELIVERY = 'lateDelivery'
const HIGH_PRICE = 'highPrice'
const WILL_SOON_EXPIRE = 'willSoonExpire'
const OTHER_REASON = 'otherReason'
export const INDUSTRY_PHARMACY = 'pharmacy'
export const INDUSTRY_FMCG = 'fmcg'

export const PACKAGE_STATUS_RIDER_FAILED_DELIVERY = 'rider_failed_delivery'

export const PACKAGE_UPDATED_STATUS_BY_JOB =
  'job_update_package_status_assigned_unassigned'

export const FILTER_SELECT = {
  [PACKAGE_CREATED_DATE]: 'Package Creation Date',
  [ORDER_CREATED_DATE]: 'Order Creation Date',
}

export const INDUSTRY_OPTION_SELECT = {
  [INDUSTRY_PHARMACY]: 'Pharmacy',
  [INDUSTRY_FMCG]: 'FMCG',
}

export const PACKAGE_STATUS_SELECT = {
  [PACKAGE_STATUS_ASSIGNED]: 'ASSIGNED',
  [PACKAGE_STATUS_UNASSIGNED]: 'UNASSIGNED',
  [PACKAGE_STATUS_DELIVERED]: 'DELIVERED',
  [PACKAGE_STATUS_PARTIAL_DELIVERED]: 'PARTIAL DELIVERED',
  [PACKAGE_STATUS_DELIVERY_FAILED]: 'DELIVERY FAILED',
  [PACKAGE_STATUS_ATTEMPT_FAILED]: 'ATTEMPT FAILED',
  [PACKAGE_STATUS_CANCELLED]: 'CANCELLED',
  [PACKAGE_STATUS_RETURNED]: 'RETURNED',
  [PACKAGE_STATUS_RIDER_DELIVERED]: 'RIDER DELIVERED',
  [PACKAGE_STATUS_RIDER_DELIVERY_FAILED]: 'RIDER FAILED DELIVERY',
  [PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED]: 'RIDER PARTIAL DELIVERED',
  [PACKAGE_STATUS_RIDER_RETURNED]: 'RIDER RETURNED',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERED]: 'DISTRIBUTOR DELIVERED',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]: 'DISTRIBUTOR FAILED DELIVERY',
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]:
    'DISTRIBUTOR PARTIAL DELIVERED',
  [PACKAGE_STATUS_AGENT_FULL_DELIVERED]: 'AGENT FULL DELIVERED',
  [PACKAGE_STATUS_AGENT_DELIVERY_FAILED]: 'AGENT FAIL DELIVERED',
  [PACKAGE_STATUS_AGENT_PARTIAL_DELIVERED]: 'AGENT PARTIAL DELIVERED',
}

export const PACKAGE_STATUS_COLOR_MAPPING = {
  [PACKAGE_STATUS_ASSIGNED]: 'blue',
  [PACKAGE_STATUS_ATTEMPT_FAILED]: 'red',
  [PACKAGE_STATUS_CANCELLED]: 'volcano',
  [PACKAGE_STATUS_DELIVERED]: 'green',
  [PACKAGE_STATUS_DELIVERY_FAILED]: 'red',
  [PACKAGE_STATUS_PARTIAL_DELIVERED]: 'lime',
  [PACKAGE_STATUS_RETURNED]: 'red',
  [PACKAGE_STATUS_RIDER_DELIVERED]: 'green',
  [PACKAGE_STATUS_RIDER_DELIVERY_FAILED]: 'red',
  [PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED]: 'lime',
  [PACKAGE_STATUS_RIDER_RETURNED]: 'red',
  [PACKAGE_STATUS_UNASSIGNED]: 'default',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERED]: 'green',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]: 'red',
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]: 'lime',
  [PACKAGE_STATUS_AGENT_FULL_DELIVERED]: 'green',
  [PACKAGE_STATUS_AGENT_DELIVERY_FAILED]: 'red',
  [PACKAGE_STATUS_AGENT_PARTIAL_DELIVERED]: 'lime',
}
export const DELIVERY_TYPE_COLOR_MAPPING = {
  [PACKAGE_STATUS_DELIVERY_FAILED]: 'text-red',
  [PACKAGE_STATUS_PARTIAL_DELIVERED]: 'text-yellow',
  [PACKAGE_STATUS_DELIVERED]: 'text-green',
  [PACKAGE_STATUS_RIDER_DELIVERY_FAILED]: 'text-red',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]: 'text-red',
  [PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED]: 'text-yellow',
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]: 'text-yellow',
}
export const DEFAULT_STATUSES = [
  PACKAGE_STATUS_ASSIGNED,
  PACKAGE_STATUS_UNASSIGNED,
  PACKAGE_STATUS_DELIVERED,
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_ATTEMPT_FAILED,
  PACKAGE_STATUS_CANCELLED,
  PACKAGE_STATUS_RETURNED,
  PACKAGE_STATUS_RIDER_DELIVERED,
  PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED,
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
  PACKAGE_STATUS_RIDER_RETURNED,
]

// Mark As Type Delivery
export const SELECT_MARK_AS_TYPE_OPTIONS = {
  [PACKAGE_STATUS_RIDER_DELIVERY_FAILED]: PACKAGE_STATUS_RIDER_FAILED_DELIVERY,
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]:
    PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]:
    PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
  [PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED]:
    PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED,
  [PACKAGE_STATUS_DELIVERED]: 'Full delivered',
  [PACKAGE_STATUS_PARTIAL_DELIVERED]: 'Partial delivered',
  [PACKAGE_STATUS_DELIVERY_FAILED]: 'Delivery failed',
}

// Mark As Type Distributor Delivery
export const SELECT_MARK_AS_DISTRIBUTOR_TYPE_OPTIONS = {
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERED]: 'Distributor delivered',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]: 'Distributor failed delivered',
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]:
    'Distributor partial delivered',
}

export const MARK_AS_TYPE_OPTIONS_MAPPING = {
  [PACKAGE_STATUS_DELIVERED]: 'Full delivered',
  [PACKAGE_STATUS_PARTIAL_DELIVERED]: 'Partial delivered',
  [PACKAGE_STATUS_DELIVERY_FAILED]: 'Delivery failed',
  [PACKAGE_STATUS_ASSIGNED]: 'Assigned',
  [PACKAGE_STATUS_RIDER_DELIVERED]: 'Rider delivered',
  [PACKAGE_STATUS_RIDER_DELIVERY_FAILED]: 'Rider delivery failed',
  [PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED]: 'Rider partial delivered',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERED]: 'Distributor delivered',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]: 'Distributor failed delivered',
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]:
    'Distributor partial delivered',
}

export const PACKAGE_STATUS_FAILED = [
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
]

export const DELIVERY_EXPANDABLE_ROW = [
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
  PACKAGE_STATUS_RIDER_DELIVERED,
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
  PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED,
]

export const STATUS_DELIVERED = [
  PACKAGE_STATUS_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERED,
  PACKAGE_STATUS_RIDER_DELIVERED,
]

export const STATUS_PARTIAL_DELIVERED = [
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
]

export const CHECK_QUANTITY_STATUS_PARTIAL_DELIVERED = [
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
  PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED,
]

export const STATUS_DELIVERY_FAILED = [
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
]

export const PACKAGE_FINAL_STATUS = [
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_DELIVERED,
]

export const PACKAGE_STATUS_UPDATE_COLLECTION = [
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_ASSIGNED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
]

export const RESET_MOVEMENTS_STATUS_FAILED = [
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
]

export const RESET_MOVEMENTS_STATUS_DELIVERED = [
  PACKAGE_STATUS_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERED,
  PACKAGE_STATUS_RIDER_DELIVERED,
]

export const RESET_MOVEMENTS_STATUS_PARTIAL = [
  PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED,
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
]

export const PACKAGE_STATUS_UPDATED = [
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
]

//Payment method
export const PAYMENT_METHOD_CASH = 'cash'
export const PAYMENT_METHOD_CHEQUE = 'cheque'
export const PAYMENT_METHOD_UPI = 'upi'
export const PAYMENT_METHOD_BANK = 'online bank transfer'
export const PAYMENT_METHOD_OTHER = 'others'

export const SELECT_PAYMENT_METHOD_OPTIONS = {
  [PAYMENT_METHOD_CASH]: 'Cash',
  [PAYMENT_METHOD_CHEQUE]: 'Cheque',
  [PAYMENT_METHOD_UPI]: 'UPI',
  [PAYMENT_METHOD_BANK]: 'Online Bank Transfer',
  [PAYMENT_METHOD_OTHER]: 'Others',
}

//Product Condition
export const PRODUCT_CONDITION_AWAITING_FOR_SALE = 'awaitingForSale'
export const PRODUCT_CONDITION_SALABLE = 'salable'
export const PRODUCT_CONDITION_DAMAGED = 'damaged'
export const PRODUCT_CONDITION_RESERVED = 'reserved'

export const ALLOW_STATUS_PRODUCT_CONDITION = [
  PRODUCT_CONDITION_SALABLE,
  PRODUCT_CONDITION_DAMAGED,
]

export const TYPE_TOTAL_PRICE_PRODUCT_CONDITION = {
  [PRODUCT_CONDITION_AWAITING_FOR_SALE]: 'awaitingForSaleTotalPrice',
  [PRODUCT_CONDITION_SALABLE]: 'salableTotalPrice',
  [PRODUCT_CONDITION_RESERVED]: 'reservedTotalPrice',
  [PRODUCT_CONDITION_DAMAGED]: 'damagedTotalPrice',
}

export const TYPE_TOTAL_PAID_PRICE_PRODUCT_CONDITION = {
  [PRODUCT_CONDITION_AWAITING_FOR_SALE]: 'awaitingForSaleTotalPaidPrice',
  [PRODUCT_CONDITION_SALABLE]: 'salableTotalPaidPrice',
  [PRODUCT_CONDITION_RESERVED]: 'reservedTotalPaidPrice',
  [PRODUCT_CONDITION_DAMAGED]: 'damagedTotalPaidPrice',
}

export const SELECT_PRODUCT_CONDITION_OPTIONS = {
  [PRODUCT_CONDITION_SALABLE]: 'Salable',
  [PRODUCT_CONDITION_DAMAGED]: 'Damaged',
}

//Reason options
export const REASON_OPTIONS = [
  'Shortage of Cash',
  'Outlet Closed',
  'Owner Not available',
  'Not Ordered',
  'Could not locate Outlet',
  'Other reasons',
]

export const REASON_OPTION = [
  DONT_WANT_ANYMORE,
  LATE_DELIVERY,
  HIGH_PRICE,
  WILL_SOON_EXPIRE,
  OTHER_REASON,
]
