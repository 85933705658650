import {
  INVENTORY_ENTITY_TYPE_INBOUND_ITEM,
  INVENTORY_ENTITY_TYPE_ORDER_ITEM,
  INVENTORY_ENTITY_TYPE_OUTBOUND_ITEM,
  INVENTORY_ENTITY_TYPE_OVERRIDE,
  INVENTORY_ENTITY_TYPE_PRODUCT_LISTING,
  INVENTORY_ENTITY_TYPE_PRODUCT_TRADING_STOCK,
  INVENTORY_ENTITY_TYPE_RETURN_ITEM,
  INVENTORY_ENTITY_TYPE_TRANSFER_STOCK,
  INVENTORY_ENTITY_TYPE_UPLOAD_OVERRIDE,
  INVENTORY_EXPORT_BY_BATCH,
  INVENTORY_EXPORT_MOVEMENT,
  INVENTORY_EXPORT_SUMMARY,
  INVENTORY_STATUS_AWAITING_FOR_SALE,
  INVENTORY_STATUS_DAMAGED,
  INVENTORY_STATUS_IN_TRANSIT,
  INVENTORY_STATUS_RESERVED,
  INVENTORY_STATUS_SALABLE,
  INVENTORY_TYPE_INBOUND,
  INVENTORY_TYPE_OUTBOUND,
  INVENTORY_TYPE_UPLOAD_AWAITING_FOR_SALE,
  PROCESS_TYPE_CONSIGNMENT,
  PROCESS_TYPE_LISTING,
  PROCESS_TYPE_RETAIL,
  PROCESS_TYPE_TRADING,
  PROCESS_TYPE_TRADING_BY_LISTING,
} from 'utils/inventoryUtils'

const INVENTORY_EXPORT_TYPES = {
  [INVENTORY_EXPORT_SUMMARY]: 'Xuất tồn kho',
  [INVENTORY_EXPORT_MOVEMENT]: 'Xuất thông tin thay đổi tồn kho',
  [INVENTORY_EXPORT_BY_BATCH]: 'Xuất tồn kho theo số lô',
}

const INVENTORY_ENTITY_TYPE = {
  [INVENTORY_ENTITY_TYPE_INBOUND_ITEM]: 'Nhập kho theo đơn mua hàng',
  [INVENTORY_ENTITY_TYPE_PRODUCT_TRADING_STOCK]:
    'Sản phẩm niêm yết trên giao dịch',
  [INVENTORY_ENTITY_TYPE_OUTBOUND_ITEM]: 'Xuất kho theo đơn mua hàng',
  [INVENTORY_ENTITY_TYPE_ORDER_ITEM]: 'Đơn bán hàng',
  [INVENTORY_ENTITY_TYPE_PRODUCT_LISTING]: 'Cập nhật tồn dạng niêm yết',
  [INVENTORY_ENTITY_TYPE_OVERRIDE]: 'Ghi đè tồn',
  [INVENTORY_ENTITY_TYPE_UPLOAD_OVERRIDE]: 'Tải tập tin ghi đè tồn',
  [INVENTORY_TYPE_UPLOAD_AWAITING_FOR_SALE]: 'Ghi đè tồn kho chờ bán',
  [INVENTORY_ENTITY_TYPE_RETURN_ITEM]: 'Trả hàng',
  [INVENTORY_ENTITY_TYPE_TRANSFER_STOCK]: 'Luân chuyển hàng tồn kho',
}

const INVENTORY_TYPE = {
  [INVENTORY_TYPE_INBOUND]: 'Nhập kho',
  [INVENTORY_TYPE_OUTBOUND]: 'Xuất kho',
}

const INVENTORY_STATUS = {
  [INVENTORY_STATUS_AWAITING_FOR_SALE]: 'Đang chờ bán',
  [INVENTORY_STATUS_SALABLE]: 'Có thể bán',
  [INVENTORY_STATUS_DAMAGED]: 'Đã bị lỗi',
  [INVENTORY_STATUS_RESERVED]: 'Đã đặt hàng',
  [INVENTORY_STATUS_IN_TRANSIT]: 'Đang vận chuyển',
}

const INVENTORY_PROCESS_TYPE_SELECT = {
  [PROCESS_TYPE_TRADING]: 'Quy Trình 1-Trading',
  [PROCESS_TYPE_CONSIGNMENT]: 'Quy Trình ký gửi',
  [PROCESS_TYPE_RETAIL]: 'Quy Trình 2-Retail',
  [PROCESS_TYPE_LISTING]: 'Niêm yết-Listing',
  [PROCESS_TYPE_TRADING_BY_LISTING]:
    'Niêm yết trên giao dịch-Trading By Listing',
}

export const INVENTORY_VI = {
  casePerUnit: 'Quy cách đóng gói trên thùng',
  cogs: 'Giá vốn',
  awaitingForSale: 'Đang chờ bán',
  salableStock: 'Tồn có thể bán',
  reservedStock: ' Tồn đã đặt hàng',
  damagedStock: ' Tồn bị lỗi',
  totalStock: '  Tổng tồn',
  invValuePreTax: '  Giá trị trước thuế',
  invValuePostTax: ' Giá trị sau thuế',
  noOfCase: 'Số lượng thùng',
  noOfPcs: 'Số lượng lẻ',
  pack: 'Quy cách đóng gói',
  maximumRetailPrice: 'Giá bán lẻ tối đa trên thùng',
  totalMRP: 'Tổng giá bán lẻ tối đa sau thuế',
  qtyByBatches: 'Số lượng tồn theo lô',
  inventoryMovementStates: 'trạng thái thay đổi tồn kho',
  inCase: 'Thùng',
  inPcs: 'Lẻ',
  notFound: 'Không tìm thấy dữ liệu tồn kho!',
  inventoryMovementExport: 'Xuất thông tin thay đổi tồn kho',
  totalQtyCasePcs: 'Tồng tồn (Trên thùng/lẻ)',
  creationTime: 'Ngày tạo',
  entityType: 'Loại thay đổi chi tiết',
  selectEntityType: 'Chọn loại thay đổi chi tiết',
  type: 'Loại thay đổi',
  selectType: 'Chọn loại thay đổi',
  orderID: 'Mã đơn hàng',
  statuses: 'Trạng thái',
  selectStatuses: 'Chọn trạng thái',
  description: 'Mô tả',
  inventoryHistories: 'Lịch sử tồn kho',
  brand: 'Nhãn',
  market: 'Thường',
  productSegment: 'Phân khúc sản phẩm',
  product_listing_v2: 'Sản phẩm niêm yết',
  ...INVENTORY_EXPORT_TYPES,
  ...INVENTORY_ENTITY_TYPE,
  ...INVENTORY_TYPE,
  ...INVENTORY_STATUS,
  ...INVENTORY_PROCESS_TYPE_SELECT,
}
