export const REMITTANCE_EN = {
  code: 'Code',
  cashierPlaceholder: 'Select or enter first name to search',
  cashier: 'Cashier',
  actualAmount: 'Actual Amount',
  actualPendingAmount: 'Actual Pending Amount',
  differentAmount: 'Different Amount',
  totalAmount: 'Total Amount',
  totalPackage: 'Total Package',
  createNewRemittance: 'Create New Remittance',
  remittanceDetail: 'Remittance Detail',
  riderID: 'Rider ID',
  actualCollectedAmount: 'Actual Collected Amount',
  deliveredDate: 'Delivered Date',
  remittanceSubmitStops: 'Remittance Submit Stops',
  actualAmountValidMsg: "Can't be greater than 3% of the Actual Pending Amount",
  riderUnremitted: 'Rider Unremitted',
  pendingDays: 'Pending Days',
}
