const BIDDING_REQUEST_STATUS = {
  created: 'Mới',
  pending: 'Đang chờ',
  requested: 'Đã yêu cầu',
  closed: 'Đã đóng',
  cancelled: 'Đã hủy',
}

export const BIDDING_REQUEST_VI = {
  createBiddingRequest: 'Tạo yêu cầu đấu thầu',
  updateBiddingRequest: 'Cập nhật yêu cầu đấu thầu',
  warehouseProvince: 'Kho hàng theo tỉnh',
  selectProvince: 'Chọn kho hàng theo tỉnh',
  biddingCode: 'Mã đấu thầu',
  requestEndDate: 'Ngày kết thúc yêu cầu',
  biddingEndDate: 'Ngày kết thúc đấu thầu',
  updateRequest: 'Cập nhật yêu cầu',
  generalInfo: 'Thông tin chung',
  requestPeriod: 'Khung thời gian yêu cầu',
  biddingPeriod: 'Khung thời gian đấu thầu',
  addSkus: 'Thêm SKU',
  selectSkus: 'Chọn SKU',
  searchPlaceholder: 'Nhập ít nhất 2 ký tự để tìm kiếm',
  salePrice: 'Giá bán',
  requestedBy: 'Yêu cầu bởi',
  sold: 'Số lượng đã bán',
  inventory: 'Tổn kho hiện tại',
  averagePriceLastMonth: 'Giá trung bình tháng trước',
  competitorPrice: 'Giá đối thủ',
  min: 'Thấp nhất',
  max: 'Lớn nhất',
  select: 'Chọn',
  unselect: 'Bỏ chọn',
  review: 'Đánh giá',
  goTo: 'Đến V2',
  backTo: 'Trở về V1',
  submittedQty: 'Số lượng đã nộp',
  unitPrice: '  Giá đơn vị',
  deliverDate: 'Ngày vận chuyển',
  internalShippingFee: 'Phí vận chuyển nội bộ',
  adjustQty: 'Số lượng điều chỉnh',
  adjustPrice: 'Giá điều chỉnh',
  priceShippingFee: 'Giá & Phí vận chuyển',
  submit: 'Nộp',
  unsubmit: 'Hủy nộp',
  price: 'Giá',
  pleaseSelectSkuToReview: 'Vui lòng chọn SKU để đánh giá!',
  cancelWarning:
    'Cảnh báo! Hủy yêu cầu đấu thầu này không thể hoàn tác, bạn có muốn tiếp tục không?',
  subtotal: 'Tổng phụ',
  biddingReview: 'Xét duyệt đấu thầu',
  createPoMsg: {
    status: 'Không thể tạo đơn đặt hàng khi trạng thái là {status}!',
    period: 'Không thể tạo PO trước ngày kết thúc đấu thầu!',
  },
  unitShippingFee: 'Đơn vị phí vận chuyển',
  updatedBy: 'Cập nhật bởi',
  ...BIDDING_REQUEST_STATUS,
}
