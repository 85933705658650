export const VEHICLE_VI = {
  plate: '  Biển số',
  type: 'Dạng phương tiện',
  createNewVehicle: 'Tạo mới phương tiện giao hàng',
  vehicleName: 'Tên phương tiện',
  vehiclePlate: 'Biển số phương tiện',
  vehicleType: 'Dạng phương tiện',
  vehicleWeight: 'Trọng lượng phương tiện',
  vehicleBuyTime: 'Thời gian mua phương tiện',
  editVehicle: 'Chỉnh sửa phương tiện giao hàng',
  deleteVehicle: 'Xóa phương tiện',
  updateVehicle: 'Cập nhật phương tiện giao hàng',
  vehiclePlaceholder: 'Tìm kiếm biển số phương tiện',
}
