import {
  PURCHASE_REQUEST_ALL,
  PURCHASE_REQUEST_CANCELLED,
  PURCHASE_REQUEST_CLOSED,
  PURCHASE_REQUEST_CREATED,
  PURCHASE_REQUEST_QUOTED,
  PURCHASE_REQUEST_QUOTING,
  PURCHASE_REQUEST_RECOMMENDED,
} from 'utils/purchaseRequest'

export const PURCHASE_REQUEST_STATUSES_VI = {
  [PURCHASE_REQUEST_ALL]: 'TẤT CẢ',
  [PURCHASE_REQUEST_CREATED]: 'MỚI',
  [PURCHASE_REQUEST_QUOTING]: 'ĐANG BÁO GIÁ',
  [PURCHASE_REQUEST_QUOTED]: 'ĐÃ BÁO GIÁ',
  [PURCHASE_REQUEST_CLOSED]: 'ĐÓNG',
  [PURCHASE_REQUEST_CANCELLED]: 'ĐÃ HỦY',
  [PURCHASE_REQUEST_RECOMMENDED]: 'ĐỀ NGHỊ',
}

export const PURCHASE_REQUEST_VI = {
  pageName: 'Yêu cầu mua hàng',
  createNewPurchaseRequest: 'Tạo mới yêu cầu mua hàng',
  addPurchaseRequest: 'Thêm yêu cầu mua hàng',
  refresh: 'Làm mới',
  PRNumber: 'Mã yêu cầu mua hàng',
  warehouse: 'Kho hàng',
  distributor: 'Nhà phân phối',
  brandGroup: 'Nhóm thương hiệu',
  requestDate: 'Ngày yêu cầu',
  currentStatus: 'Trạng thái hiện tại',
  startDate: 'Ngày bắt đầu',
  selectWarehouse: 'Chọn kho hàng',
  searchBrandGroup: 'Tìm kiếm nhóm thương hiệu',
  endDate: 'Ngày kết thúc',
  search: 'Tìm kiếm',
  reset: 'Cài lại',
  messageCreatePR: 'Yêu cầu mua hàng sẽ tách riêng ra theo nhóm thương hiệu ?',
  messageCancelPR: 'Bạn có xác nhận hủy yêu cầu mua hàng này ?',
  messageSelectWarehouse: 'Vui lòng chọn kho hàng!',
  copyPurchaseRequest: 'Sao chép yêu cầu mua hàng',
  copyExistingPR: 'Kết quả sao chép yêu cầu mua hàng',
  createdDate: 'Ngày tạo',
  createdBy: 'Người tạo',
  requestBy: 'Người yêu cầu',
  leadTime: 'Thời gian',
  dayAgo: 'ngày trước',
  monthAgo: 'thangs trước',
  yearAgo: 'năm trước',
  pre: 'Trang trước',
  next: 'Trang kế tiếp',
  records: 'dòng',
  page: 'trang',
  status: 'Trạng thái',
  items: 'Danh sách sản phẩm',
  addItem: 'Thêm sản phẩm',
  selectSKUs: 'Chọn sản phẩm',
  selectCategory: 'Chọn danh mục',
  productName: 'Tên sản phẩm',
  poPrice: 'Giá mua vào',
  salePrice: 'Giá bán ra',
  image: 'Hình ảnh',
  ok: 'Đồng ý',
  quantity: 'Số lượng',
  quantityRequired: 'Vui lòng nhập số lượng!',
  packType: 'Đơn vị đóng gói',
  action: 'Thao tác',
  cancel: 'Hủy',
  create: 'Tạo',
  name: 'Tên sản phẩm',
  brandGroupRequired: 'Vui lòng chọn nhóm thương hiệu!',
  brand: 'Thương hiệu',
  update: 'Cập nhật',
  statusHistory: 'Lịch sử thay đổi',
  copyPRSuccess: 'Sao chép thành công yêu cầu mua hàng',
  cancelPRSuccess: 'Hủy thành công yêu cầu mua hàng',
  confirmPRSuccess: 'Xác nhận đề nghị mua hàng thành công',
  prUpdated: 'Cập nhật thành công yêu cầu mua hàng',
  prCreated: 'Tạo thành công yêu cầu mua hàng',
  confirmDeleteSupplierProduct: 'Bạn có muốn xoá sản phẩm này ?',
  deleteSupplierProduct: 'Xóa sản phẩm',
  confirmPurchaseRequest: 'Xác nhận',
  referralPrice: 'Giá tham khảo',
  totalReferralAmount: 'Tổng giá trị ước tính',
  totalReferralPrice: 'Tổng giá trị ước tính',
  estimateProfit: 'Lợi nhuận ước tính',
  totalEstimateProfit: 'Tổng lợi nhuận ước tính',
  sellOutDay: 'Ngày hết hàng',
  recommendedSellOutDay: 'Ngày bán hàng',
  reasonCancel: 'Lý do hủy',
  industry: 'Lĩnh vực',
  createRetail: 'Yêu cầu mua hàng',
  createConsignment: 'Yêu cầu mua hàng kí gửi',
  retail: 'Mua bán',
  consignment: 'Kí gửi',
  supplier_of_distributor: 'Hàng của Nhà phân phối',
  undefined: 'Chờ xác định nguồn hàng',
  ...PURCHASE_REQUEST_STATUSES_VI,
}
