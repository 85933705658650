import {
  SUBSIDY_RULE_STATUS_CANCELLED,
  SUBSIDY_RULE_STATUS_COMPLETED,
  SUBSIDY_RULE_STATUS_IN_PROGRESS,
  SUBSIDY_RULE_STATUS_SCHEDULED,
} from 'utils/subsidyUtils'

const SUBSIDY_RULE_STATUS = {
  [SUBSIDY_RULE_STATUS_SCHEDULED]: 'Đã lên lịch',
  [SUBSIDY_RULE_STATUS_IN_PROGRESS]: 'Đang chạy',
  [SUBSIDY_RULE_STATUS_CANCELLED]: 'Đã hủy',
  [SUBSIDY_RULE_STATUS_COMPLETED]: 'Đã hoàn thành',
}

export const SUBSIDY_VI = {
  subsidyRuleManagement: 'Quản lý tài trợ giá',
  name: 'Tên chương trình tài trợ giá',
  noOfSku: 'Số lượng sản phẩm',
  subsidyRuleInfo: 'Thông tin chương trình tài trợ giá',
  effectiveFrom: 'Ngày có hiệu lực',
  effectiveTo: 'Ngày hết hiệu lực',
  subsidyRuleItems: 'Danh sách sản phẩm tài trợ giá',
  minOrderQuantity: 'Số lượng mua tối thiểu để kích hoạt tài trợ',
  unitPriceSubsidy: 'Đơn giá tài trợ',
  magentoStock: 'Tồn kho',
  createSubsidyRule: 'Tạo tài trợ giá',
  generalInfo: 'Thông tin chung',
  supportedSkUs: 'Danh sách SKU hỗ trợ',
  addSkus: 'Thêm SKU hỗ trợ',
  selectSkus: 'Chọn SKU',
  incurredAmount: 'Số tiền phát sinh',
  cancelSubsidy: 'Hủy tài trợ giá',
  cancelSubsidyMsg:
    'Bạn có chắc chắn hủy tài trợ giá này không? Hành động này KHÔNG THỂ hoàn tác.',
  unitPrice: 'Đơn giá',
  availableStock: 'Tồn khả dụng',
  unitPriceSubsidyValid: 'Đơn giá tài trợ phải > 0',
  poPrice: 'Giá mua vào',
  salePrice: 'Giá bán ra',
  searchSkuPlaceholder: 'Nhập ít nhất 2 từ để tìm kiếm',
  maximumSubsidyAmount: 'Số tiền trợ giá tối đa',
  maximumOrderQuantity: 'Số lượng trợ giá tối đa',
  messErrorEmptySkuSubsidy: 'Vui lòng chọn 1 sản phẩm!',
  messageSelectSku: 'Nhấn vào để thêm <link>một SKU</link>',
  subsidyType: 'Loại trợ giá',
  manual: 'Thủ công',
  auto: 'Chênh giá',
  special: 'Đặc biệt',

  ...SUBSIDY_RULE_STATUS,
}
