import {
  TRADE_ORDER_STATUS_CANCELLED,
  TRADE_ORDER_STATUS_COMPLETED,
  TRADE_ORDER_STATUS_COMPLETED_DELIVERED,
  TRADE_ORDER_STATUS_COMPLETED_PARTIAL,
  TRADE_ORDER_STATUS_CREATED,
  TRADE_ORDER_STATUS_FAILED,
  TRADE_ORDER_STATUS_IN_DELIVERY,
  TRADE_ORDER_STATUS_PENDING,
  TRADE_ORDER_STATUS_PROCESSING,
} from 'utils/tradeOrder'

const TRADE_ORDER_STATUS_VI = {
  [TRADE_ORDER_STATUS_CREATED]: 'MỚI',
  [TRADE_ORDER_STATUS_PROCESSING]: 'ĐANG XỬ LÝ',
  [TRADE_ORDER_STATUS_COMPLETED]: 'HOÀN THÀNH',
  [TRADE_ORDER_STATUS_CANCELLED]: 'ĐÃ HỦY',
  [TRADE_ORDER_STATUS_PENDING]: 'CHỜ XỬ LÝ',
  [TRADE_ORDER_STATUS_IN_DELIVERY]: 'ĐANG GIAO',
  [TRADE_ORDER_STATUS_COMPLETED_DELIVERED]: 'HOÀN THÀNH',
  [TRADE_ORDER_STATUS_COMPLETED_PARTIAL]: 'HOÀN THÀNH',
  [TRADE_ORDER_STATUS_FAILED]: 'THẤT BẠI',
}
export const TRADE_ORDERS_VI = {
  allStatus: 'Tất cả trạng thái',
  pre_order: 'Hàng đặt trước',
  available: 'Hàng có sẵn',
  fulfillmentOrderButton: 'Xác nhận giao hàng',
  confirmToFulfillment: 'Xác nhận giao hàng',
  messageProductNotEnough:
    'Hiện có {quantity} sản phẩm không đủ số lượng để giao:',
  messageRemoveItemFulfillment:
    'Những sản phẩm không đủ số lượng sẽ được giao một phần khi bấm giao hàng. Bạn có muốn nhập thêm sản phẩm chưa đủ số lượng?',
  buttonNoConfirmFulfillment: 'Không, tiếp tục giao',
  buttonYesConfirmFulfillment: 'Có, vui lòng đợi cho tôi nhập hàng',
  currentStock: 'tồn hiện tại',
  warningPreOrders: 'Cảnh báo giao hàng',
  subtotalDes: 'Tạm tính = Giá gốc x Số lượng',
  grossSACommissionsDes: 'Tổng hoa hồng NV bán hàng',
  discountDes: 'Giảm giá = Tổng giá khuyến mãi trên SKU',
  grandTotalDes:
    'Giá sau khi được giảm và bao gồm cả phí vận chuyển cho toàn bộ đơn hàng',
  totalPaidDes:
    'Tổng cần thanh toán= Tổng tiền đơn hàng + Hoa hồng NV bán hàng trên tất cả sản phẩm',
  totalRefunded: 'Tổng giá trị hàng trả về = Số lượng hàng trả x Giá bán',
  totalDueDes:
    'Tổng tiền thanh toán thực tế = Tổng cần thanh toán (-) Tổng giá trị hàng trả về',
  category: 'Khuyến mãi trên danh mục',
  sku: 'Khuyến mãi trên SKU',
  cart_total: 'Giá khuyến mãi giỏ hàng',
  all: 'TẤT CẢ',
  cancelTradeOrder: 'Xác nhận hủy đơn hàng',
  reasonCancel: 'Vui lòng chọn lý do hủy',
  createNew: 'Hủy để tạo mới',
  duplicateOrder: 'Đơn hàng đã tồn tại trước đó',
  outletTooFar: 'Người nhận quá xa',
  salesAgentCancel: 'Nhân viên bán hàng yêu cầu hủy',
  insufficientInventory: 'Hết hàng hoặc tồn kho không đủ',
  product: 'Khuyến mãi trên sản phẩm',
  cart: 'Khuyến mãi trên giỏ hàng',
  flexible: 'Khuyến mãi từ linh hoạt',
  bundle: 'Khuyến mãi theo gói',
  brand: 'Khuyến mãi từ thương hiệu',
  type: 'Loại sản phẩm',
  price: 'Giá sản phẩm',
  others: 'Lý do khác',
  ...TRADE_ORDER_STATUS_VI,
}
